import React from 'react';

const travelChips = [
  {
    num: '5 MIN',
    body: (
      <p>
        Drive to Guilford
        <br />
        Town Centre
      </p>
    ),
    top: 0,
    right: 0,
  },
  {
    num: '40 MIN',
    body: (
      <p>
        Skytrain to <br />
        Downtown Vancouver
      </p>
    ),
    top: 15,
    left: 0,
    noArrow: true,
  },
  {
    num: '30 MIN',
    body: (
      <p>
        Drive to <br />
        Downtown Vancouver
      </p>
    ),
    top: 0,
    left: 0,
    rotation: -90,
  },
  {
    num: '40 MIN',
    body: (
      <p>
        Drive to <br />
        YVR/Richmond
      </p>
    ),
    top: 50,
    left: 0,
    rotation: -134,
  },
  {
    num: '32 MIN',
    body: (
      <p>
        Drive to White
        <br />
        Rock Beach
      </p>
    ),
    bottom: 0,
    left: 0,
    reverse: true,
    rotation: 135,
  },
];

export default travelChips;
