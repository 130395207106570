import React, { Fragment } from 'react';

export default {
  outline: (
    <Fragment>
      <g
        id="level-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="bg-shapes" transform="translate(105.000000, 32.000000)">
          <path
            d="M151,150 L151,129"
            id="Path"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M117,129 L117,150"
            id="Path"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="178.5 129 106.5 129 106.5 177"
          />
          <path
            d="M178.5,149.5 L106.5,149.5"
            id="Path"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="178.5 128.402093 178.5 177 90.5 177 90.5 108 178.5 108 178.5 112.814653"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="178.250537 164.956858 194.878761 164.956858 194.878761 155.847345 211.746239 155.847345 211.746239 182.936947 196.015222 182.936947 196.015222 227.528761 193.532951 227.528761 193.532951 237.982301 191.91798 237.982301 191.91798 243 255.5 243 255.5 40.7389381 196.942335 40.7389381 196.942335 35.960177 143.169771 35.960177 143.169771 31.6294248 147.745523 2.74778761 138.145415 0 134.736032 22.3108407 93.2851003 15.949115 90.0850645 36.1692478 89.1878582 35.960177 88.5 40.7389381"
          />
          <path
            d="M124.5,129 L151.5,150 L178.5,129 M124.5,150 L151.5,129 L178.5,150"
            id="Shape"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M164.5,163.508699 L121.5,163.508699 M124.496516,149.87821 L124.496516,129"
            id="Shape"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M161.239074,150 L161.239074,163.5 M121.000326,150 L121.000326,163.5 M163.999674,163.5 L163.999674,177 M123.520874,163.5 L123.520874,177"
            id="Shape"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M130.638789,88.1375187 L130.638789,38.2720478 L143.169771,38.2720478 L143.169771,36 M178.250537,113.727952 L255.5,113.727952 M194.878761,156 L194.878761,128.675635 L255.5,128.675635 M88.5,40.7832586 L93.4944484,41.4409567 L93.4944484,88.1375187 L141.584706,88.1375187 L141.584706,108.914798"
            id="Shape"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.5911073,55.6184645 L4.59655817,52.2147771 L2.44362249,67.4417997 L27.5911073,55.6184645 Z M192.5,35.6143368 L192.5,24 L143.759928,24 L192.5,35.6143368 Z M167.232908,241 L191.363728,241 L167.232908,241 Z M101.119841,241 L125.250662,241 L101.119841,241 Z M0.5,208.008118 L0.709313191,224.87727 L29.8636505,224.87727 L0.5,208.008118 Z"
            id="Shape"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M93.7409091,88 L146.5,14 M131.111932,88 L93.5,15.6191248"
            id="Shape"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="124.5 109 124.5 115 106.5 115 106.5 109"
          />
          <path
            d="M106.5,114 L124.5,108 M106.5,108 L124.5,114"
            id="Shape"
            stroke="#2A2827"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <text
            id="EXERCISE"
            opacity="0.5"
            fontFamily="Brother-1816-Book, Brother 1816"
            fontSize="8"
            fontWeight="300"
            letterSpacing="0.6666667"
            fill="#B77356"
          >
            <tspan x="176.001333" y="80">
              EXERCISE
            </tspan>
          </text>
          <text
            id="MTNG."
            opacity="0.5"
            fontFamily="Brother-1816-Book, Brother 1816"
            fontSize="8"
            fontWeight="300"
            letterSpacing="0.6666667"
            fill="#B77356"
          >
            <tspan x="213.373333" y="208">
              YOGA
            </tspan>
            <tspan x="211.873333" y="215">
              ROOM
            </tspan>
          </text>
        </g>
      </g>
    </Fragment>
  ),
};
