import React from 'react';

export default {
  units: [
    {
      id: 'ph2',
      mockup: (
        <g id="ph2" transform="translate(232.000000,76.000000)">
          <polygon
            id="fill-ph2"
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="132.184073 91.4399181 137.5 91.4399181 137.5 71.4759468 133.240217 71.4759468 133.240217 10.0700102 137.5 10.0700102 137.5 0 130.036579 0 130.036579 1.30276356 0.342036383 1.30276356 0.342036383 42.2517912 39.9122497 42.2517912 39.9122497 40.3856704 61.5280068 40.3856704 61.5280068 123.868168 41.778105 123.868168 41.778105 122.283726 0.342036383 122.283726 0.342036383 156.824565 127.642651 156.824565 127.642651 172 137.5 172 137.5 153.655681 132.184073 153.655681"
          />
          <text
            id="PH2"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="81.2609995" y="76">
              PH2
            </tspan>
          </text>
          <text
            id="02"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="87.052333" y="94">
              02
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'ph1',
      mockup: (
        <g id="ph1" transform="translate(100.000000, 77.000000)">
          <polygon
            id="fill-ph1"
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="132.342036 121.283726 71.1912708 121.283726 71.1912708 41.2517912 132.342036 41.2517912 132.342036 0.302763562 0.5 0.302763562 0.5 155.824565 132.342036 155.824565"
          />
          <text
            id="PH1"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="19.4649995" y="75">
              PH1
            </tspan>
          </text>
          <text
            id="01"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="25.151333" y="93">
              01
            </tspan>
          </text>
        </g>
      ),
    },
  ],
};
