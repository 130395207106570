import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import imgArrow from 'src/assets/images/neighbourhood/arrow-right-diagonal.svg';

const TravelChip = ({ info, mapOverlay, visible }) => {
  return (
    <Root
      visible={visible}
      mapOverlay={mapOverlay}
      top={info.top}
      left={info.left}
      right={info.right}
      bottom={info.bottom}
      reverse={info.reverse}
    >
      <Arrow
        src={imgArrow}
        top={info.top}
        right={info.right}
        left={info.left}
        bottom={info.bottom}
        rotation={info.rotation}
        noArrow={info.noArrow}
      />
      <TextContainer reverse={info.reverse}>
        <Title>
          <span>{info.num}</span>
        </Title>

        <Body>{info.body && <span>{info.body}</span>}</Body>
      </TextContainer>
    </Root>
  );
};

export default TravelChip;

const Root = styled.div`
  display: ${props => (props.mapOverlay ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 25vw;
  margin-bottom: 36px;
  z-index: 1000;
  @media (min-width: ${media.mdup}) {
    margin-left: 20px;
    text-align: left;

    display: ${props => (props.mapOverlay && props.visible ? 'flex' : 'none')};
    position: absolute;
    width: 94px;
    top: ${props => props.top && `${props.top}%`};
    left: ${props => props.left && `${props.left}%`};
    right: ${props => props.right && `${props.right}%`};
    bottom: ${props => props.bottom && `${props.bottom}%`};
    margin-top: 20px;
    padding: 10px;
    width: 150px;
    flex-direction: ${props => (props.reverse ? 'row' : 'column')};
    align-items: ${props => (props.reverse ? 'flex-end' : 'flex-start')};
  }
`;

const Title = styled.div`
  span {
    font-size: 20px;
    color: ${({ theme }) => theme.colorCopper};
    /* font-weight: 500; */
  }
`;

const Body = styled.div`
  span {
    font-size: 10px;
    /* font-weight: 500; */
    line-height: 1.6;
    letter-spacing: 0.7px;
    color: ${({ theme }) => theme.colorBlack};
    text-transform: uppercase;
  }
`;

const Arrow = styled.img`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    display: ${props => props.noArrow && 'none'};
    top: ${props => props.top && `${props.top + 10}%`};
    left: ${props => props.left && `${props.left}%`};
    right: ${props => props.right && `${props.right}%`};
    bottom: ${props => props.bottom && `${props.bottom}%`};
    transform: ${props => `rotate(${props.rotation}deg)`};
  }
`;

const TextContainer = styled.div`
  margin-left: ${props => props.reverse && '20px'};
`;
