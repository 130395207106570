import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import TextBlock from 'components/TextBlock';
import ScrollAnimation from 'components/ScrollAnimation';

const Description = ({ text, image }) => {
  return (
    <Root>
      <ScrollAnimation>
        <TextWrapper>
          <TextBlock text={text} />
        </TextWrapper>
        <ImageWrapper>
          <Image
            background={image.image}
            backgroundSize={image.backgroundSize}
            backgroundPosition={image.backgroundPosition}
          />
        </ImageWrapper>
      </ScrollAnimation>
    </Root>
  );
};

export default Description;

Description.defaultProps = {
  image: {
    image: '',
    alt: '',
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 64px;
  /* padding-left: 23px;
  padding-right: 23px;
  padding-top: 53px;
  padding-bottom: 52px; */
  > div {
    width: 100%;
    /* padding: 0 27px; */
  }
  @media (min-width: ${media.smup}) {
    flex-direction: row;
    margin-bottom: 137px;
    align-items: center;
    margin-bottom: 109px;

    /* padding-bottom: 80px;
    padding-left: 50px;
    padding-right: 50px; */
  }
`;

const Image = styled.img`
  background-image: ${props => `url(${props.background})`};
  background-size: ${props =>
    props.backgroundSize ? props.backgroundSize : 'cover'};
  background-position: ${props =>
    props.backgroundPosition ? props.backgroundPosition : 'center'};
  min-height: 240px;
  min-width: 276px;
  width: 100%;
  height: 64vw;
  @media (min-width: ${media.smup}) {
    height: auto;
  }
  @media (min-width: ${media.mdup}) {
    height: 434px;
    width: 499px;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 33px;
  @media (min-width: ${media.mdup}) {
    justify-content: center;
    align-items: center;
    /* align-items: center; */
  }
  > div {
    @media (min-width: ${media.smup}) {
      justify-content: flex-end;
    }
  }
`;

const ImageWrapper = styled.div`
  /* padding: 0 27px; */
  /* NEED TO REMOVE PADDING FOR HOME DESCRIP. */
`;
