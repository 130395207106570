import React, { Fragment } from 'react';

export default {
  outline: (
    <Fragment>
      <g
        id="level-3"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="bg-shapes"
          transform="translate(48.000000, 32.000000)"
          stroke="#2A2827"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.5"
        >
          <path
            d="M185.105263,141.314107 L185.105263,117.755486 M147,117.755486 L147,141.314107 M134.754386,117.755486 L215.245614,117.755486 M215.245614,141.314107 L134.754386,141.314107 M134.754386,172 L134.754386,117.755486 M215.245614,117.755486 L215.245614,172"
            id="Shape"
          />
          <path
            d="M154,117 L184.017452,141 L214,117 M154,141 L184.017452,117 L214,141"
            id="Shape"
          />
          <path
            d="M199,156.271392 L151,156.271392 M154.50365,141.163354 L154.50365,118"
            id="Shape"
          />
          <path
            d="M196.118367,141 L196.118367,156 M150.768707,141 L150.768707,156 M199.231293,156 L199.231293,171 M153.634014,156 L153.634014,171"
            id="Shape"
          />
          <path
            d="M300.896497,30.4337143 L342.475559,30.4337143 L342.475559,54.2605714 M300.896497,240.939429 L342.475559,240.939429 L342.475559,219.853714 M343.491391,156.385714 L354,156.385714 L354,126.760286 L343.491391,126.760286 M165.125272,13.8111429 L165.125272,0 L124.036612,0 L124.036612,13.8111429 M231.469622,13.8111429 L231.469622,0 L171.745696,0 L171.745696,13.8111429 M15.3075401,94.218 L6.62042351,94.218 L0,137.232857 L15.3075401,139.552286 M49.7757768,36.1268571 L20.9121314,31.7691429 L17.619434,54.2957143 M202.360776,244.242857 L230.698991,244.242857 M128.625371,244.242857 L156.963586,244.242857 M16.3233723,206.358857 L16.6036018,226.074 L49.7757768,226.074"
            id="Shape"
          />
          <polyline id="Path" points="155 95 155 102 135 102 135 95" />
          <path d="M135,102 L155,95 M135,95 L155,102" id="Shape" />
        </g>
      </g>
    </Fragment>
  ),
};
