import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import InfoChip from 'components/InfoChip';
import imgMobileBackground from 'src/assets/images/home/mobileBalcony.jpg';

const Glance = ({ infoChips, handleClick, activeIndex, image }) => {
  return (
    <Root background={image} mobileBackground={imgMobileBackground}>
      <Flower
        src={require('src/assets/images/logo/logo-flower-copy.png')}
        alt="Centra Logo in white"
      />
      <h1>Centra at a Glance</h1>
      <ChipWrapper>
        {infoChips.map((item, index) => (
          <InfoChip
            info={item}
            key={index}
            type="expandable"
            index={index}
            handleClick={handleClick}
            activeIndex={activeIndex}
          />
        ))}
      </ChipWrapper>
    </Root>
  );
};

export default Glance;

const Root = styled.div`
  background-image: ${props =>
    `linear-gradient(to bottom, #f7f5f1, #f7f5f1), url(${
      props.mobileBackground
    })`};
  background-position: 50%;
  background-size: cover;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;

  margin-left: -23px;
  margin-right: -23px;
  margin-bottom: 80px;
  @media (min-width: ${media.mdup}) {
    background-image: ${props =>
      `linear-gradient(to bottom, #f7f5f1, #f7f5f1), url(${props.background})`};
    height: 672px;
    margin: 57px;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 55px;
    margin-top: 137px;
    margin-bottom: 120px;
  }

  h1 {
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.82);
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 3.6px;
    color: white;
    margin-bottom: 64px;
    @media (min-width: ${media.smup}) {
      font-size: 25px;
      letter-spacing: 4.5px;
      margin-bottom: 112px;
    }
  }
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
    max-height: 254px;
  }
`;

const Flower = styled.img`
  margin-bottom: 18px;
`;
