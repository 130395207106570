export default [
  'Gym',
  'Co-working Space',
  'Pet Wash Station',
  'Private Theatre',
  'Yoga Room',
  'Club Room',
  'Parcel Room',
  'DIY Project Workshop',
];
