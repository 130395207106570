import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';

import mapStyles from 'src/config/map/mapStyles';

// const PCMarker = ({ text }) => <div>{text}</div>;

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 49.1918211,
      lng: -122.8024965,
    },
    zoom: 13,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDuhFLo5b9eBcjD9BccHGekEVLMXPmxdG8' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={{ styles: mapStyles, disableDefaultUI: true }}
        >
          <PCmarker lat={49.1918211} lng={-122.8024965}>
            <span>
              Presentation Centre
              <br />
              #103-15225 - 104 Ave
            </span>
          </PCmarker>
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;

const PCmarker = styled.div`
  white-space: nowrap;
  text-align: center;
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.colorCopper};
  width: 200px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  ::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -10px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    transform: translateY(100%);
    border-top: 10px solid ${({ theme }) => theme.colorCopper};
  }
  span {
    text-align: center;
    font-size: 9px;
    font-weight: 300;
    line-height: 1.22;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colorBlack};
    display: inline-block;
  }
`;
