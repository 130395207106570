const mapLocations = [
  {
    title: 'King George Station',
    description:
      'Growing development of stores, restaurants and services currently includes:',
    locations: [
      'Coast Capital Savings',
      'Starbucks',
      "Tim Horton's",
      'Chopped Leaf',
    ],
  },
  {
    title: 'Civic Plaza',
    description: '',
    locations: [
      'City Hall',
      'Surrey City Centre Library',
      '3 Civic Plaza Hotel',
      'Dominion Bar + Kitchen',
      'Prado Cafe',
    ],
  },
  {
    title: 'Central City',
    description: '140 retail stores, restaurants and services including:',
    locations: [
      'T&T Supermarket',
      'Best Buy',
      'Walmart',
      'Club 16 Trevor Linden Fitness',
    ],
  },
];

export default mapLocations;
