import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const Transportation = () => {
  return (
    <Root>
      <h1>Transportation:</h1>
      <Line>
        <Bar background="#5675a0" />

        <p>Skytrain - Expo Line</p>
      </Line>

      <Line>
        <Bar background="#f4631e" />
        <p>Future Skytrain</p>
      </Line>
    </Root>
  );
};

export default Transportation;

const Root = styled.div`
  background: #ffffff;
  margin: 0 -23px;
  padding: 0 23px;
  padding-top: 37px;
  padding-bottom: 25px;

  h1 {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 1px;
  }
  p {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
  }

  @media (min-width: ${media.mdup}) {
    position: absolute;
    bottom: 0;
    left: 27.6vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
    transform: translateY(100%);
    background: ${({ theme }) => theme.colorOffWhite};
    h1 {
      margin-right: 30px;
    }
  }
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 9px;
  @media (min-width: ${media.mdup}) {
    margin-right: 30px;
  }
`;

const Bar = styled.div`
  width: 37px;
  height: 8px;
  background-color: ${props => props.background};
  margin-right: 8px;
`;
