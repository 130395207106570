import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import media from 'src/styles/media';

import LayoutWithHero from 'components/LayoutWithHero';
import Select from './elements/Select';
import PlanCard from './elements/PlanCard';
import MobilePopUp from './elements/MobilePopUp';
import Floorplate from './elements/Floorplate';

import imgCompass from 'src/assets/images/floorplans/compass-4-x.png';

import floorplans from 'src/data/floorplans';
import floorplates2 from 'src/data/floorplans/floorplates2';
import floorplates3 from 'src/data/floorplans/floorplates3';
import floorplates4 from 'src/data/floorplans/floorplates4';
import floorplates20 from 'src/data/floorplans/floorplates20';
import floorplates21 from 'src/data/floorplans/floorplates21';
import floorplates24 from 'src/data/floorplans/floorplates24';
import floorplatesTH from 'src/data/floorplans/floorplatesTH';
import floorplates1 from 'src/data/floorplans/floorplates1';

import outlineF1 from 'src/data/floorplans/outlineF1';
import outlineF2 from 'src/data/floorplans/outlineF2';
import outlineMain from 'src/data/floorplans/outlineMain';
import outlineF20 from 'src/data/floorplans/outlineF20';
import outlineF21 from 'src/data/floorplans/outlineF21';
import outlineF24 from 'src/data/floorplans/outlineF24';
import outlineTH from 'src/data/floorplans/outlineTH';

import Building from './elements/Building';
import Slider from './elements/Slider';

const options = [
  { title: 'All Homes', value: 'all' },
  { title: 'Studio', value: 0 },
  { title: 'JR 2 Bed', value: 1 },
  { title: '1 Bedroom', value: 1 },
  { title: '2 Bedroom', value: 2 },
  { title: '3 Bedroom', value: 3 },
  { title: '4 Bedroom', value: 4 },
];

const sliderMarks = {
  1: {
    title: '1',
    floorplate: floorplates1,
    outline: outlineF1,
  },
  5: {
    title: '2',
    floorplate: floorplates2,
    outline: outlineF2,
  },
  10: {
    title: '3',
    floorplate: floorplates3,
    outline: outlineMain,
  },
  40: {
    title: '4-19',
    floorplate: floorplates4,
    outline: outlineMain,
  },
  68: {
    title: '20',
    floorplate: floorplates20,
    outline: outlineF20,
  },
  75: {
    title: '21-23',
    floorplate: floorplates21,
    outline: outlineF21,
  },
  83: {
    title: '24',
    floorplate: floorplates24,
    outline: outlineF24,
  },
};

class Floorplans extends Component {
  state = {
    popupOpen: false,
    desktopOpen: false,
    filterOpen: false,
    activeIndex: 0,
    swipeDirection: null,
    activeHomeTypeFilter: 0,
    filterByType: true,
    floorplansFiltered: floorplans, // this can change
    activeSlider: 0,
    activeLevel: sliderMarks['1'],
    filterCondos: true,
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleResizing);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizing);
  }

  handleResizing = () => {
    /* If browser is on mobile device, reset filter to By Type (default)  
       because we don't allow By Level on mobile view.
    */
    if (window.innerWidth < 992) {
      let plans = this.sortPlans(this.state.activeHomeTypeFilter);
      this.setState({
        filterByType: true,
        filterOpen: false,
        floorplansFiltered: plans /* updates floorplans state using sortPlans */,
      });
    }
  };

  handleTogglePopup = () => {
    document.body.style.overflow = 'visible';
    document.documentElement.style.overflow = 'visible';
    document.getElementById('app').style.overflow = 'visible';
    this.setState({ popupOpen: !this.state.popupOpen, swipeDirection: null });
  };

  handlePlanSelect = (index, unclickable = false) => {
    if (!unclickable) {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
      document.getElementById('app').style.overflow = 'auto';
      this.setState({ activeIndex: index, popupOpen: !this.state.popupOpen });
    }
  };

  handlePlanSelectFromPlate = id => {
    let floorplanSelected = floorplans.findIndex(
      floorplan => floorplan.id === id
    );

    let soldout = floorplans[floorplanSelected].soldout;
    let active = this.determineActive(floorplans[floorplanSelected].id);

    if (!soldout && active) {
      this.setState({
        activeIndex: floorplanSelected,
        popupOpen: !this.state.popupOpen,
      });
    }
  };

  handleSwipeLeft = () => {
    const nextPlan = this.state.activeIndex + 1;
    if (nextPlan >= this.state.floorplansFiltered.length) {
      this.setState({
        activeIndex: 0,
        swipeDirection: 'fadeInLeft',
      });
    } else {
      this.setState({
        activeIndex: nextPlan,
        swipeDirection: 'fadeInLeft',
      });
    }
  };

  handleSwipeRight = () => {
    const prevPlan = this.state.activeIndex - 1;
    if (prevPlan < 0) {
      this.setState({
        activeIndex: this.state.floorplansFiltered.length - 1,
        swipeDirection: 'fadeInRight',
      });
    } else {
      this.setState({
        activeIndex: prevPlan,
        swipeDirection: 'fadeInRight',
      });
    }
  };

  // I know this function is crazy, it's because centra has added & removed various filters since the beginning and its turned into this.
  handleHomeTypeFilter = index => {
    if (!this.state.filterByType) {
      this.setState({
        activeHomeTypeFilter: index,
        floorplansFiltered: floorplans, // reset
        filterOpen: !this.state.filterOpen,
      });
    } else {
      if (options[index].title === 'All Homes') {
        this.setState({
          floorplansFiltered: floorplans, // reset
          filterOpen: !this.state.filterOpen,
          activeHomeTypeFilter: 0,
        });
      } else if (index === 1) {
        let filteredPlans = floorplans.filter(
          floorplan => floorplan.bedrooms === 0
        );

        this.setState({
          floorplansFiltered: filteredPlans,
          filterOpen: !this.state.filterOpen,
          activeHomeTypeFilter: index,
        });
      } else if (index === 2) {
        let filteredPlans = floorplans.filter(floorplan => floorplan.junior2);

        this.setState({
          floorplansFiltered: filteredPlans,
          filterOpen: !this.state.filterOpen,
          activeHomeTypeFilter: index,
        });
      } else if (index === 3) {
        let filteredPlans = floorplans.filter(
          floorplan => floorplan.bedrooms === 1
        );

        this.setState({
          floorplansFiltered: filteredPlans,
          filterOpen: !this.state.filterOpen,
          activeHomeTypeFilter: index,
        });
      } else if (index === 4) {
        let filteredPlans = floorplans.filter(
          floorplan => floorplan.bedrooms === 2 && !floorplan.junior2
        );

        this.setState({
          floorplansFiltered: filteredPlans,
          filterOpen: !this.state.filterOpen,
          activeHomeTypeFilter: index,
        });
      } else if (index === 5) {
        let filteredPlans = floorplans.filter(
          floorplan => floorplan.bedrooms === 3
        );

        this.setState({
          floorplansFiltered: filteredPlans,
          filterOpen: !this.state.filterOpen,
          activeHomeTypeFilter: index,
        });
      } else {
        let filteredPlans = floorplans.filter(
          floorplan => floorplan.bedrooms === 4
        );

        this.setState({
          floorplansFiltered: filteredPlans,
          filterOpen: !this.state.filterOpen,
          activeHomeTypeFilter: index,
        });
      }
    }
  };

  handleOpenFilter = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };

  sortPlans = filterValue => {
    if (filterValue === 0) {
      // All Homes
      return floorplans; // reset
    } else if (filterValue === 1) {
      let filteredPlans = floorplans.filter(
        floorplan => floorplan.bedrooms === 0
      );
      return filteredPlans;
    } else if (filterValue === 2) {
      let filteredPlans = floorplans.filter(floorplan => floorplan.junior2);
      return filteredPlans;
    } else if (filterValue === 3) {
      let filteredPlans = floorplans.filter(
        floorplan => floorplan.bedrooms === 1
      );
      return filteredPlans;
    } else if (filterValue === 4) {
      let filteredPlans = floorplans.filter(
        floorplan => floorplan.bedrooms === 2
      );
      return filteredPlans;
    } else if (filterValue === 5) {
      let filteredPlans = floorplans.filter(
        floorplan => floorplan.bedrooms === 3
      );
      return filteredPlans;
    } else {
      let filteredPlans = floorplans.filter(
        floorplan => floorplan.bedrooms === 4
      );
      return filteredPlans;
    }
  };

  //If filter by TYPE - check all plans for # of bedrooms and set floorplans arr for user scrolling
  setFilterByType = byType => {
    if (byType === true) {
      let plans = this.sortPlans(this.state.activeHomeTypeFilter);
      this.setState({
        floorplansFiltered: plans,
      });
    } else if (!byType) {
      this.setState({
        floorplansFiltered: floorplans,
      });
    }
    this.setState({ filterByType: byType });
  };

  renderDetails = (bedrooms, dens, junior2) => {
    if (bedrooms === 0) {
      return 'Studio';
    } else if (junior2) {
      return 'JR 2 bed';
    } else if (dens) {
      return `${bedrooms} bedroom + Den`;
    } else return `${bedrooms} bedroom`;
  };

  renderBedrooms = (bedrooms, junior) => {
    if (bedrooms === 0) {
      return 'Studio';
    } else if (junior) {
      return 'JR 2 bed';
    } else {
      return `${bedrooms} bedroom`;
    }
  };

  handleLevel = level => {
    this.setState({
      activeLevel: sliderMarks[level],
      activeSlider: level,
    });
  };

  getIndex = id => {
    return floorplans.findIndex(floorplan => floorplan.id === id);
  };

  matchToPlan = id => {
    return floorplans[this.getIndex(id)];
  };

  determineActive = id => {
    const plan = this.matchToPlan(id);

    //ADD ONE TO HOMETYPEFILTER - indices run 1 less than bedroom count
    // doesnt apply anymore, this got all changed when they added another filter
    if (plan) {
      if (this.state.activeHomeTypeFilter === 0) {
        // All Homes
        return true;
      } else if (
        this.state.activeHomeTypeFilter === 1 &&
        plan.bedrooms &&
        plan.bedrooms === 0
      ) {
        return true;
      } else if (this.state.activeHomeTypeFilter === 2 && plan.junior2) {
        return true;
      } else if (
        this.state.activeHomeTypeFilter === 3 &&
        plan.bedrooms &&
        plan.bedrooms === 1
      ) {
        return true;
      } else if (
        this.state.activeHomeTypeFilter === 4 &&
        plan.bedrooms &&
        plan.bedrooms === 2
      ) {
        return true;
      } else if (
        this.state.activeHomeTypeFilter === 5 &&
        plan.bedrooms &&
        plan.bedrooms === 3
      ) {
        return true;
      } else if (
        this.state.activeHomeTypeFilter === 6 &&
        plan.bedrooms &&
        plan.bedrooms === 4
      ) {
        return true;
      } else {
        return false; // plan.bedrooms is above 4 or doesn't exist
      }
    } else {
      return false; // no corresponding floor plan for id
    }
  };

  determineSold = id => {
    const index = this.getIndex(id);

    if (index >= 0) {
      if (floorplans[index].soldout) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  setFilterCondos = bool => {
    this.setState({ filterCondos: bool });
  };

  render() {
    return (
      <LayoutWithHero
        hero={{
          image: require('src/assets/images/floorplans/floorplans.jpg'),
          title: (
            <span>
              {/* Thoughtfully
              <br />
              Designed
              <br /> */}
              Floorplans
            </span>
          ),
        }}
        nextPage={{ text: 'Discover Next: The Team', link: '/team' }}
      >
        <MobilePopUp
          isOpen={this.state.popupOpen}
          handleClose={this.handleTogglePopup}
          floorplan={this.state.floorplansFiltered[this.state.activeIndex]}
          floorplansLength={this.state.floorplansFiltered.length}
          handleSwipeLeft={this.handleSwipeLeft}
          handleSwipeRight={this.handleSwipeRight}
          index={this.state.activeIndex}
          swipeDirection={this.state.swipeDirection}
          renderDetails={this.renderDetails}
        />

        <Content>
          <h1>Select a Floorplan from the List Below</h1>

          {/* MOBILE SELECTOR */}
          <Select
            activeHomeTypeFilter={this.state.activeHomeTypeFilter}
            handleClick={this.handleHomeTypeFilter}
            handleOpenFilter={this.handleOpenFilter}
            filterOpen={this.state.filterOpen}
          />

          <Containers>
            <FiltersContainer>
              <Title>Filters</Title>

              <h3>Homes</h3>

              <FilterBy
                bold={this.state.filterCondos}
                onClick={() => this.setFilterCondos(true)}
              >
                Condos
              </FilterBy>
              <FilterBy
                bold={!this.state.filterCondos}
                onClick={() => this.setFilterCondos(false)}
              >
                Townhomes
              </FilterBy>

              <Line />

              <h3>Display Plans</h3>
              <FilterBy
                bold={this.state.filterByType}
                onClick={() => this.setFilterByType(true)}
              >
                By Type
              </FilterBy>
              <FilterBy
                bold={!this.state.filterByType}
                onClick={() => this.setFilterByType(false)}
              >
                By Level
              </FilterBy>

              <Line />

              <h3>Home Type</h3>

              {options.map((option, index) => (
                <HomeFilter
                  key={index}
                  bold={this.state.activeHomeTypeFilter === index}
                  onClick={() => this.handleHomeTypeFilter(index)}
                >
                  {option.title}
                </HomeFilter>
              ))}
            </FiltersContainer>
            {/* {this.state.filterByType && ( */}

            <PlansContainer showContainer={this.state.filterByType}>
              <Title>Select a Plan</Title>

              <CardsWrapper>
                {this.state.floorplansFiltered.map((plan, index) => (
                  <PlanCard
                    id={plan.id}
                    bedrooms={plan.bedrooms}
                    interior={plan.interior}
                    key={index}
                    handleClick={this.handlePlanSelect}
                    index={index}
                    den={plan.den}
                    showCondoUnits={this.state.filterCondos}
                    //hideUnit={plan.listViewHidden}
                    renderBedrooms={this.renderBedrooms}
                    junior2={plan.junior2}
                    soldout={plan.soldout}
                  />
                ))}
              </CardsWrapper>
            </PlansContainer>
            {/* )} */}

            {!this.state.filterByType && (
              <Fragment>
                {this.state.filterCondos && (
                  <LevelSelectContainer>
                    <Title>Level Select</Title>
                    <LevelSelectContent>
                      <Building activeLevel={this.state.activeLevel} />
                      <Slider
                        activeLevel={this.state.activeLevel.title}
                        handleLevel={this.handleLevel}
                        activeSlider={this.state.activeSlider}
                        sliderMarks={sliderMarks}
                      />
                    </LevelSelectContent>
                  </LevelSelectContainer>
                )}

                <SelectPlan>
                  <Title>Select a Plan</Title>
                  <PlateContainer>
                    <Floorplate
                      floorplate={
                        this.state.filterCondos
                          ? this.state.activeLevel.floorplate
                          : floorplatesTH
                      }
                      handleClick={this.handlePlanSelectFromPlate}
                      outline={
                        this.state.filterCondos
                          ? this.state.activeLevel.outline
                          : outlineTH
                      }
                      determineActive={this.determineActive}
                      determineSold={this.determineSold}
                    />

                    <Compass src={imgCompass} />
                  </PlateContainer>

                  <Label>
                    {' '}
                    Level{' '}
                    {this.state.filterCondos
                      ? this.state.activeLevel.title
                      : '1'}{' '}
                  </Label>
                </SelectPlan>
              </Fragment>
            )}
          </Containers>
        </Content>
      </LayoutWithHero>
    );
  }
}

export default Floorplans;

const Content = styled.div`
  /* padding-left: 14.9vw; */
  padding-left: 15px;
  padding-right: 15px;
  /* padding-right: 14.9vw; */
  margin: -23px;
  /* overflow: auto; */
  h1 {
    text-align: center;
    @media (min-width: ${media.mdup}) {
      /* margin-bottom: 146px; */
    }
  }

  @media (min-width: ${media.smup}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const CardsWrapper = styled.div`
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; */

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media (min-width: ${media.smup}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: ${media.mdup}) {
    padding: 1.05vw;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
`;

const Title = styled.h2`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    color: white;
    text-transform: uppercase;
    width: 100%;
    background: ${({ theme }) => theme.colorBlack};
    padding-left: 2.1vw;
    padding-top: 11px;
    padding-bottom: 11px;
  }
`;

const PlansContainer = styled.div`
  margin: 0 auto;
  @media (min-width: ${media.mdup}) {
    flex: 1;
    border: 1px solid ${({ theme }) => theme.colorBlack};
    display: ${props => (props.showContainer ? 'block' : 'none')};
  }
`;

const FiltersContainer = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    margin-right: 30px;
    width: 14.6vw;
    border: 1px solid ${({ theme }) => theme.colorBlack};
    height: 719px;
    h3 {
      font-size: 14px;
      letter-spacing: 2px;
      color: ${({ theme }) => theme.colorBlack};
      font-weight: 300;
      text-transform: uppercase;
      margin-top: 30px;
      margin-left: 2.1vw;
      margin-bottom: 25px;
    }
    p {
      margin-left: 2.1vw;
      margin-bottom: 15px;
      font-size: 14px;
      letter-spacing: 1px;
      color: ${({ theme }) => theme.colorCopper};
    }
  }
`;

const Containers = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  @media (min-width: ${media.mdup}) {
    margin-top: 146px;
    max-width: 1218px;
    margin: 146px auto 0 auto;
  }
`;

const Line = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colorBlack};
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 2.1vw;
  margin-right: 2.1vw;
`;

const FilterBy = styled.p`
  cursor: pointer;
  font-weight: ${props => (props.bold ? 'bold' : '300')};
  position: relative;
  &:before {
    ${props => props.bold && bulletStyle}
  }
`;

const HomeFilter = styled.p`
  cursor: pointer;
  font-weight: ${props => (props.bold ? 'bold' : '300')};
  position: relative;
  &:before {
    ${props => props.bold && bulletStyle}
  }
`;

const bulletStyle = css`
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background: ${({ theme }) => theme.colorCopper};
  position: absolute;
  top: 50%;
  left: -12px;
`;

const LevelSelectContainer = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    width: 24.8vw;
    border: 1px solid ${({ theme }) => theme.colorBlack};
    display: block;
    min-width: 311px;
    max-width: 323px;
  }
`;

const SelectPlan = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    flex: 1;
    margin-left: 31px;
    border: 1px solid ${({ theme }) => theme.colorBlack};
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40.8vw;
  }
`;

const Label = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: normal;
  margin-bottom: 48px;
  position: relative;
`;

const LevelSelectContent = styled.div`
  display: flex;
`;

const Compass = styled.img`
  display: none;
  @media (min-width: ${media.mdup}) {
    position: absolute;
    display: block;
    bottom: 0;
    right: 0;
    padding-right: 70px;
  }
`;

const PlateContainer = styled.div`
  position: relative;
  width: 100%;
`;
