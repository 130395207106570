import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'src/styles/media';

const NextPage = ({ link, text }) => {
  return (
    <Root>
      <Background>
        <Link to={link}>{text}</Link>
      </Background>
    </Root>
  );
};

export default NextPage;

const Root = styled.div`
  padding: 0 11px;
  height: 45px;
  margin: 0 auto;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 0;
  transform: translateY(50%);

  a {
    display: block;
    padding-top: 13px;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.6px;
    color: white;
    text-align: center;
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colorPinkBrown};
  max-width: 506px;

  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${media.mdup}) {
    &:hover {
      background: white;
      border: ${({ theme }) => `1px solid ${theme.colorCopper}`};
      a {
        color: ${({ theme }) => theme.colorCopper};
      }
    }
  }
`;
