import React from 'react';
import styled, { css } from 'styled-components';
import media from 'src/styles/media';

const Button = ({ label, border, header, width, arrow, handleClick }) => {
  return (
    <ButtonWrapper
      border={border}
      header={header}
      width={width}
      arrow={arrow}
      onClick={handleClick}
    >
      <RootButton>
        <span>{label}</span>
      </RootButton>
    </ButtonWrapper>
  );
};

export default Button;

const buttonStyles = css`
  text-transform: uppercase;
  cursor: pointer;
`;

const arrowStyles = css`
  background: ${props => `url(${props.arrow})`};
  background-position: center;
  background-size: cover;
  height: 5px;
  width: 9px;
  content: '';
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translate(-50%, -50%) rotate(90deg);
`;

const RootButton = styled.div`
  ${buttonStyles}
`;

const ButtonWrapper = styled.div`
  flex: ${props => props.header && '1'};
  width: ${props => (props.width ? `${props.width}px` : '253px')};
  margin: 12px auto;
  background: #ffffff;
  position: relative;
  &:after {
    ${props => props.arrow && arrowStyles}
  }

  @media (min-width: ${media.mdup}) {
    &:hover {
      background: ${({ theme }) => theme.colorCopper};
      span {
        color: white;
      }
    }
  }
  span {
    padding: 9px;
    white-space: nowrap;

    display: block;
    text-align: center;
    /* width: 72px; */
    margin-left: ${props => props.header && 'auto'};
    font-size: 12px;
    letter-spacing: 1.6px;
    color: ${({ theme }) => theme.colorCopper};
    border: ${props => `1px solid ${props.border}`};

    @media (min-width: ${media.smup}) {
      width: auto;
      /* padding: 9px 27px; */
      /* text-align: right; */
    }
    @media (min-width: ${media.mdup}) {
      text-align: center;
      /* max-width: 165px; */
    }
  }
  a {
    text-align: right;
    display: block;
  }
`;
