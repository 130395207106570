const infoChips = [
  {
    subtext: 'Expansive Views',
    body:
      "Set in a quiet suburb, Centra's 24 floors offer unobstructed views in almost every direction.",
  },
  {
    subtext: 'Spacious Homes',
    body:
      'Centra offers floorplans that have been carefully designed to be efficient and comfortable.',
  },
  {
    subtext: 'Practical Amenities',
    body:
      'Enjoy useful amenities such as a workout room with pro-level equipment and a co-work space.',
  },
  {
    subtext: '9 Minute Walk to Skytrain',
    body:
      'Only steps from downtown, Centra’s location gives you effortless access to every urban convenience.',
  },
  {
    subtext: 'Amazing Value',
    body:
      'With everything you want and need in a home, at Centra you never have to compromise.',
  },
];

export default infoChips;
