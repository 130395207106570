const coworkingChip = {
  header: {
    title: 'Co-work Space',
    subtitle: 'Level 1 (Main Building)',
  },
  features: [
    {
      title: 'Co-work long table',
      location: [22, 36],
    },
    {
      title: 'Relaxed seating area',
      location: [45, 54],
    },
    {
      title: 'Meeting area',
      locations: [[73, 44]],
    },
    {
      title: 'Co-work pods',
      locations: [[85, 89]],
    },
    {
      title: 'Coffee Bar',
      location: [23, 26],
    },
    {
      title:'Bathroom',
      location:[48,8]
    }
  ],
  transform: 'translateY(-100px)',
};

export default coworkingChip;
