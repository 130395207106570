import React from 'react';
import styled from 'styled-components';
import SliderComponent from 'rc-slider';

import imgBackground from 'src/assets/images/floorplans/slider-scale.svg';

const Slider = ({ activeLevel, activeSlider, handleLevel, sliderMarks }) => {
  return (
    <Root background={imgBackground}>
      <StyledSlider
        marks={sliderMarks}
        step={null}
        vertical
        onChange={handleLevel}
        level={activeLevel}
        value={activeSlider}
      />
    </Root>
  );
};

export default Slider;

const Root = styled.div`
  height: 436px;
  margin-top: 20px;
  background: ${props => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const StyledSlider = styled(SliderComponent)`
  height: 100%;
padding:20px;

  &::before {
    content: '';
    width: 23px;
    height: 2px;
    position: absolute;
    top: 0;
    left: -50%;
    z-index: 1;
  }
  &::after {
    content: '';
    width: 23px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: -50%;
    z-index: 1;
  }

  .rc-slider-rail {
    background-color: transparent;
    width: 2px;
  }
  &.rc-slider-vertical {
    width: 12px;
  }
  .rc-slider-handle {
    width: 46.8px;
    height: 46.8px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colorCopper};
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-17px);
    z-index: 2;
    &::before {
      content: '${props => props.level}';
      display: block;
      color: ${({ theme }) => theme.colorCopper};
      font-size: 10px;
      font-weight: 500;
      z-index: 3;
      letter-spacing: 1.5px;
    }
    &::after {
      content: '';
      width: 20px;
      height: 20px;
      border-left: 1px solid ${({ theme }) => theme.colorCopper};
      border-bottom: 1px solid ${({ theme }) => theme.colorCopper};

      position: absolute;
      left: -6px;
      top: 12px;
      background: white;
      transform: rotate(45deg);
      z-index: 1;
    }
  }
  .rc-slider-dot {
    display: none;
  }
  .rc-slider-track {
    display: none;
  }
`;
