import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'src/styles/media';

import Button from './Button';
import ContactFragment from './ContactFragment';

import imgLogo from 'src/assets/images/logo/logo.svg';
import imgRennieLogo from 'src/assets/images/logo/rennie-logo.svg';
import imgEverestLogo from 'src/assets/images/logo/everest-logo.svg';

const location = window.location.pathname;

const Footer = () => {
  return (
    <Root>
      <Navigation>
        <ButtonWrapper marginLeft>
          <Button type="register" />
        </ButtonWrapper>
        <FlowerLogo src={imgLogo} alt="Centra Logo" />
        <ButtonWrapper>
          <Button
            label="Back to Top"
            handleClick={() =>
              location === '/floorplans'
                ? window.moveTo(0, 0)
                : window.scrollTo(0, 0)
            }
          />
        </ButtonWrapper>
      </Navigation>

      <ContactWrapper>
        <ContactFragment
          title="Contact Us"
          type="contact"
          content={[
            {
              link: 'mailto:info@centrasurrey.com',
              body: 'info@centrasurrey.com',
            },
            {
              link: 'tel:1-604-498-3887',
              body: '604.498.3887',
            },
          ]}
          flex={1}
        />

        {/* <ContactFragment
          title="Presentation centre open by appointment"
          type="location"
          content={{
            link:
              'https://www.google.ca/maps/place/15225+104+Ave,+Surrey,+BC+V3R+6Y8/@49.1918211,-122.8024965,17z/data=!3m1!4b1!4m5!3m4!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!8m2!3d49.1918211!4d-122.8003077',
            body: (
              <p>
                #103-15225 - 104 Ave
                <br />
                Surrey, BC
                <br />
                Daily 12-5pm except Friday<br/>
                Or by appointment
              </p>
            ),
          }}
        /> */}

        <ContactFragment
          title="Site Address"
          type="location"
          content={{
            link:
              'https://www.google.ca/maps/place/13868+101+Ave,+Surrey,+BC+V3T+5T1/@49.1854979,-122.8400151,17z/data=!3m1!4b1!4m5!3m4!1s0x5485d77df9ac0c49:0xcbc6ef3fc40e0cb!8m2!3d49.1854944!4d-122.8378264',
            body: (
              <p>
                13868 - 101 Ave
                <br />
                Surrey, BC
              </p>
            ),
          }}
        />
      </ContactWrapper>

      <Bottom>
        <PageNavigation>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-use">Terms of Use</Link>
        </PageNavigation>

        <Copyright>
          <p>&#169; Everest Group of Companies</p>
          <p>
            The developer reserves the right to make changes and modifications
            to the information contained herein without prior notice.
            Dimensions, sizes, areas, specifications layout and materials are
            approximate only and subject to change without notice. Artist’s
            renderings and maps are representations only and may not be
            accurate. Prices and promotions are subject to change without
            notice. See staff for details. E.&O.E.
          </p>
        </Copyright>

        <Logos>
          <a
            href="https://rennie.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo src={imgRennieLogo} alt="Marketing by Rennie" />
          </a>
          <a
            href="https://everestgroup.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo src={imgEverestLogo} alt="Everest Group of Companies" />
          </a>
        </Logos>
      </Bottom>
    </Root>
  );
};

export default Footer;

const Root = styled.div`
  background: ${({ theme }) => theme.colorBlack};
  @media (min-width: ${media.mdup}) {
    min-height: 415px;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 69px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  span {
    padding: 0;
  }
`;

const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  max-width: 350px;
  margin: 0 auto;

  > div {
    margin-bottom: 45px;
    &:first-child {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    color: #ffffff;
  }
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: none;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  /* margin-top: 56px; */
  padding: 0 11px;
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
    align-items: flex-end;
    padding-left: 78px;
    padding-right: 78px;
    margin-top: 0px;
  }
`;

const PageNavigation = styled.div`
  flex: 1;
  order: 1;
  /* margin-top: 40px; */
  margin-bottom: 20px;
  a {
    text-decoration: underline;
    font-size: 12px;
    font-weight: 300;
    line-height: 2;
    letter-spacing: 0.9px;
    color: white;
    margin-right: 15px;
    display: inline-block;
  }
  @media (min-width: ${media.mdup}) {
    margin-top: 0px;
    margin-bottom: 30px;
    order: 0;
  }
`;

const Copyright = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  p {
    font-size: 11px;
    opacity: 0.75;
    font-weight: 300;
    line-height: 1.82;
    letter-spacing: 0.8px;
    color: white;
    max-width: 451px;
    width: 100%;
    &:last-child {
      margin-bottom: 30px;
      margin-top: 11px;
    }
  }
`;

const Logos = styled.div`
  order: 1;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-left: 52px;
  padding-right: 52px;
  max-width: 350px;

  @media (min-width: ${media.mdup}) {
    max-width: none;
    justify-content: flex-end;
    align-items: flex-end;
    order: 0;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
  }
`;

const FlowerLogo = styled.img`
  height: 74px;
  width: auto;
  margin-right: 32px;
  margin-left: 32px;
  @media (min-width: ${media.mdup}) {
    margin-left: 60px;
    margin-right: 60px;
  }
`;

const Logo = styled.img`
  opacity: 0.9;
  margin-bottom: 30px;

  @media (min-width: ${media.mdup}) {
    margin-left: 60px;
    /* margin-bottom: 30; */
  }
`;

const ButtonWrapper = styled.div`
  flex: 1;
  > div {
    margin-left: ${props => props.marginLeft && 'auto'};
  }
`;
