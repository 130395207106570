import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import Hero from 'components/Hero';
import NextPage from 'components/NextPage';

const LayoutWithHero = ({ hero, children, nextPage, hideOverflow }) => {
  return (
    <Root hideOverflow={hideOverflow}>
      <Hero image={hero.image} title={hero.title} />
      <Container>{children}</Container>
      {nextPage && <NextPage text={nextPage.text} link={nextPage.link} />}
    </Root>
  );
};

export default LayoutWithHero;

const Root = styled.div`
  background: ${({ theme }) => theme.colorOffWhite};
  overflow: ${props => props.hideOverflow && 'hidden'};
  padding-bottom: 80px;
  position: relative;
  /* -webkit-transform: translate3d(0, 0, 0); */

  @media (min-width: ${media.mdup}) {
    padding-bottom: 120px;
  }
`;

const Container = styled.div`
  padding-top: 60px;
  padding-left: 23px;
  padding-right: 23px;
  @media (min-width: ${media.mdup}) {
    padding-top: 80px;
  }
`;
