import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import imgDropdown from 'src/assets/images/icons/dropdown-icon.svg';

const options = [
  'All Homes',
  'Studio',
  'JR 2 bed',
  '1 Bedroom',
  '2 Bedroom',
  '3 Bedroom',
  '4 Bedroom',
];

const Select = ({
  activeHomeTypeFilter,
  handleClick,
  filterOpen,
  handleOpenFilter,
}) => {
  return (
    <Root>
      <Filter dropdown={imgDropdown} onClick={() => handleOpenFilter()}>
        <span>Filters</span>
      </Filter>
      <FilterMenu filterOpen={filterOpen}>
        <h2>Home Type</h2>
        {options.map((option, index) => (
          <Option
            key={index}
            isActive={index === activeHomeTypeFilter}
            onClick={() => handleClick(index)}
          >
            {option}
          </Option>
        ))}
      </FilterMenu>
    </Root>
  );
};

export default Select;

const Root = styled.div`
  @media (min-width: ${media.mdup}) {
    display: none;
  }
  span {
    color: white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
`;

const FilterMenu = styled.div`
  width: 210px;
  margin-top: -52px;
  position: absolute;
  left: 50%;
  margin-left: -105px;
  background: #ffffff;
  overflow: hidden;
  height: ${props => (props.filterOpen ? 'auto' : '0')};
  z-index: 99;

  p {
  }
  h2 {
    text-transform: uppercase;
    margin: 25px auto;
    text-align: center;
    font-size: 14px;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colorBlack};
    font-weight: 300;
    background: white;
  }
`;

const Filter = styled.div`
  background: ${({ theme }) => theme.colorBlack};

  width: 210px;
  height: 40px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-left: 30px;
  margin: 40px auto 52px auto;
  position: relative;
  &:after {
    content: '';
    background-image: ${props => `url(${props.dropdown})`};
    background-size: contain;
    background-position: center;
    width: 10px;
    height: 6px;
    position: absolute;
    right: 30px;
  }
`;

const Option = styled.p`
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colorCopper};
  padding-top: 10px;
  padding-bottom: 20px;
  background: ${props => (props.isActive ? '#f2e6e1' : '#ffffff')};
  height: 40px;
`;
