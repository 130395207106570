import React, { Component } from 'react';
import Counter from 'components/Counter';

class About extends Component {
  render() {
    return (
      <main>
        <h1>About</h1>
        <Counter />
      </main>
    );
  }
}

export default About;
