import React from 'react';

export default {
  units: [
    {
      id: 'b1',
      mockup: (
        <g id="unit-b1" transform="translate(158.000000, 203.000000)">
          <polygon
            id="fill-b1"
            stroke="#2A2827"
            // fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="91.0084321 0.904949894 6.73049418 0.904949894 6.73049418 2.1676283 0.0584907674 2.1676283 0.0584907674 16.1973884 17.1950048 16.1973884 17.1950048 58.6374127 47.1136728 58.6374127 47.1136728 74 92.7642224 74 92.7642224 57.1292135 91.2542427 57.1292135"
          />
          <text
            id="B1"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="46.256333" y="29">
              B1
            </tspan>
          </text>
          <text
            id="01"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="46.151333" y="46">
              01
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'e6',
      mockup: (
        <g id="unit-e6" transform="translate(249.000000, 189.000000)">
          <polygon
            id="fill-e6"
            stroke="#2A2827"
            // fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="143 18.4123899 63.1817697 18.4123899 63.1817697 0.875189797 33.2631017 0.875189797 33.2631017 14.9049499 0.00843206319 14.9049499 0.00843206319 71.0239903 1.51841178 71.0239903 1.51841178 72.5321895 29.9271 72.5321895 29.9271 87.8947768 100.158715 87.8947768 100.158715 62.676283 143 62.676283"
          />
          <text
            id="E6"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="52.328333" y="43">
              E6
            </tspan>
          </text>
          <text
            id="02"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="53.052333" y="61">
              08
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'a1',
      mockup: (
        <g id="unit-a1" transform="translate(282.000000, 149.000000)">
          <polygon
            id="fill-a1"
            stroke="#2A2827"
            // fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="110 38.4901306 100.027111 38.4901306 100.027111 10.7112056 98.4820152 10.7112056 98.4820152 9.41345278 19.4363326 9.41345278 19.4363326 0.223959915 0.263101718 0.223959915 0.263101718 40.5945946 30.1817697 40.5945946 30.1817697 58.1317947 110 58.1317947"
          />
          <text
            id="A1"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="48.011333" y="30">
              A1
            </tspan>
          </text>
          <text
            id="01-copy"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="48.151333" y="48">
              07
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'f1',
      mockup: (
        <g id="unit-f1" transform="translate(281.000000, 51.000000)">
          <polygon
            id="fill-f1"
            stroke="#2A2827"
            // fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="111 35.5460067 68.2991781 35.5460067 68.2991781 0.471606438 31.1817697 0.471606438 31.1817697 36.9139083 20.4363326 36.9139083 20.4363326 44.8056483 0.736364607 44.8056483 0.736364607 75.9867902 1.26310172 75.9867902 1.26310172 98.2239599 20.4363326 98.2239599 20.4363326 107.413453 99.4820152 107.413453 99.4820152 108.711206 111 108.711206"
          />
          <text
            id="F1"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="49.323333" y="64">
              F1
            </tspan>
          </text>
          <text
            id="06"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="47.450333" y="82">
              06
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'd1',
      mockup: (
        <g id="unit-d1" transform="translate(209.000000, 46.000000)">
          <polygon
            id="fill-d1"
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="103.18177 5.47160644 74.5272708 5.47160644 74.5272708 0 0.784075141 0 0.784075141 37.8102035 15.6380617 37.8102035 15.6380617 58.2585788 6.15679368 58.2585788 6.15679368 80.9867902 72.7363646 80.9867902 72.7363646 49.8056483 92.4363326 49.8056483 92.4363326 41.9139083 103.18177 41.9139083"
          />
          <text
            id="D1"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="35.084333" y="37">
              D1
            </tspan>
          </text>
          <text
            id="05"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="34.517333" y="55">
              05
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'g1',
      mockup: (
        <g id="unit-g1" transform="translate(63.000000, 46.000000)">
          <polygon
            id="fill-g1"
            stroke="#2A2827"
            // fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="161.708293 37.8102035 146.854307 37.8102035 146.854307 7.10542736e-15 101.590031 7.10542736e-15 101.590031 5.33130884 34.5539545 5.33130884 34.5539545 40.4057091 0 40.4057091 0 81.2673854 9.72707866 81.2673854 9.72707866 79.6890374 101.590031 79.6890374 101.590031 80.9517158 152.227025 80.9517158 152.227025 58.2585788 161.708293 58.2585788"
          />
          <text
            id="G1"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="77.238333" y="38">
              G1
            </tspan>
          </text>
          <text
            id="04"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="76.132333" y="56">
              04
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'c1',
      mockup: (
        <g id="unit-c1" transform="translate(63.000000, 125.000000)">
          <polygon
            id="fill-c1"
            stroke="#2A2827"
            // fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="101.590031 0.689037352 9.72707866 0.689037352 9.72707866 2.23231096 8.18198314 2.23231096 8.18198314 34.1500152 0 34.1500152 0 70.8378378 35.747892 70.8378378 35.747892 66.6289098 61.3473156 66.6289098 61.3473156 37.2716368 80.5205465 37.2716368 80.5205465 46.9521713 101.590031 46.9521713"
          />
          <text
            id="C1"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="26.497333" y="28">
              C1
            </tspan>
          </text>
          <text
            id="03"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="25.678333" y="46">
              03
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'e2',
      mockup: (
        <g id="unit-e2" transform="translate(63.000000, 162.000000)">
          <polygon
            id="fill-e2"
            stroke="#2A2827"
            // fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="94.9180275 43.1325539 101.590031 43.1325539 101.590031 9.95217127 80.5205465 9.95217127 80.5205465 0.271636805 61.4175472 0.271636805 61.4175472 29.6289098 35.747892 29.6289098 35.747892 33.8378378 0 33.8378378 0 75.9271181 34.5539545 75.9271181 34.5539545 115 113.599637 115 113.599637 99.6374127 112.054542 99.6374127 112.054542 57.2324628 94.9180275 57.2324628"
          />
          <text
            id="E2"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="58.052333" y="69.5268752">
              E2
            </tspan>
          </text>
          <text
            id="02"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="58.052333" y="87.5268752">
              02
            </tspan>
          </text>
        </g>
      ),
    },
  ],
};
