import React from 'react';

export default {
  outline: (
    <g
      id="level-20"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="bg-lines"
        transform="translate(53.000000, 32.000000)"
        stroke="#2A2827"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      >
        <path d="M184.5,141 L184.5,118" id="Path" />
        <path d="M147.5,118 L147.5,141" id="Path" />
        <path d="M135,117.5 L216,117.5" id="Path" />
        <path d="M216,141.5 L135,141.5" id="Path" />
        <path d="M134.5,171 L134.5,117" id="Path" />
        <path d="M215.5,118 L215.5,158" id="Path" />
        <path
          d="M155,118 L185.361835,141 L216,118 M155,141 L185.361835,118 L216,141"
          id="Shape"
        />
        <path
          d="M200,156.309383 L152,156.309383 M155.406671,141.195266 L155.406671,118"
          id="Shape"
        />
        <path
          d="M165.675011,13.7487774 L165.675011,0 L124.196119,0 L124.196119,13.7487774 M232,13.7487774 L232,0 L172.341875,0 L172.341875,13.7487774 M15.3612798,94.3166131 L6.63249889,94.3166131 L0,137.212799 L15.3612798,139.515719 M49.9327507,35.8843091 L20.9971856,31.65656 L17.5606577,54.204555 M203.133165,244.281403 L231.553251,244.281403 M128.869797,244.281403 L157.53044,244.281403 M16.3578729,206.369149 L16.3578729,226.064273 L49.9327507,226.064273 M196.947415,141.30306 L196.947415,156.667319 M151.585247,141.30306 L151.585247,156.667319 M200.005925,156.667319 L200.005925,172.031577 M154.678122,156.667319 L154.678122,172.031577"
          id="Shape"
        />
        <polygon
          id="Shape"
          points="153.343361 94.3291759 153.343361 101.21856 133.167568 101.21856 133.167568 94.3291759"
        />
        <path
          d="M307.25,173.835505 L343.862617,173.835505 L307.25,173.835505 Z M307.387383,220 L344,220 L344,155.732452 L334.142757,155.732452 L334.142757,128.184328 L343.862617,128.184328 L343.862617,54.9850282 L307.25,54.9850282 L307.387383,220 Z"
          id="Shape"
        />
        <path d="M135,102 L155,95 M135,95 L155,102" id="Shape" />
      </g>
    </g>
  ),
};
