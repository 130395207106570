import React from 'react';

export default {
  units: [
    {
      id: 'e7',
      mockup: (
        <g id="fill-e7" transform="translate(209.000000, 190.000000)">
          <polygon
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="150 15.1437268 76.6054054 15.1437268 76.6054054 0.262657758 57.9393655 0.262657758 57.9393655 13.5936154 1.56380729 13.5936154 1.56380729 70.0521158 0.0540540541 70.0521158 0.0540540541 87 45.4152761 87 45.4152761 71.6022272 73.7917744 71.6022272 73.7917744 87 143.858049 87 143.858049 61.6126206 150 61.6126206"
          />
          <text
            id="E7"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="56.486333" y="41">
              E7
            </tspan>
          </text>
          <text
            id="05"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="56.017333" y="59">
              05
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'g3',
      mockup: (
        <g id="fill-g3" transform="translate(67.000000, 45.000000)">
          <polygon
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="115.050059 37.9605048 110.211986 37.9605048 110.211986 0.0688938382 101.256404 0.0688938382 101.256404 5.44261321 34.5184489 5.44261321 34.5184489 40.5095768 1.42108547e-14 40.5095768 1.42108547e-14 81.5703044 8.16639248 81.5703044 8.16639248 113.674833 1.42108547e-14 113.674833 1.42108547e-14 118.29072 60.8361927 118.29072 60.8361927 128.314774 101.496592 128.314774 101.496592 81.3291759 115.050059 81.3291759"
          />
          <text
            id="G3"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="50.765333" y="65">
              G3
            </tspan>
          </text>
          <text
            id="02-copy"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="51.052333" y="83">
              02
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'e1',
      mockup: (
        <g id="fill-e1" transform="translate(177.000000, 45.000000)">
          <polygon
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="108.605405 37.6849295 107.33584 37.6849295 107.33584 6.88938382 110.149471 6.88938382 110.149471 -7.10542736e-15 0.349236193 -7.10542736e-15 0.349236193 37.891611 5.18730905 37.891611 5.18730905 81.3291759 89.9393655 81.3291759 89.9393655 79.2623608 108.605405 79.2623608"
          />
          <text
            id="E1"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="43.529333" y="38">
              E1
            </tspan>
          </text>
          <text
            id="03"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="42.178333" y="56">
              03
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'g2',
      mockup: (
        <g id="fill-g2" transform="translate(284.000000, 50.000000)">
          <polygon
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="75 35.5095768 68.8580494 35.5095768 68.8580494 0.339272457 3.14947121 0.339272457 3.14947121 1.88938382 0.335840188 1.88938382 0.335840188 32.6849295 1.60540541 32.6849295 1.60540541 74.2623608 2.05146886 74.2623608 2.05146886 140.262658 1.77696827 140.262658 1.77696827 155.143727 75 155.143727"
          />
          <text
            id="G2"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="27.534333" y="76">
              G2
            </tspan>
          </text>
          <text
            id="04"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="27.632333" y="94">
              04
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'g4',
      mockup: (
        <g id="fill-g4" transform="translate(67.000000, 163.000000)">
          <polygon
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="143.563807 40.5936154 101.496592 40.5936154 101.496592 10.3147736 60.8361927 10.3147736 60.8361927 0.290720119 -2.84217094e-14 0.290720119 -2.84217094e-14 75.0060876 34.5184489 75.0060876 34.5184489 114 113.437368 114 113.437368 98.6022272 142.088367 98.6022272 142.088367 97.0521158 143.563807 97.0521158"
          />
          <text
            id="G4"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="57.219333" y="57">
              G4
            </tspan>
          </text>
          <text
            id="01"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="59.151333" y="75">
              01
            </tspan>
          </text>
        </g>
      ),
    },
  ],
};
