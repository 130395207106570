import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import LayoutWithHero from 'components/LayoutWithHero';
import TextBlock from 'components/TextBlock';
import AmenitiesList from './elements/List';
import FeaturedImage from 'components/FeaturedImage';
import Layout from './elements/Layout';
import FeatureAccordion from './sections/FeatureAccordion';
import ScrollAnimation from 'components/ScrollAnimation';
import ParallaxContainer from 'components/ParallaxContainer';

import imgFeatureCoworking from 'src/assets/images/amenities/coworking.jpg';

import imgClubhouse from 'src/assets/images/amenities/clubhouse.png';
import imgCoworking from 'src/assets/images/amenities/coworking-dollhouse.png';
import imgCoworkingBubble from 'src/assets/images/amenities/group-9.png';

import clubroomChip from 'src/data/amenities/clubroomChip';
import coworkingChip from 'src/data/amenities/coworkingChip';
import amenities from 'src/data/amenities/amenities';
import features from 'src/data/amenities/features';

class Amenities extends Component {
  state = {
    activeClubroomIndex: null,
    activeCoworkingIndex: null,
    openFeature: 0,
  };

  handleClubroomIndex = index => {
    if (this.state.activeClubroomIndex === index) {
      this.setState({ activeClubroomIndex: null });
    } else this.setState({ activeClubroomIndex: index });
  };

  handleCoworkingIndex = index => {
    if (this.state.activeCoworkingIndex === index) {
      this.setState({ activeCoworkingIndex: null });
    } else this.setState({ activeCoworkingIndex: index });
  };

  handleOpenFeature = index => {
    if (this.state.openFeature === index) {
      this.setState({ openFeature: null });
    } else this.setState({ openFeature: index });
  };

  render() {
    return (
      <LayoutWithHero
        hero={{
          image: require('src/assets/images/amenities/amenities.jpg'),
          title: (
            <span>
              {/* Practical
              <br /> Amenities */}
              Amenities
            </span>
          ),
        }}
        nextPage={{ text: 'Discover Next: The Interiors', link: '/interiors' }}
      >
        <ScrollAnimation>
          <TextblockWrapper>
            <TextBlock
              pWidth={658}
              text={{
                alignment: 'center',
                title: <h1>Simply Practical</h1>,
                body:
                  'In a collection of homes that are effortlessly practical, Centra’s amenities are no different. Designed to give you conveniences you’ll use every day, Centra features flexible co-work spaces with long tables, meeting rooms, cubicles, and a relaxed seating area for when you want to work from home, have a meeting, study session, or simply want to get to know your neighbours.',
              }}
            />
          </TextblockWrapper>
        </ScrollAnimation>

        <ScrollAnimation>
          <FeaturedImage
            image={imgFeatureCoworking}
            label="Co-working Space"
            noGradientOverlay
          >
            <ParallaxContainer right="-30px" bottom="-20px">
              <Bubble src={imgCoworkingBubble} />
            </ParallaxContainer>
          </FeaturedImage>
        </ScrollAnimation>

        <ScrollAnimation>
          <AmenitiesList amenities={amenities} />
        </ScrollAnimation>

        <ScrollAnimation>
          <Layout
            chip={clubroomChip}
            handleClick={this.handleClubroomIndex}
            activeIndex={this.state.activeClubroomIndex}
            chipOrientation="row"
            image={imgClubhouse}
            height={71.2}
            desktopHeight="44vw"
            width="51vw"
            dot={[95, 70]}
            chipImage={require('src/assets/images/amenities/yoga.png')}
          />
        </ScrollAnimation>

        <ScrollAnimation>
          <Layout
            chip={coworkingChip}
            handleClick={this.handleCoworkingIndex}
            activeIndex={this.state.activeCoworkingIndex}
            chipOrientation="row-reverse"
            image={imgCoworking}
            height={91.2}
            desktopHeight="42.4vw"
            width="47vw"
            transform
            dot={[-5, 48]}
            chipImage={require('src/assets/images/amenities/co-work.png')}
          />
        </ScrollAnimation>

        <ScrollAnimation>
          <FeatureAccordion
            features={features}
            handleClick={this.handleOpenFeature}
            activeIndex={this.state.openFeature}
          />
        </ScrollAnimation>
      </LayoutWithHero>
    );
  }
}

export default Amenities;

const TextblockWrapper = styled.div`
  margin-bottom: 60px;

  @media (min-width: ${media.mdup}) {
    margin-bottom: 81px;
  }
  p {
    text-align: center;
  }
`;

const Bubble = styled.img`
  display: none;
  @media (min-width: ${media.mdup}) {
    /* position: absolute; */
    right: -30px;
    bottom: -20px;
    display: block;
  }
`;
