import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const Chip = ({ chip, activeIndex, handleClick, dot }) => {
  return (
    <Root>
      <Dot left={dot[0]} top={dot[1]} />

      <Title>
        <h3>{chip.header.subtitle}</h3>
        <h2>{chip.header.title}</h2>
      </Title>
      <List>
        {chip.features.map((item, index) => (
          <CardItem
            key={index}
            active={activeIndex === index}
            onClick={() => handleClick(index)}
          >
            {item.title}
          </CardItem>
        ))}
      </List>
    </Root>
  );
};

export default Chip;

const Root = styled.div`
  background: #ffffff;
  padding: 33px 59px;
  margin-top: 30px;
  margin-bottom: 70px;
  @media (min-width: ${media.mdup}) {
    padding: 30px;
    width: 21.8vw;
    height: 23.4vw;
    margin-top: 15px;

    min-height: 337px;
    min-width: 314px;
  }
`;

const List = styled.ol`
  li {
    counter-increment: list;
    list-style-type: none;
    position: relative;
    /* margin-left: 59px; */
    font-size: 14px;
    line-height: 2.5;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colorBlack};
    margin-left: 30px;
  }

  li:before {
    color: ${({ theme }) => theme.colorCopper};
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 1px;
    content: counter(list) '';
    left: -32px;
    position: absolute;
    text-align: right;
    /* width: 26px; */
  }
`;

const CardItem = styled.li`
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
`;

const Title = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    h2,
    h3 {
      text-transform: uppercase;
      @media (min-width: ${media.mdup}) {
        text-align: left;
      }
    }
    h2 {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 3px;
      color: ${({ theme }) => theme.colorBlack};
      margin-top: 4px;
      margin-bottom: 40px;
      @media (min-width: ${media.smup}) {
        margin-bottom: 30px;
        margin-top: 4px;
        /* margin-top: 30px; */
      }
    }
    h3 {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 2px;
      color: ${({ theme }) => theme.colorBlack};
    }
  }
`;

const Dot = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.colorCopper};
    opacity: 0.9;
    border-radius: 50px;
    overflow: hidden;
    position: absolute;
    top: ${props => `${props.top}%`};

    left: ${props => `${props.left}%`};
  }
`;
