import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import LayoutWithHero from 'components/LayoutWithHero';
import TextBlock from 'components/TextBlock';
import ScrollAnimation from 'components/ScrollAnimation';

import imgEverest from 'src/assets/images/team/everest-logo.svg';
import imgAPA from 'src/assets/images/team/apa-logo.png';
import imgMetroCan from 'src/assets/images/team/metrocan-logo.png';
import imgCristina from 'src/assets/images/team/co-logo.png';
import imgRennie from 'src/assets/images/team/rennie-logo.svg';

const text = [
  {
    header: { title: 'Everest Group', subtitle: 'Developer' },
    body:
      'Founded in 2002, Everest is a vertically-integrated land developemnt and asset management group headquartered in Canada.  Everest specializes in multi-family real estate, student residences, commercial/retail mixed-use communities, and hospitality properties',
    link: 'https://everestgroup.ca/',
    image: imgEverest,
  },
  {
    header: {
      title: 'Atelier Pacific Architecture',
      subtitle: 'Architect',
    },
    body:
      'Founded in 1993, Atelier Pacific Architecture Inc. extensive experience includes landmark projects in the commercial, educational, retail, community, multi-family residential, recreational and hospitality market sectors.  Operating from a belief that "Every project is an opportunity to explore new solutions", their significant portfolio includes projects in British Columbia, Alberta, Saskatchewan, and Ontario',
    link: 'http://atelierpacific.com/',
    image: imgAPA,
  },
  {
    header: {
      title: 'Metrocan',
      subtitle: 'Construction',
    },
    body:
      "Through our multi-residential, commercial, industrial and institutional work we've helped shape the skyline of Vancouver.  We have built office towers, medical centres and skyscrapers that reach into the west coast air and homes for hardworking people and their families.  Trust in our people to deliver.  Trust in Metro-Can to make it happen.",
    link: 'https://www.metrocan.com/',
    image: imgMetroCan,
  },
  {
    header: {
      title: 'Cristina Oberti',
      subtitle: 'Interior Design',
    },
    body:
      'Favouring an approach of simplicity, Cristina Oberti Interior Design creates spaces with the essence of timelessness.  The firm offers leading industry experience, extensive knowledge, and an eye for style.  Working in tandem with the developer and architect, they produce homes, that are easy to call home.',
    link: 'https://cointeriordesign.com/',
    image: imgCristina,
  },
  {
    header: {
      title: 'Rennie',
      subtitle: 'Sales & Marketing',
    },
    body:
      "For more than forty years, Rennie has partnered with developers throughout Western Canada and Washington State to help acquire, plan, design, approve, market and sell some of the region's largest and most successful communities.  Integrating data intelligence, strategic perspective, and industry experience, its head office team of 70 and more 150 Realtors in the field work seamlessly to deliver products and sales to build their clients' brands and ensure continued success.",
    link: 'https://rennie.com/',
    image: imgRennie,
  },
];
const Team = () => {
  return (
    <LayoutWithHero
      hero={{
        image: require('src/assets/images/team/hero.jpg'),
        title: (
          <span>
            The Team that Works <br />
            For You
          </span>
        ),
      }}
      nextPage={{ text: 'Register Now', link: '/register' }}
    >
      <Content>
        {text.map((textblock, index) => (
          <ScrollAnimation key={index}>
            <Container>
              <LogoWrapper>
                <Logo src={textblock.image} />
              </LogoWrapper>

              <TextWrapper>
                <TextBlock text={textblock} key={index} pWidth={618} />
                <ExternalLink
                  href={textblock.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Site
                </ExternalLink>
              </TextWrapper>
            </Container>
          </ScrollAnimation>
        ))}
      </Content>
    </LayoutWithHero>
  );
};

export default Team;

const ExternalLink = styled.a`
  font-size: 12px;
  letter-spacing: 1.6px;
  color: ${({ theme }) => theme.colorCopper};
  text-transform: uppercase;
  display: block;
  margin-top: 24px;
  margin-bottom: 80px;
  &:hover {
    font-weight: 700;
  }
`;

const Container = styled.div`
  @media (min-width: ${media.mdup}) {
    display: flex;
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    width: auto;
  }
`;

const TextWrapper = styled.div`
  @media (min-width: ${media.mdup}) {
    width: 42.9vw;
  }
`;

const LogoWrapper = styled.div`
  @media (min-width: ${media.mdup}) {
    width: 12.7vw;
    margin-right: 4.2vw;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
