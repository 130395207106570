import React from 'react';
import styled from 'styled-components';

const Floorplate = ({
  handleClick,
  floorplate,
  outline,
  determineActive,
  determineSold,
}) => {
  return (
    <SVG
      width="450px"
      height="310px"
      viewBox="0 0 450 310"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {outline.outline}
      {floorplate.units.map((unit, index) => {
        return (
          <G
            key={index}
            onClick={() => handleClick(unit.id)}
            active={determineActive(unit.id)}
            soldout={determineSold(unit.id)}
          >
            {unit.mockup}
          </G>
        );
      })}
    </SVG>
  );
};

export default Floorplate;

const SVG = styled.svg`
  height: 100%;
  width: 100%;
`;

const G = styled.g`
  cursor: ${props => (props.soldout || !props.active ? 'default' : 'pointer')};
  polygon {
    fill: ${props => (props.active ? 'white' : 'transparent')};
  }
  text {
    opacity: ${props => {
      if (props.active) {
        return '1';
      } else {
        return '0.5';
      }
    }};

    text-decoration: ${props => (props.soldout ? 'line-through' : 'none')};
  }
`;
