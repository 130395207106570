import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const List = ({ amenities }) => {
  return (
    <Root>
      <h3>Everyday Conveniences</h3>
      <h2>Centra's Amenities Includes:</h2>
      <ul>
        {amenities.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </Root>
  );
};

export default List;

const Root = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
  ul {
    list-style: none;
    text-align: center;
    @media (min-width: ${media.smup}) {
      columns: 2;
      max-width: 50vw;
      margin: 0 auto;
    }
  }
  li {
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: normal;
    @media (min-width: ${media.smup}) {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  h2,
  h3 {
    text-transform: uppercase;
    text-align: center;
  }
  h2 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 3px;
    color: ${({ theme }) => theme.colorBlack};
    margin-top: 4px;
    margin-bottom: 4px;
    @media (min-width: ${media.smup}) {
      margin-bottom: 24px;
      margin-top: 4px;
      /* margin-top: 30px; */
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: 54px;
    }
  }
  h3 {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3.8px;
    color: ${({ theme }) => theme.colorCopper};
  }
`;
