import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import TextHeader from 'components/TextHeader';

const TextBlock = ({ text, pWidth }) => {
  return (
    <Root alignment={text.alignment} pWidth={pWidth}>
      {text.header ? <TextHeader header={text.header} /> : text.title}
      {typeof text.body === 'object' ? text.body : <p>{text.body}</p>}
    </Root>
  );
};

export default TextBlock;

const Root = styled.div`
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.alignment};
  /* REMOVED FROM FLOORPLANS */

  h1 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 3px;
    color: ${({ theme }) => theme.colorBlack};
    margin-bottom: 24px;
  }
  p {
    font-size: 14px;
    font-weight: 300;
    @media (min-width: ${media.smup}) {
      max-width: ${props => (props.pWidth ? `${props.pWidth}px` : '382px')};
    }
  }
  span {
    font-weight: 500;
  }
`;
