import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

class ImageWithPOI extends Component {
  state = {
    activeIndex: 0,
  };

  togglePOI = index => {
    if (this.state.activeIndex === index) {
      this.setState({ activeIndex: null });
    } else this.setState({ activeIndex: index });
  };
  render() {
    const { image, label, points, halfsized } = this.props;
    return (
      <Root background={image} halfsized={halfsized}>
        {points &&
          points.map((point, index) => {
            return (
              <Point
                top={point.location[0]}
                left={point.location[1]}
                key={index}
                onClick={() => this.togglePOI(index)}
                isActive={this.state.activeIndex === index}
              >
                <POILabel active={this.state.activeIndex === index}>
                  {point.description}
                </POILabel>
              </Point>
            );
          })}

        <Label>
          <span>{label}</span>
        </Label>
        {this.props.children}
      </Root>
    );
  }
}
export default ImageWithPOI;

const Root = styled.div`
  background: ${props =>
    `linear-gradient(to bottom, #d8d8d8, #d8d8d8), url(${props.background})`};
  background-size: cover, cover;
  background-position: center, center;
  background-blend-mode: multiply;
  padding: 0 23px;
  height: 69.3vw;
  position: relative;
  margin-bottom: 83px;

  @media (min-width: ${media.mdup}) {
    width: ${props => (props.halfsized ? '41.7vw' : '74.5vw')};
    height: ${props => (props.halfsized ? '50.1vw' : '40.9vw')};
    margin: ${props => (props.halfsized ? '0' : '0 auto')};
    margin-bottom: 80px;
  }
`;

const Label = styled.p`
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  background: ${({ theme }) => theme.colorBlack};
  width: 243px;
  left: 50%;
  margin-left: -121.5px;
  @media (min-width: ${media.mdup}) {
    transform: translateY(-50%);
  }
  span {
    padding: 5px 50px;
    margin: 0 auto;
    display: block;
    color: white;
    font-size: 12px;
    letter-spacing: 0.9px;
    text-align: center;
  }
`;

const Point = styled.div`
  display: none;
  position: absolute;
  top: ${props => `${props.top}%`};
  left: ${props => `${props.left}%`};
  width: 10px;
  height: 10px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colorCopper};
  /* box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.9); */

  border-radius: 50px;
  justify-content: center;
  align-items: center;

  box-shadow: ${props =>
    props.isActive && '0 0 6px 2px rgba(255, 255, 255, 0.5)'};
  @media (min-width: ${media.mdup}) {
    /* display: block; */
    display: flex;
    &:hover {
      span {
        display: flex;
      }
    }
  }
`;

const POILabel = styled.span`
  display: none;

  background: #ffffff;
  position: absolute;
  height: 58px;
  top: -70px;
  white-space: wrap;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colorCopper};

  font-size: 9px;
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colorBlack};
  padding: 13px 40px;
  text-transform: uppercase;
  width: 200px;
  text-align: center;
  padding: 13px 40px;
  @media (min-width: ${media.mdup}) {
    display: ${props => (props.active ? 'flex' : 'none')};
    z-index: 99;
    ::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -10px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      transform: translateY(100%);
      border-top: 10px solid ${({ theme }) => theme.colorCopper};
    }
  }
`;
