import React, { Component } from 'react';

import LayoutWithHero from 'components/LayoutWithHero';

import Description from 'components/Description';
import Glance from './elements/Glance';
import PageCard from './elements/PageCard';

import infoChips from 'src/data/home/infoChips';
import pageCards from 'src/data/home/pageCards';

class Home extends Component {
  state = {
    activeIndex: 0,
  };

  handleGlanceToggle = index => {
    if (this.state.activeIndex === index) {
      this.setState({ activeIndex: null });
    } else this.setState({ activeIndex: index });
  };

  render() {
    return (
      <LayoutWithHero
        hero={{
          image: require('src/assets/images/home/home-hero.jpg'),
          title: (
            <span>
              Urban Energy
              <br />
              Suburban Serenity
            </span>
          ),
        }}
        nextPage={{
          text: 'Discover Next: The Neighbourhood',
          link: '/neighbourhood',
        }}
      >
        <Description
          image={{
            image: require('src/assets/images/home/home-building.jpg'),
            alt: 'Centra Surrey Building at Sunrise',
            backgroundSize: '160%',
            backgroundPosition: '90%',
          }}
          text={{
            alignment: 'flex-start',
            title: (
              <h1>
                Welcome to Centra
                <br />
                Surrey City Centre
              </h1>
            ),
            body: (
              <p>
                The <span>vibrant energy</span> of the city, the{' '}
                <span>peace and quiet</span> of the suburbs - Centra gives you a
                connected location, views in every direction, spacious interiors
                and practical amenities that come together to give you homes
                with real value -{' '}
                <span> homes that work for you in every way.</span>
              </p>
            ),
          }}
        />
        <Glance
          image={require('src/assets/images/home/home-balcony.jpg')}
          infoChips={infoChips}
          handleClick={this.handleGlanceToggle}
          activeIndex={this.state.activeIndex}
        />
        {pageCards.map((card, index) => (
          <PageCard card={card} key={index} />
        ))}
      </LayoutWithHero>
    );
  }
}

export default Home;
