import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import Map from './elements/Map';

const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    return window.open(
      'https://www.google.ca/maps/dir//15225+104+Ave,+Surrey,+BC+V3R+6Y8/@49.2304386,-123.0213144,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!2m2!1d-122.8003078!2d49.1918211!3e0',
      '_blank'
    );
  }
};

const success = position => {
  window.open(
    // `https://www.google.ca/maps/dir/${position.coords.latitude},${
    //   position.coords.longitude
    // }/15225+104+Ave,+Surrey,+BC+V3R+6Y8/@49.1918211,-122.8024965,17z/data=!4m17!1m7!3m6!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!2s15225+104+Ave,+Surrey,+BC+V3R+6Y8!3b1!8m2!3d49.1918211!4d-122.8003077!4m8!1m0!1m5!1m1!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!2m2!1d-122.8003078!2d49.1918211!3e2`
    `https://www.google.ca/maps/dir/${position.coords.latitude},${
      position.coords.longitude
    }/15225+104+Ave,+Surrey,+BC+V3R+6Y8/@49.2304386,-123.0213144,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!2m2!1d-122.8003078!2d49.1918211!3e0`
  );
};

const error = () => {
  window.open(
    // 'https://www.google.ca/maps/dir//15225+104+Ave,+Surrey,+BC+V3R+6Y8/@49.1918211,-122.8024965,17z/data=!4m17!1m7!3m6!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!2s15225+104+Ave,+Surrey,+BC+V3R+6Y8!3b1!8m2!3d49.1918211!4d-122.8003077!4m8!1m0!1m5!1m1!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!2m2!1d-122.8003078!2d49.1918211!3e2',
    'https://www.google.ca/maps/dir//15225+104+Ave,+Surrey,+BC+V3R+6Y8/@49.2304386,-123.0213144,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!2m2!1d-122.8003078!2d49.1918211!3e0',
    '_blank'
  );
};

const Contact = () => {
  return (
    <Root>
      <ColumnLeft>
        <h1>Visit Us</h1>

        <h2>Presentation Centre Map</h2>

        <MapContainer>
          <Map />
        </MapContainer>

        <BottomContainer>
          <Address>
            <h2>Address</h2>
            <p>
              #103-15225 - 104 Ave
              <br />
              Surrey, BC
              <br />
              By appointment only
            </p>

            {/* <a
              href="https://www.google.ca/maps/dir//15225+104+Ave,+Surrey,+BC+V3R+6Y8/@49.1918211,-122.8024965,17z/data=!4m17!1m7!3m6!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!2s15225+104+Ave,+Surrey,+BC+V3R+6Y8!3b1!8m2!3d49.1918211!4d-122.8003077!4m8!1m0!1m5!1m1!1s0x5485d71266f58a05:0xe7ffcaf38ef0d11a!2m2!1d-122.8003078!2d49.1918211!3e2"
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <GetDirections onClick={getLocation}>
              <p>Get Directions</p>
            </GetDirections>
            {/* </a> */}
          </Address>

          {/* <Hours>
            <h2>Opening Hours</h2>
            <p>
              Monday to Sunday, 9 AM to 5 PM
              <br />
              or by appointment.
            </p>
          </Hours> */}
        </BottomContainer>
      </ColumnLeft>

      <ColumnRight>
        <h1>Contact Us</h1>

        <h2>Email</h2>
        <a
          href="mailto:info@centrasurrey.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          info@centrasurrey.com
        </a>

        <h2>Phone</h2>
        <a href="tel:1-604.498-3887" target="_blank" rel="noopener noreferrer">
          604.498.3887
        </a>
      </ColumnRight>
    </Root>
  );
};

export default Contact;

const Root = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.colorOffWhite};
  display: flex;
  flex-direction: column-reverse;
  padding: 0 23px;
  padding-top: 80px;
  padding-bottom: 80px;

  /* @media (min-width: ${media.mdup}) {
    padding-top: 80px;
  } */

  h1 {
    margin-top: 20px;
    margin-bottom: 50px;
    @media (min-width: ${media.mdup}) {
      margin-top: 80px;
    }
  }

  h2 {
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: normal;
    color: ${({ theme }) => theme.colorBlack};
    text-transform: uppercase;
  }

  @media (min-width: ${media.mdup}) {
    flex-direction: row;
    padding-left: 12.6vw;
    padding-right: 12.6vw;
  }
`;

const ColumnLeft = styled.div`
  h2 {
    margin-bottom: 20px;
  }
  margin-right: 7.4vw;
`;

const ColumnRight = styled.div`
  text-transform: uppercase;
  h2 {
    margin-bottom: 7px;
  }
  a {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3.8px;
    color: ${({ theme }) => theme.colorCopper};
    margin-bottom: 20px;
    display: block;
  }
`;

const MapImage = styled.img`
  border: 1px solid black;
  width: 100%;
  height: 25vw;
  margin-bottom: 20px;
`;

const BottomContainer = styled.div`
  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    letter-spacing: 1px;
  }
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
`;

const Address = styled.div`
  margin-right: 6.9vw;
  a {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3.8px;
    color: ${({ theme }) => theme.colorCopper};
    text-transform: uppercase;
    display: block;
    margin-top: 20px;
  }
`;

const Hours = styled.div`
  margin-top: 30px;
  @media (min-width: ${media.mdup}) {
    margin-top: 0px;
  }
`;

const MapContainer = styled.div`
  height: 350px;
  @media (min-width: ${media.mdup}) {
    height: 25vw;
    width: 40vw;
  }
`;

const GetDirections = styled.div`
  margin-top: 44px;
  cursor: pointer;
  p {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3.8px;
    color: ${({ theme }) => theme.colorCopper};
    text-transform: uppercase;
  }
`;
