import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import media from 'src/styles/media';
import Recaptcha from './Recaptcha';

class Form extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      width: '125px',
      position: 'inherit',
    };
    this.state = {
      captchaValid: false,
      captchaFeedback: false,
    };
    this.$phoneNumber = React.createRef();
    this.$postalCode = React.createRef();
    this.$honeypot = React.createRef();
    this.$honeypotCheckbox = React.createRef();
  }

  handleClickPhone = e => {
    let key = e.keyCode || e.charCode;
    let str = this.$phoneNumber.current.value;

    if (key === 8) {
      if (str.length === 4) {
        str = str.slice(0, 3);
      } else if (str.length === 8) {
        str = str.slice(0, 7);
      }
    } else {
      if (str.length === 3) {
        str = str + '-';
      } else if (str.length === 7) {
        str = str + '-';
      }
    }

    this.$phoneNumber.current.value = str;
  };

  handleClickPostalCode = e => {
    let key = e.keyCode || e.charCode;
    let str = this.$postalCode.current.value;
    if (key === 8) {
      if (str.length === 4) {
        str = str.slice(0, 3);
      }
    } else {
      if (str.length === 3) {
        str = str + ' ';
      }
    }

    this.$postalCode.current.value = str;
  };

  handleFormSubmit = event => {
    if (
      this.$honeypot.current.value !== '' ||
      this.$honeypotCheckbox.current.checked
    ) {
      event.preventDefault();
    }

    if (this.state.captchaValid === false) {
      event.preventDefault();
    }

    if (this.state.captchaFeedback === false) {
      let $recaptchaWarning = document.getElementById('recaptcha-warning');
      $recaptchaWarning.style.opacity = '1';
    }
  };

  captchaTimestamp = () => {
    var response = document.getElementById('g-recaptcha-response');
    let $recaptchaWarning = document.getElementById('recaptcha-warning');
    if (response === null || response.value.trim() === '') {
      this.setState({ captchaValid: false });
      this.setState({ captchaFeedback: false });
      var elems = JSON.parse(
        document.getElementsByName('captcha_settings')[0].value
      );
      elems['ts'] = JSON.stringify(new Date().getTime());
      document.getElementsByName('captcha_settings')[0].value = JSON.stringify(
        elems
      );
    } else {
      this.setState({ captchaValid: true });
      this.setState({ captchaFeedback: true });
      $recaptchaWarning.style.opacity = '0';
    }
  };

  render() {
    return (
      <Container id="form">
        <Recaptcha captchaTimestamp={this.captchaTimestamp} />
        <FormWrapper
          action="https://gateway.rennie.com/leads"
          method="POST"
          onSubmit={event => this.handleFormSubmit(event)}
        >
          <input
            type="hidden"
            name="retURL"
            value="http://centrasurrey.com/thank-you/"
          />

          <input type="hidden" name="lead_source" value="Project Site" />
          <input
            type="hidden"
            name="00N3Z00000CeVw2"
            value="7013Z0000033kxsQAA"
          />
          <input
            type="hidden"
            name="00N3Z00000CeVw3"
            value="a04f100000VqnRKAAZ"
          />
          <input type="hidden" name="00N3Z00000CeYiQ" value="1" />
          <input name="00N3Z00000CeVvt" type="hidden" value="Project Site" />
          <input name="00N3Z00000CeVw9" type="hidden" value="Web Source" />
          <input
            name="00N3Z00000CeVw1"
            type="hidden"
            value="Web Registration"
          />
          <input
            ref={this.$honeypot}
            id="website"
            name="website"
            type="text"
            className="hide"
          />

          <input
            type="hidden"
            name="captcha_settings"
            value='{"keyname":"Centra","fallback":"true","orgId":"00Dj0000000HQIU","ts":""}'
          />

          <input
            ref={this.$honeypotCheckbox}
            type="checkbox"
            name="contact_me_by_fax_only"
            value="1"
            className="hide"
            tabIndex="-1"
            autoComplete="off"
          />

          <div
            style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}
          >
            <label for="pardot_extra_field">Comments</label>
            <input
              type="text"
              id="pardot_extra_field"
              name="pardot_extra_field"
            />
          </div>

          <div>
            <div>
              <h4>First Name*</h4>
              <input
                id="first_name"
                maxLength="40"
                name="first_name"
                size="20"
                type="text"
                pattern="[A-Za-z]{1,20}"
                required
              />

              <h4>Last Name*</h4>
              <input
                id="last_name"
                maxLength="80"
                name="last_name"
                size="20"
                type="text"
                pattern="[A-Za-z]{1,20}"
                required
              />

              <h4>Email*</h4>
              <input
                id="email"
                maxLength="80"
                name="email"
                size="20"
                type="text"
                pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]+\.[A-Za-z]{1,63}$"
                required
              />

              <h4>Phone Number*</h4>
              <input
                id="phone"
                maxLength="12"
                name="phone"
                size="20"
                type="text"
                required
              />

              <h4>Postal Code*</h4>
              <input
                id="zip"
                maxLength="10"
                name="zip"
                size="20"
                type="text"
                required
              />
            </div>

            <div>
              {/* <h4>What is your price range?</h4>
              <SelectWrapper>
                <select
                  id="00Nf100000CBrGL"
                  name="00Nf100000CBrGL"
                  title="Price Range Interested In"
                  defaultValue=""
                >
                  <option value="" disabled />
                  <option value="$350,000 to $450,000">
                    $350,000 to $450,000
                  </option>
                  <option value="$450,000 to $550,000">
                    $450,000 to $550,000
                  </option>
                  <option value="$550,000 to $650,000">
                    $550,000 to $650,000
                  </option>
                  <option value="$650,000 to $750,000">
                    $650,000 to $750,000
                  </option>
                  <option value="$750,000 to $850,000">
                    $750,000 to $850,000
                  </option>
                </select>
              </SelectWrapper>

              <h4>How many bedrooms do you prefer?</h4>
              <SelectWrapper>
                <select
                  id="00Nf100000CNWPU"
                  name="00Nf100000CNWPU"
                  title="Bedroom Type Interested"
                  defaultValue=""
                >
                  <option value="" disabled />
                  <option value="Studio">Studio</option>
                  <option value="1 Bed">1 Bed</option>
                  <option value="2 Bed">2 Bed</option>
                  <option value="3 Bed">3 Bed</option>
                </select>
              </SelectWrapper> */}

              <h4>Are you a realtor?*</h4>
              <SelectWrapper>
                <select
                  id="00Nj0000000gK0N"
                  name="00Nj0000000gK0N"
                  title="Are you a realtor"
                  defaultValue=""
                  required
                >
                  <option value="" disabled />
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </SelectWrapper>

              <h4>How did you hear about us?*</h4>
              <SelectWrapper>
                <select
                  id="00Nj000000AITER"
                  name="00Nj000000AITER"
                  title="How did you hear about us?"
                  defaultValue=""
                  required
                >
                  <option value="" disabled />
                  <option value="Friends and Family">Friends and Family</option>
                  <option value="Realtor">Realtor</option>
                  <option value="Signage/Walk by/Drive by">
                    Signage/Walk by/Drive by
                  </option>
                  <option value="Newspaper advertisement">
                    Newspaper advertisement
                  </option>
                  <option value="Social Media">Social Media</option>
                  <option value="Radio">Radio</option>
                  <option value="Online Advertisement">
                    Online Advertisement
                  </option>
                  <option value="Transit Ad">Transit Ad</option>
                  <option value="Other">Other</option>
                </select>
              </SelectWrapper>

              <CheckBoxContainer>
                <div>
                  <input
                    type="checkbox"
                    id="00Nj0000000kXHD"
                    name="00Nj0000000kXHD"
                    value="1"
                    required
                  />
                  <label>
                    * Click to confirm your consent to receive e-communications
                    from Everest Group and Rennie Marketing Systems including
                    information about upcoming developments, VIP early access or
                    special offers.
                  </label>
                  <CheckMark />
                </div>
              </CheckBoxContainer>
            </div>
          </div>
          <CaptchaWrapper>
            <div
              className="g-recaptcha"
              data-sitekey="6Lef8ZkUAAAAAHMYGvQReKLzDJBSG6znDAUzgm8Y"
            />
          </CaptchaWrapper>

          <p id="recaptcha-warning">
            *Please complete the reCaptcha to proceed.
          </p>

          <RegisterContainer>
            <input type="submit" value="Register" />
          </RegisterContainer>
        </FormWrapper>
      </Container>
    );
  }
}

export default withRouter(Form);

const Container = styled.div`
  padding: 90px 5%;
  text-align: center;

  h4 {
    font-weight: 300;
    margin-top: 20px;
    font-size: 14px;
  }

  label {
    max-width: 270px;
    display: block;
  }
`;

const FormWrapper = styled.form`
  > div {
    margin: 0 auto;
    /* width: 45%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    > div:nth-child(2) {
      margin-left: 150px;
      @media (max-width: ${media.sm}) {
        margin-left: 0px;
        h3 {
          margin-top: 45px;
        }
      }
    }
    input[type='text'] {
      width: 270px;
      height: 35px;
      margin-top: 13px;
      padding-left: 5px;
      font-size: 18px;
      border: 1px solid rgba(36, 36, 36, 0.74);
      @media (max-width: ${media.sm}) {
        width: 100%;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
    .postal {
      width: 115px !important;
      @media (max-width: ${media.sm}) {
        width: 50% !important;
      }
    }
    h3 {
      opacity: 0.9;
      color: #242424;
    }

    button {
      text-transform: uppercase;
      font-weight: lighter;
      font-size: 18px;
      color: #242424;
      letter-spacing: 1.29px;
      text-align: center;
      padding-bottom: 2px;
      border-bottom: 2px solid #b06e44;
    }
    @media (max-width: ${media.sm}) {
      flex-direction: column;
      width: 85%;
    }
  }
  .hide {
    display: none;
  }

  #recaptcha-warning {
    margin: 15px 0 20px;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.8s ease;
  }
`;

const CheckMark = styled.span`
  @media (min-width: 476px) {
    top: 0px;
  }
  @media (min-width: ${media.sm}) {
    top: 10px;
  }
  position: absolute;
  top: 10px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid black;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 3px;
    width: 5px;
    height: 15px;
    border: solid #54729e;
    border-width: 0 2px 2px 0;
    transform: rotate(30deg);
  }
`;

const CheckBoxContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  div {
    margin-top: 6px;
    display: block;
    font-size: 14px;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    user-select: none;
    cursor: pointer;
    input {
      @media (min-width: 476px) {
        margin-top: 0px;
      }
      @media (min-width: ${media.sm}) {
        margin-top: 10px;
      }
      margin-top: 10px;
      height: 25px;
      width: 25px;
      left: 0;
      z-index: 1;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ ${CheckMark} {
        background-color: white;
      }
      &:checked ~ ${CheckMark}:after {
        display: block;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }
`;

const RegisterContainer = styled.div`
  width: 165px;
  height: 34px;
  border: 1px solid ${({ theme }) => theme.colorCopper};
  margin: 0 auto;
  /* margin-top: 80px !important; */
  background: #ffffff;
  input {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1.6px;
    color: ${({ theme }) => theme.colorCopper};
    border: none;
    &:disabled {
      color: gray;
      border: none;
      background-color: transparent;
      border-radius: 0;
      -webkit-appearance: none;
    }
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  select {
    appearance: none;
    background: #ffffff;
    border: 1px solid rgba(36, 36, 36, 0.74);
    border-radius: 0;
    width: 100%;
    height: 35px;
    margin-top: 13px;
    padding-left: 5px;
    font-size: 18px;
    @media (min-width: ${media.mdup}) {
      width: 270px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 60%;
    right: 20px;
    width: 9px;
    height: 5px;
    background-image: url(${require('src/assets/images/icons/triangle-brown.svg')});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    @media (min-width: ${media.mdup}) {
      right: 50px;
    }
  }
`;

const CaptchaWrapper = styled.div`
  margin: 0 auto;
  margin-top: 50px !important;
  /* margin-bottom: 30px !important; */
  display: flex;
`;
