import Script from 'react-load-script';
import React, { Component } from 'react';

class Recaptcha extends Component {
  constructor(props) {
    super(props);
    this.$captchaInterval = null;
    this.state = {
      verified: false,
    }
  }

  handleLoadCaptcha = () => {
    this.$captchaInterval = setInterval(this.props.captchaTimestamp, 500);
  };

  componentWillUnmount() {
    clearInterval(this.$captchaInterval);
  }

  render() {
    return (
      <Script
        url="https://www.google.com/recaptcha/api.js"
        onLoad={this.handleLoadCaptcha}
      />
    );
  }
}

export default Recaptcha;
