import React, { Component } from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import imgLogo from 'src/assets/images/logo/logo-flower.png';

class ThankYou extends Component {
  render() {
    return (
      <Root>
        <h1>
          Thank You <span> for Registering</span>
        </h1>

        <Logo src={imgLogo} />
        <p>
          Form submitted successfully. We will be in touch shortly with more
          information.
        </p>

        <Button
          border="#aa496c"
          label="Home"
          handleClick={() => (window.location.href = '/')}
        />
      </Root>
    );
  }
}

export default ThankYou;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 100;
  min-height: 100vh;
  width: 100%;
  padding-top: 60px;
  padding-left: 50px;
  padding-right: 50px;
  overflow-y: scroll;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    margin-bottom: 60px;
  }
  h2 {
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 30px;
    font-size: 14px;
    max-width: 400px;
  }

  li {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.71;
    font-weight: 300;
  }
  ul {
    margin-bottom: 30px;
  }

  a {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3.8px;
    color: ${({ theme }) => theme.colorCopper};
    margin-bottom: 20px;
    display: block;
    text-transform: uppercase;
  }
`;

const Logo = styled.img`
  height: 60px;
  width: 60px;
  margin-bottom: 60px;
`;
