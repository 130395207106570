import React from 'react';

export default {
  units: [
    {
      id: 'th1',
      mockup: (
        <g id="th1" transform="translate(163.000000, 51.000000)">
          <polygon
            id="fill-th1"
            stroke="#2A2827"
            fill="#FFFFFF"
            points="10.800293 69.262207 0.482421875 58.9443359 22.8776855 35.9538574 16.3641357 29.4403076 45.4405518 0.363891602 62.2653809 17.1887207 65.7658691 13.6882324 86.9202881 34.8426514 88.0239258 33.7390137 95.920166 41.6352539 41.3720703 96.1833496 12.546875 67.3581543"
          />
          <text
            id="TH1"
            font-family="Brother-1816-Regular, Brother 1816"
            font-size="14"
            font-weight="normal"
            letter-spacing="1.166667"
            fill="#B77356"
          >
            <tspan x="34.2519995" y="55">
              TH1
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'th2',
      mockup: (
        <g id="th2" transform="translate(204.000000, 96.000000)">
          <polygon
            id="fill-th2"
            stroke="#2A2827"
            fill="#FFFFFF"
            points="0.668141593 51.2097168 51.4683481 0.40951025 65.4858398 14.427002 68.5827637 11.3300781 89.2259521 31.9732666 90.2192383 30.9799805 98.3054199 39.0661621 43.4818115 93.8897705"
          />
          <text
            id="TH2"
            font-family="Brother-1816-Regular, Brother 1816"
            font-size="14"
            font-weight="normal"
            letter-spacing="1.166667"
            fill="#B77356"
          >
            <tspan x="32.0479995" y="52">
              TH2
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'th3',
      mockup: (
        <g id="th3" transform="translate(247.000000, 138.000000)">
          <polygon
            id="fill-th3"
            stroke="#2A2827"
            fill="#FFFFFF"
            points="0.434326172 51.8334961 51.9296875 0.338134766 66.6744385 15.0828857 69.6713867 12.0859375 87.5500488 29.9645996 87.5500488 42.6152344 86.0727539 42.6152344 86.0727539 61.5 87.5500488 61.5 87.5500488 69.7075195 83.2495117 69.7075195 83.2495117 101.811279 32.5727539 101.811279 32.5727539 83.6647949"
          />
          <text
            id="TH3"
            font-family="Brother-1816-Regular, Brother 1816"
            font-size="14"
            font-weight="normal"
            letter-spacing="1.166667"
            fill="#B77356"
          >
            <tspan x="36.2789995" y="56">
              TH3
            </tspan>
          </text>
        </g>
      ),
    },
  ],
};
