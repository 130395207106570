import React from 'react'

const clubroomChip = {
  header: {
    title: 'Club Room',
    subtitle: 'Level 1 (TH Building)',
  },
  features: [
    {
      title: 'Long table',
      location: [59, 31],
    },
    {
      title: 'Relaxed seating area',
      locations: [[35, 30], [78, 25]]
    },
    {
      title: 'Theatre',
      location: [72, 60],
    },
    {
      title: 'Fully equipped kitchen',
      location: [57, 52],
    },
    {
      title: 'Billiards Table',
      location: [78, 39],
    },
    {
      title: <>Outdoor Patio<br /> Area</>,
      location: [80, 13]
    }
  ],
  transform: 'translateY(100px)',
};

export default clubroomChip;
