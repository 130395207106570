import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'src/styles/media';

const Button = ({ label, link, border, header }) => {
  return (
    <ButtonWrapper border={border} header={header}>
      {link ? (
        <RootLink to={link}>
          <p>{label}</p>
        </RootLink>
      ) : (
        <RootButton>
          <span>{label}</span>
        </RootButton>
      )}
    </ButtonWrapper>
  );
};

export default Button;

const buttonStyles = css`
  text-transform: uppercase;
`;

const RootLink = styled(Link)`
  ${buttonStyles}
  @media(min-width:${media.smup}){
    br{
      display:none;
    }
  }
`;

const RootButton = styled.div`
  ${buttonStyles}
`;

const ButtonWrapper = styled.div`
  flex: ${props => props.header && '1'};
  width: 165px;
  margin: 12px auto;
  background: #ffffff;
  span {
    padding: 9px 39px;

    display: block;
    text-align: center;
    /* width: 72px; */
    margin-left: ${props => props.header && 'auto'};
    font-size: 12px;
    letter-spacing: 1.6px;
    color: ${({ theme }) => theme.colorCopper};
    border: ${props => `1px solid ${props.border}`};

    @media (min-width: ${media.smup}) {
      width: auto;
      /* padding: 9px 27px; */
      /* text-align: right; */
    }
    @media (min-width: ${media.mdup}) {
      text-align: center;
      max-width: 165px;
    }
  }
  a {
    text-align: right;
    display: block;
  }
`;
