import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const FeaturedImage = ({ image, label, children, noGradientOverlay }) => {
  return (
    <Root background={image} noGradientOverlay>
      {children}
      <Label>
        <span>{label}</span>
      </Label>
    </Root>
  );
};

export default FeaturedImage;

const Root = styled.div`
  background: ${props =>
    props.noGradientOverlay
      ? `url(${props.background})`
      : `linear-gradient(to bottom, #d8d8d8, #d8d8d8), url(${
          props.background
        })`};
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  padding: 0 23px;
  height: 69.3vw;
  position: relative;
  @media (min-width: ${media.mdup}) {
    width: 74.5vw;
    height: 41vw;
    margin: 0 auto;
  }
`;

const Label = styled.p`
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  background: ${({ theme }) => theme.colorBlack};
  width: 243px;
  left: 50%;
  margin-left: -121.5px;
  @media (min-width: ${media.mdup}) {
    transform: translateY(-50%);
  }
  span {
    padding: 5px 50px;
    margin: 0 auto;
    display: block;
    color: white;
    font-size: 12px;
    letter-spacing: 0.9px;
    text-align: center;
  }
`;
