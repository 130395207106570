import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'src/styles/media';

import Button from 'components/Button';

import imgMenu from 'src/assets/images/icons/hamburger.svg';
import imgWordmark from 'src/assets/images/logo/wordmark-2.svg';
import imgWordmarkMobile from 'src/assets/images/logo/header-wordmark.svg';
import imgLogo from 'src/assets/images/logo/logo-header.svg';

export default class Header extends Component {
  state = {
    smallHeader: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.shrinkHeader);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.shrinkHeader);
  }

  shrinkHeader = () => {
    if (window.pageYOffset > 0) {
      this.setState({ smallHeader: true });
    } else this.setState({ smallHeader: false });
  };

  render() {
    return (
      <Root smallHeader={this.state.smallHeader}>
        <MenuWrapper onClick={this.props.handleOpenMenu}>
          <img src={imgMenu} alt="Menu" />
          <span>Menu</span>
        </MenuWrapper>

        <Link to="/">
          <MobileWordmark
            src={imgWordmarkMobile}
            alt="Centra - Surrey City Centre"
          />
          <DesktopWordmark
            src={this.state.smallHeader ? imgWordmarkMobile : imgWordmark}
            alt="Centra - Surrey City Centre"
          />

          <FlowerLogo
            background={imgLogo}
            smallHeader={this.state.smallHeader}
          />
        </Link>

        <ButtonContainer className="show-on-desktop">
          <Button type="register" mobileBorderless />
        </ButtonContainer>
      </Root>
    );
  }
}

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  height: 60px;
  background: white;
  position: fixed;
  z-index: 100;
  width: 100%;
  transition: all 0.2s;
  @media (min-width: ${media.smup}) {
    padding: 0 30px; /* 0 77px */
    height: ${props => (props.smallHeader ? '74px' : '120px')};
    background: ${({ theme }) => theme.colorOffWhite};
  }

  a {
    flex: 1;
    margin: 0 auto;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  cursor: pointer;

  img {
    margin-right: 25px;
  }
  span {
    display: none;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colorCopper};
    font-size: 12px;
    letter-spacing: 1.6px;
    @media (min-width: ${media.smup}) {
      display: block;
    }
  }
`;

const MobileWordmark = styled.img`
  display: block;
  margin: 0 auto;
  @media (min-width: ${media.smup}) {
    display: none;
  }
`;

const DesktopWordmark = styled.img`
  display: none;
  margin: 0 auto;
  @media (min-width: ${media.smup}) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  flex: 1;
  text-align: center;

  .show-on-desktop {
    display: none !important;

    @media (min-width: ${media.smup}) {
      display: block !important;
    }
  }

  .show-on-mobile {
    display: block !important;
    text-align: right;

    @media (min-width: ${media.smup}) {
      display: none !important;
    }
  }
`;

const FlowerLogo = styled.div`
  display: none;

  @media (min-width: ${media.mdup}) {
    height: 60px;
    width: 60px;
    background-image: ${props => `url(${props.background})`};
    display: ${props => (props.smallHeader ? 'block' : 'none')};
    position: absolute;
    top: 0;
    margin-top: 7px;
    left: 50%;
    margin-left: -30px;
  }
`;
