import React from 'react';
import styled from 'styled-components';

import LayoutWithHero from 'components/LayoutWithHero';
import TextBlock from 'components/TextBlock';
import Form from './elements/Form';

const Register = () => {
  return (
    <LayoutWithHero
      hero={{
        image: require('src/assets/images/register/register.jpg'),
        title: <span>REGISTER FOR INFORMATION - ABOUT OUR AVAILABLE HOMES</span>,
      }}
    >
      <TextblockWrapper>
        <TextBlock
          pWidth={618}
          text={{
            alignment: 'center',
            title: <h1>REGISTER NOW</h1>,
            body:
              'Register your interest and a member of our sales team will be in touch',
          }}
        />
      </TextblockWrapper>

      <Form />
    </LayoutWithHero>
  );
};

export default Register;

const TextblockWrapper = styled.div`
  text-align: center;
`;
