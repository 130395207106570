import React, { Component } from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

class TermsOfUse extends Component {
  render() {
    return (
      <Root>
        <h1>Terms of Use</h1>
        <p>
          The material, images, renderings and information on this website is
          provided on an ‘as is’ basis solely for the reader’s general
          knowledge. The information is not intended to be a comprehensive
          review of all matters and developments concerning Everest Properties,
          and therefore assumes no responsibility and provides no warranty,
          either express or implied, as to its accuracy and or completeness. In
          no event will Everest Properties be liable for any special,
          incidental, indirect or consequential damages of any kind, or any
          damages whatsoever arising out of or in connection with the use or
          performance of this information, including without limitation, those
          resulting from loss of use, data or profits, whether or not advised of
          the possibility of damage. This site may contain links to external
          organizations. We do not control the relevance, timeliness, or
          accuracy of materials produced by other organizations, nor do we
          endorse their views, products or services. Your access and use of this
          website constitutes your agreement to be governed by the contents of
          the disclaimer set forth above.
        </p>

        <Button
          label="Back"
          handleClick={() => this.props.history.goBack()}
          border="#aa694c"
        />
      </Root>
    );
  }
}

export default TermsOfUse;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 100;
  min-height: 100vh;
  width: 100%;
  padding-top: 60px;
  padding-left: 50px;
  padding-right: 50px;
  overflow-y: scroll;
  padding-bottom: 60px;
  h1 {
    margin-bottom: 60px;
  }
  h2 {
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 30px;
    font-size: 14px;
  }

  a {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3.8px;
    color: ${({ theme }) => theme.colorCopper};
    margin-bottom: 20px;
    display: block;
    text-transform: uppercase;
  }
`;
