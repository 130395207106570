import React from 'react';
import styled, { keyframes } from 'styled-components';
import media from 'src/styles/media';

import imgScrollIcon from 'src/assets/images/icons/scroll-icon.svg';

const Hero = ({ title, image }) => {
  return (
    //TODO: Flower SVG
    <Root background={image}>
      {title}
      <FlowerContainer>
        <ScrollIcon background={imgScrollIcon} />
        <Flower src={require('src/assets/images/logo/logo-flower@2x.png')} />
      </FlowerContainer>
    </Root>
  );
};

export default Hero;

const fadeDown = keyframes`
0%{
  transform:translateY(-20px);
  opacity: 0;
}
100%{
  transform:translateY(0);
opacity:1;
}
`;

const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-image: ${props =>
    `linear-gradient(to bottom, #d8d8d8, #d8d8d8), url(${props.background})`};
  background-blend-mode: multiply;
  background-size: cover, cover;
  background-position: center, center;
  /* background-position: center, calc(50% - 38px) calc(50% + 44px); */
  span {
    margin-top: auto;
    margin-left: 50px;
    margin-right: 50px;
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    letter-spacing: 3.6px;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.82);
    font-weight: 500;
    transform: translateY(50%);
    @media (min-width: ${media.smup}) {
      font-size: 25px;
      letter-spacing: 4.46px;
    }
  }
`;

const Flower = styled.img`
  position: absolute;
  left: 50%;
  top: -100%;
  margin-left: -52px;
  height: 104px;
  width: 104px;
`;

const FlowerContainer = styled.div`
  background: ${({ theme }) => theme.colorOffWhite};
  width: 100%;
  height: 52px;
  margin-top: auto;
  position: relative;
`;

const ScrollIcon = styled.div`
  background: ${props => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 50%;
  top: -80px;
  animation: ${fadeDown} 2s ease-in-out infinite forwards;
  margin-left: -10px;
  height: 10px;
  width: 20px;
`;
