import React, { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'src/styles/media';

import Button from 'components/Button';

import imgClose from 'src/assets/images/icons/close-icon.svg';

const routes = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Neighbourhood',
    link: '/neighbourhood',
  },
  {
    title: 'Amenities',
    link: '/amenities',
  },
  {
    title: 'Interiors',
    link: '/interiors',
  },
  {
    title: 'Floorplans',
    link: '/floorplans',
  },
  {
    title: 'Team',
    link: '/team',
  },
  // {
  //   title: 'Contact',
  //   link: '/contact',
  // },
];

class Menu extends Component {
  render() {
    const { isOpen, handleOpenMenu } = this.props;
    return (
      <Root isOpen={isOpen}>
        <ActionBar>
          <Close src={imgClose} alt="Close Menu" onClick={handleOpenMenu} />
          <Button type="register" border handleClick={handleOpenMenu} />
        </ActionBar>

        <RoutesWrapper isOpen={isOpen}>
          {routes.map((route, index) => (
            <MenuLink
              to={route.link}
              onClick={handleOpenMenu}
              key={index}
              delay={index * 0.2}
              isOpen={isOpen}
            >
              <h1>{route.title}</h1>
            </MenuLink>
          ))}
        </RoutesWrapper>

        <Contact>
          <a
            href="mailto:info@centrasurrey.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@centrasurrey.com
          </a>
          <a
            href="tel:1-604-498-3887"
            target="_blank"
            rel="noopener noreferrer"
          >
            604.498.3887
          </a>
        </Contact>
      </Root>
    );
  }
}

export default Menu;

const fadeIn = keyframes`
0%{
  /* display:none; */
  opacity: 0;
}
1%{
  /* display: block; */
  opacity: 0;
}
100%{
  opacity: 1;
}
`;

const animation = css`
  ${fadeIn} 10s ease-in-out forwards
`;

const Root = styled.div`
  height: 100vh;
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: ${props => (props.isOpen ? '0' : '-100%')};
  z-index: 999;
  transition: all 1.2s;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 30px;
  @media (min-width: ${media.mdup}) {
    width: 33.3vw;
    box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.35);
    padding: 60px;
  }
`;

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;

  @media (min-width: ${media.mdup}) {
    margin-bottom: 60px;
  }

  .show-on-desktop {
    display: none !important;

    @media (min-width: ${media.smup}) {
      display: block !important;
    }
  }

  .show-on-mobile {
    display: block !important;
    text-align: right;

    @media (min-width: ${media.smup}) {
      display: none !important;
    }
  }
`;

const Close = styled.img``;

const MenuLink = styled(Link)`
    display: block;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transform:${props =>
      props.isOpen ? 'translateY(0px)' : 'translateY(-50px)'};
    transition: all 0.8s;
    transition-delay:${props => (props.isOpen ? `${props.delay}s` : '0')};
    /* animation: ${props => props.isOpen && animation}; */
    /* animation-delay: ${props => `${props.animationdDelay}s`}; */
`;

const RoutesWrapper = styled.div`
  text-align: center;
  h1 {
    margin-bottom: 25px;

    @media (min-width: ${media.mdup}) {
      text-align: left;
    }
  }
`;

const Contact = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  a {
    margin-top: 7px;
    text-align: center;
    display: block;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 1px;
    font-weight: 300;
    color: ${({ theme }) => theme.colorBlack};
    @media (min-width: ${media.mdup}) {
      text-align: left;
      padding-left: 16px;
    }
  }
`;
