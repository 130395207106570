import React from 'react';

export default {
  units: [
    {
      id: 'e5',
      mockup: (
        <g id="unit-e5" transform="translate(105.000000, 170.000000)">
          <polygon
            id="fill-e5"
            stroke="#2A2827"
            fill="#FFFFFF"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="99.3419663 52.400905 83.7500426 52.400905 83.7500426 39.4556561 90.0833191 39.4556561 90.0833191 0.407692308 49.6709831 0.407692308 49.6709831 27.4199095 32.0584427 27.4199095 32.0584427 31.5126697 3.55271368e-14 31.5126697 3.55271368e-14 69.4085973 31.817175 69.4085973 31.817175 81.5352941 30.4600443 81.5352941 30.4600443 104 101.151474 104 101.151474 88.4778281 99.3419663 88.4778281"
          />
          <text
            id="E5"
            font-family="Brother-1816-Regular, Brother 1816"
            font-size="14"
            font-weight="normal"
            letter-spacing="1.166667"
            fill="#B77356"
          >
            <tspan x="47.395333" y="58">
              E5
            </tspan>
          </text>
          <text
            id="02"
            font-family="Brother-1816-Regular, Brother 1816"
            font-size="14"
            font-weight="normal"
            letter-spacing="1.166667"
            fill="#B77356"
          >
            <tspan x="47.052333" y="76">
              02
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'b2',
      mockup: (
        <g id="unit-b2" transform="translate(188.000000, 194.000000)">
          <polygon
            id="fill-b2"
            stroke="#2A2827"
            fill="#ffcc00"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="82.0270915 33.3728507 80.2175839 33.3728507 80.2175839 14.3036199 23.3387289 14.3036199 23.3387289 0.691402715 7.08331913 0.691402715 7.08331913 15.4556561 0.750042597 15.4556561 0.750042597 28.400905 16.3419663 28.400905 16.3419663 64.4778281 18.1514738 64.4778281 18.1514738 66.2058824 43.6655308 66.2058824 43.6655308 74.8158371 42.0671324 74.8158371 42.0671324 79.8180995 82.0270915 79.8180995"
          />
          <text
            id="B2"
            font-family="Brother-1816-Regular, Brother 1816"
            font-size="14"
            font-weight="normal"
            letter-spacing="1.166667"
            fill="#B77356"
          >
            <tspan x="41.052333" y="37">
              B2
            </tspan>
          </text>
          <text
            id="01"
            font-family="Brother-1816-Regular, Brother 1816"
            font-size="14"
            font-weight="normal"
            letter-spacing="1.166667"
            fill="#B77356"
          >
            <tspan x="42.151333" y="55">
              01
            </tspan>
          </text>
        </g>
      ),
    },
  ],
};
