import React, { Component } from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

class PrivacyPolicy extends Component {
  render() {
    return (
      <Root>
        <h1>Privacy Policy</h1>
        <p>
          Everest Group of Companies and each of our affiliates and partnerships
          is committed to maintaining the accuracy, confidentiality and security
          of your personal information. This Privacy Policy describes the
          personal information that Everest Group of Companies collects from or
          about you, how we use that information, and to whom we disclose that
          information.
        </p>

        <h2>Collecting Personal Information</h2>
        <p>
          Everest Group of Companies collects personal information to better
          serve your needs, whether purchasing products and services from us or
          obtaining service or warranty work. In order to provide you with our
          wide variety of services, products and promotions, we may collect the
          following information:
        </p>

        <ul>
          <li>
            Contact and mailing information such as name, address, telephone
            numbers, fax numbers and email addresses.
          </li>
          <li>
            The plans, specifications and other particulars about the home that
            is being built for you.{' '}
          </li>
          <li>Municipal and legal descriptions for you home.</li>
          <li>
            Any other relevant information necessary to provide you with our
            products and services.
          </li>
          <li>
            We may need to collect other personal information depending on the
            circumstance, but we will ask you directly and ensure that you
            agree.
          </li>
        </ul>

        <h2>PERSONAL INFORMATION SECURITY</h2>
        <p>
          Like you, Everest Group of Companies values our personal information.
          Part of valuing your personal information is making sure that it is
          protected and kept confidential. We achieve this by:
        </p>

        <ul>
          <li>Having our employees sign a confidentiality agreement.</li>
          <li>
            Having up to date computer security such as passwords, encryption
            and firewalls.
          </li>
          <li>
            Where third parties are hired to assist us with providing products
            and services to you for your home, we have them sign a privacy and
            confidentiality agreement to ensure that your personal information
            is protected by them.
          </li>
        </ul>

        <h2>PERSONAL INFORMATION UPDATING</h2>
        <p>
          Everest Group of Companies wants to ensure that your personal
          information is always correct and up to date. If your personal
          information changes or is incorrect, updates can be made by contacting
          your representative with the change and any other information you feel
          is necessary.
        </p>
        <p>
          We will keep your information as long as is necessary to continue to
          meet your customer needs and any legal requirements we may have.
          However, once your personal information no longer needed, we will
          destroy it.
        </p>

        <h2>PERSONAL DATA</h2>
        <p>
          We only collect Personal Data from you, such as first and last name,
          phone number, e-mail, when you register to receive information. We
          collect data about your activities that does not personally or
          directly identify you when you visit our website, or the websites and
          online services where we display advertisements. This information may
          include the content you view, the date and time that you view this
          content, the products you purchase, or your location information
          associated with your IP address. We use the information we collect to
          serve you more relevant advertisements (referred to as “Retargeting”).
          We collect information about where you saw the ads we serve you and
          what ads you clicked on.
        </p>

        <p>
          As you browse www.centrasurrey.com, online ad networks we work with
          may place anonymous cookies on your computer, and use similar
          technologies, in order to understand your interests based on your
          (anonymous) online activities, and thus to tailor more relevant ads to
          you. If you do not wish to receive such tailored advertising, you can
          visit this page to opt out of most companies that engage in such
          advertising. (This will not prevent you from seeing ads; the ads
          simply will not be delivered through these targeting methods.)
        </p>
        <p>
          We do not target ads to you based on sensitive personal data, such as
          information related to race or ethnic, political opinions, religious
          beliefs or other beliefs of a similar nature, trade union membership,
          physical or mental health or condition or sexual life. We may target
          ads based on data provided by Advertisers or partners alone or in
          combination with the data we collect ourselves. Any data used to serve
          targeted advertisements is de-identified and is not used to personally
          or directly identify you. In order to allow us to reach the best
          inventory online, we work with third party advertising companies (our
          “Advertising Partners”) to help us recognize you and serve relevant
          advertisements to you when you visit a website or online service in
          their network. We also work with Advertising Partners who help us
          recognize you across different devices in order to show you relevant
          advertisements. Our Advertising Partners may collect information about
          your activities on our website, and other websites or online services
          in their networks. We also work with third party companies to assist
          us with website analytics such as evaluating the use and operation of
          our website so that we can continue to enhance the website and our
          services.
        </p>

        <p>
          We and our third-party partners use session cookies and persistent
          cookies to make it easier for you to navigate and enhance the
          experience of our site, and to monitor and evaluate our website’s
          operation and use.
        </p>

        <p>
          To modify or delete your personal information, or to opt out of future
          communications, please contact us at:
        </p>

        <Address>
          9837 - 266 Street,
          <br /> Acheson, AB T7X 6H6
          <br /> Canada
        </Address>

        <a
          href="mailto:info@everestgroup.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@everestgroup.ca
        </a>

        <Button
          label="Back"
          handleClick={() => this.props.history.goBack()}
          border="#aa694c"
        />
      </Root>
    );
  }
}

export default PrivacyPolicy;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 100;
  min-height: 100vh;
  width: 100%;
  padding-top: 60px;
  padding-left: 50px;
  padding-right: 50px;
  overflow-y: scroll;
  padding-bottom: 60px;
  h1 {
    margin-bottom: 60px;
  }
  h2 {
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 30px;
    font-size: 14px;
  }

  li {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.71;
    font-weight: 300;
  }
  ul {
    margin-bottom: 30px;
  }

  a {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3.8px;
    color: ${({ theme }) => theme.colorCopper};
    margin-bottom: 20px;
    display: block;
    text-transform: uppercase;
  }
`;

const Address = styled.p``;
