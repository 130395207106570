import imgFeatureCoworking from 'src/assets/images/amenities/coworking.jpg';
import imgEntrance from 'src/assets/images/amenities/entrance.jpg';
import imgAerial from 'src/assets/images/home/home-building.jpg';

export default [
  {
    title: 'Building Amenities',
    features: [
      'Secure underground parking equipped with two electric vehicle charging outlets/stalls and 17 conveniently located visitor parking spaces',
      'Specially designed modern Parcel Delivery Room',

      // 'Outdoor urban space on level 2 podium ideal for gathering and socializing',

      'In-building Pet Wash Station - two sinks to keep small and large pets clean',

      'Dedicated Workshop Room for minor home improvement projects',
    ],
    image: imgEntrance,
  },
  {
    title: 'Centra Club Amenities',
    features: [
      'Everest Co-work Space with coffee bar, flexible co-work stations, and meeting rooms perfect for quiet study sessions or working from home',

      'Oversized Everest Club Room with fully functioning kitchen, group seating, and private theatre optimized to fit the needs of all occasions',

      'Private yoga room that can also be transformed into a space for TRX workouts',

      'Outdoor urban space on Level 2 podium with BBQ area ideal for gathering and socializing',

      'Thoughtful and fully-equipped Fitness Center perfect for all levels of fitness',
    ],
    image: imgFeatureCoworking,
  },
  {
    title: 'Conveniently Secured Living',
    features: [
      'Short 9-minute walk to King George Skytrain Station, connecting you to Downtown Vancouver within 35 minutes via Skytrain',

      'Located in Surrey City Centre with easy access to nearby shops, restaurants, schools, and services',

      'Nestled in a quiet residential area with leisure parks, scenic trails, and a fish-stocked lake nearby',

      'Bazinga Community App for 24/7 access',

      '2 Modo carsharing vehicles located onsite',

      'Monitored sprinklers and smoke detectors in every home for safe, worry-free living',

      'Secure access and elevator lock-off control access to each floor; exclusive fob and key entry to parkade',

      'Each unit comes standard with 2-5-10 National Home Warranty',
    ],
    image: imgAerial,
  },
];
