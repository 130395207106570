import React, { Fragment } from 'react';

export default {
  outline: (
    <Fragment>
      <g
        id="level-24"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(66.000000, 35.000000)">
          <g
            id="outlines"
            stroke="#2A2827"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
          >
            <path
              d="M176.288817,132.172017 L176.288817,107.756511 M136.918343,107.756511 L136.918343,132.172017 M124.249799,107.756511 L207.750201,107.756511 M207.750201,132.172017 L124.249799,132.172017 M124.249799,164 L124.249799,107.791641 M207.750201,107.791641 L207.750201,164"
              id="Shape"
            />
            <path
              d="M144.5,108 L175.841709,132 L207.5,108 M144.5,132 L175.841709,108 L207.5,132"
              id="Shape"
            />
            <path
              d="M191.5,147.247078 L141.5,147.247078 M145.003854,131.365609 L145.003854,107"
              id="Shape"
            />
            <path
              d="M141.268173,132 L141.268173,164 M191.731827,148 L191.731827,164"
              id="Shape"
            />
            <path
              d="M144.72284,84 L144.72284,91.032967 L123.5,91.032967 L123.5,84 M191.73143,132.07033 L191.73143,148"
              id="Shape"
            />
            <path d="M124.5,91 L145.5,84 M124.5,84 L145.5,91" id="Shape" />
            <path
              d="M303.5,114.494442 L317.5,114.494442 L317.5,51 L303.5,51 M303.5,196 L317.5,196 L317.5,131.979942 L303.5,131.979942"
              id="Shape"
            />
            <path d="M166.5,198 L166.5,239" id="Path" />
            <path d="M166.5,43 L166.5,0" id="Path" />
            <polyline
              id="Path"
              points="297.5 41.9516484 297.5 5.55604396 229.21322 5.55604396 229.21322 0 105.691898 0 105.691898 5.55604396 36.3848614 5.55604396 36.3848614 41.9516484 0.5 41.9516484 0.5 84.4307692 9.01385928 84.4307692 9.01385928 117.591209 0.5 117.591209 0.5 199.630769 36.3848614 199.630769 36.3848614 240 118.181237 240 118.181237 224.07033 147.944563 224.07033 147.944563 240 194.946695 240 194.946695 224.07033 224.710021 224.07033 224.710021 240 297.5 240 297.5 213.731868"
            />
          </g>
        </g>
      </g>
    </Fragment>
  ),
};
