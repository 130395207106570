import React, { Fragment } from 'react';

export default {
  outline: (
    <Fragment>
      <g
        id="townhomes"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        {/* <g id="compass" transform="translate(402.000000, 236.000000)">
          <g transform="translate(20.152543, 20.152543) rotate(-315.000000) translate(-20.152543, -20.152543) translate(6.152543, 5.652543)">
            <circle
              id="Oval"
              stroke="#2A2827"
              stroke-width="0.5"
              opacity="0.795321"
              cx="14"
              cy="14"
              r="14"
            />
            <path
              d="M14.5,0 L14.5,28"
              id="Line"
              stroke="#2A2827"
              stroke-width="0.5"
              opacity="0.795321"
              stroke-linecap="square"
            />
            <path
              d="M0,14 L28,14"
              id="Line-2"
              stroke="#2A2827"
              stroke-width="0.5"
              opacity="0.795321"
              stroke-linecap="square"
            />
            <path
              d="M14.5,1 L14.5,13.5"
              id="Line-3"
              stroke="#B77356"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </g> */}
        {/* <polygon
            id="N"
            fill="#2A2827"
            points="37.6928333 9.084 37.6858333 9.084 34.5568333 5.689 34.3958333 5.514 34.3958333 9 33.7238333 9 33.7238333 3.876 33.7308333 3.876 36.8598333 7.271 37.0208333 7.446 37.0208333 3.96 37.6928333 3.96"
          />
        </g> */}
        <g id="bg-lines" transform="translate(103.000000, 27.000000)">
          <path
            d="M156.410438,224.691476 L156.410438,251 L156.410438,224.691476 Z"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M156.410438,239.242252 L208.148076,239.242252 L156.410438,239.242252 Z"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M208.148076,212.753533 L208.148076,251 L208.148076,212.753533 Z"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M162.184124,225.637502 L208.148076,225.637502 L162.184124,225.637502 Z"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="69.6000977 92.1230469 49.8227848 112.655007 50.7249232 113.601033 79.6835663 141.576363 82.6606231 138.1977 93.1254287 148.288641 100.974033 140.089752 162.184124 199.148783 162.184124 224.691476 141.840903 224.691476 141.840903 251 221.589939 251 221.589939 224.286037 227.183197 224.286037 227.183197 212.536133"
          />
          <path
            d="M196.177215,251 L196.177215,239.582278"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M161.924051,225.486582 L161.924051,224.614684"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M154.5,64.5 L171.511708,46.5189873"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M197.999771,106.67639 L215.343146,89.2564667"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M230.253943,106.992295 L230.253943,138.898734"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="195.026621 104.149147 212.144757 86.0974133 225.208598 98.6433682 223.541832 100.313154"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="151.606201 61.2423154 168.628654 43.901486 181.692494 56.4023115 179.80049 58.2977435"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="107.949367 26.3010457 128.626273 5 139.257537 15.4700055 137.590771 17.0495322"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="122.346191 160.744873 82.4421554 202.142442 98.9215319 218.819999 98.9215319 251"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="49.8455865 112.15595 36.5353209 99.4556962 5.68434189e-14 137.059293 5.68434189e-14 251 142.202532 251"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="68.8172607 130.94519 77.1905207 122.291139 87.5 132.27832"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="82.6341553 138.214722 90.2625144 129.775764 100.683544 139.936709"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="205.502227 98.997436 213.179376 106.631704 221.39844 98.1441943 230.43038 106.721519"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="161.516682 57.1885914 169.193831 64.5983222 177.412896 56.2455348 210.469794 87.6807563"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="118.434331 15.5593767 127.195313 23.7325343 135.414377 15.3797468 167.026165 45.6473744"
          />
          <path d="" id="Path-3" stroke="#2A2827" stroke-width="0.5" />
          <polyline
            id="Path-6"
            stroke="#2A2827"
            stroke-width="0.5"
            points="23.2480469 113.526855 19.8735352 110.152344 21.0803223 108.945557 10.9438477 98.809082 37.2036133 72.5493164 32.050293 67.3959961 53.5917969 45.8544922 43.1013184 35.3640137 77.6254883 0.83984375 102.987305 26.2016602"
          />
          <polyline
            id="Path-7"
            stroke="#2A2827"
            stroke-width="0.5"
            points="33.6655273 69.2412109 9.37792969 93.5288086 12.5800781 96.730957"
          />
          <path
            d="M66.3427734,11.7265625 L92.0693359,37.453125"
            id="Path-8"
            stroke="#2A2827"
            stroke-width="0.5"
          />
          <path
            d="M53.2617188,45.7382812 L76.684082,22.315918"
            id="Path-9"
            stroke="#2A2827"
            stroke-width="0.5"
          />
          <text
            id="AMENITY"
            opacity="0.5"
            font-family="Brother-1816-Book, Brother 1816"
            font-size="8"
            font-weight="300"
            letter-spacing="0.6666667"
            fill="#B77356"
          >
            <tspan x="22.4026665" y="183">
              AMENITY
            </tspan>
          </text>
          <text
            id="THEATRE"
            opacity="0.5"
            font-family="Brother-1816-Book, Brother 1816"
            font-size="8"
            font-weight="300"
            letter-spacing="0.6666667"
            fill="#B77356"
          >
            <tspan x="103.786667" y="207">
              THEATRE
            </tspan>
          </text>
        </g>
      </g>
    </Fragment>
  ),
};
