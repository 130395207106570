import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'src/styles/media';

import TextBlock from 'components/TextBlock';
import ScrollAnimation from 'components/ScrollAnimation';
import ParallaxContainer from 'components/ParallaxContainer';

import imgDot from 'src/assets/images/shapes/dot.svg';

const PageCard = ({ card }) => {
  const { text, image, bubble, hasDot } = card;

  return (
    <Root flexColumn={text.alignment === 'center'}>
      <ScrollAnimation>
        {/* <ParallaxContainer> */}
        <NavigationImage
          to={text.link}
          background={image.image}
          height={image.height}
          width={image.width}
          mobileHeight={image.mobileHeight}
          backgroundSize={image.backgroundSize}
          backgroundPosition={image.backgroundPosition}
        >
          {hasDot && (
            <Dot
              src={imgDot}
              alt="Dot"
              top={hasDot.top}
              left={hasDot.left}
              right={hasDot.right}
            />
          )}
          <h2>{image.title}</h2>
          <p>Learn More</p>
          {bubble && (
            <ParallaxContainer
              top={bubble.top}
              right={bubble.right}
              bottom={bubble.bottom}
              left={bubble.left}
            >
              <Bubble src={bubble.image} />
            </ParallaxContainer>
          )}
        </NavigationImage>
        {/* </ParallaxContainer> */}

        <TextWrapper marginLeft={text.marginLeft}>
          <TextBlock text={text} pWidth={340} />
        </TextWrapper>
      </ScrollAnimation>
    </Root>
  );
};

export default PageCard;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  > div {
    width: 100%;
  }
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
    padding-left: 14.5vw;
    margin-left: -23px;
    margin-right: -23px;
    padding-right: 14.5vw;
    margin-bottom: 180px;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
    &:last-child {
      margin-bottom: 46px;
    }
  }
`;

const NavigationImage = styled(Link)`
  background-image: ${props =>
    `linear-gradient(to bottom, #d8d8d8, #d8d8d8), url(${props.background})`};
  background-blend-mode: multiply;
  background-position: ${props =>
    props.backgroundPosition ? props.backgroundPosition : 'center, center'};
  background-size: ${props =>
    props.backgroundSize ? props.backgroundSize : 'cover, cover'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.8s;
  flex: 1;
  height: 69.6vw;
  position: relative;
  @media (min-width: ${media.mdup}) {
    width: 36.5vw;
    height: 28.8vw;
  }

  &:hover {
    background-image: ${props =>
      `linear-gradient(to bottom, #504a47, #504a47),url(${props.background})`};
    p {
      color: ${({ theme }) => theme.colorCopper};
      font-weight: 500;
    }
  }

  h2 {
    color: white;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 3.4px;
  }

  p {
    border-bottom: 1px solid white;
    width: auto;
    margin: 0 auto;
    color: white;
    font-size: 12px;
    letter-spacing: 1.6px;
    padding-bottom: 5px;
    padding-top: 20px;
  }
`;

const TextWrapper = styled.div`
  margin-left: 27px;
  margin-top: 20px;
  @media (min-width: ${media.mdup}) {
    margin-left: ${props => props.marginLeft && '10.8vw'};
  }
`;

const Bubble = styled.img`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    /* position: absolute; */
    left: ${props => props.left};
    right: ${props => props.right};
    top: ${props => props.top};
    bottom: ${props => props.bottom};
  }
`;

const Dot = styled.img`
  height: 25px;
  width: 25px;
  position: absolute;
  right: ${props => `${props.right}`};
  top: ${props => `${props.top}`};
  left: ${props => !props.right && `${props.left}`};
  @media (min-width: ${media.mdup}) {
    right: ${props => props.right && 'auto'};
    left: ${props => props.left};
    transform: translateX(-8px);
    height: 32px;
    width: 32px;
  }
`;
