import { Parallax } from 'react-scroll-parallax';
import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const ParallaxContainer = ({ children, ...props }) => {
  return (
    <StyledParallax
      className="custom-class"
      offsetYMax={20}
      offsetYMin={-20}
      slowerScrollRate
      tag="figure"
      top={props.top}
      bottom={props.bottom}
      left={props.left}
      right={props.right}
    >
      {children}
    </StyledParallax>
  );
};

export default ParallaxContainer;

const StyledParallax = styled(Parallax)`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    position: absolute;
    left: ${props => props.left};
    right: ${props => props.right};
    top: ${props => props.top};
    bottom: ${props => props.bottom};
  }
`;
