import React from 'react';

import imgNeighbourhood from 'src/assets/images/home/neighbourhood.jpg';
import imgAmenities from 'src/assets/images/home/amenities.jpg';
import imgHomes from 'src/assets/images/home/home-homes.jpg';
import imgFloorplans from 'src/assets/images/home/home-floorplans.jpg';

import imgNeighbourhoodBubble from 'src/assets/images/home/bitmap.png';
import imgAmenitiesBubble from 'src/assets/images/home/group-9.png';
import imgInteriorsBubble from 'src/assets/images/home/bitmap-copy.png';

// TODO: Swap Neighbourhood card image
// TODO: Swap Amenities card image
// TODO: Floorplans rendering is not the same, does that matter?

const pageCards = [
  {
    text: {
      alignment: 'flex-start',
      link: '/neighbourhood',
      header: {
        title: 'Surrey City Centre',
        subtitle: 'Neighbourhood',
      },
      pWidth: 349,
      body:
        'What was once a suburban town centre, is now a walkable, transit-focused business, cultural, and entertainment hub.',
      marginLeft: true,
    },
    image: {
      image: imgNeighbourhood,
      // height: 415,
      // width: 524,
      mobileHeight: 70,
      title: 'Neighbourhood',
    },
    bubble: {
      image: imgNeighbourhoodBubble,
      bottom: '-50px',
      left: '-50px',
    },
    hasDot: {
      top: '20%',
      left: '-10px',
    },
  },
  {
    text: {
      alignment: 'flex-start',
      link: '/amenities',
      header: {
        title: 'Simply Practical',
        subtitle: 'Amenities',
      },
      pWidth: 272,
      body:
        "In a collection of homes that are effortlessly practical, Centra's amenities are no different.  Designed to give you conveniences you'll use every day.",
    },
    image: {
      image: imgAmenities,
      // height: 424,
      // width: 620,
      mobileHeight: 60,
      title: 'Amenities',
    },
    bubble: {
      image: imgAmenitiesBubble,
      bottom: '-100px',
      right: '-50px',
    },
    hasDot: {
      top: '15px',
      left: '-4px',
      right: '-10px',
    },
  },
  {
    text: {
      alignment: 'flex-start',
      link: '/interiors',
      header: {
        title: 'Contemporary Elegance',
        subtitle: 'Interiors',
      },
      body:
        'The beautifully designed Cristina Oberti interiors of Centra feature high ceilings that together with expansive windows give you a sense of natural spaciousness and frame the spectacular views beautifully.',
      pWidth: 349,
      marginLeft: true,
    },
    image: {
      image: imgHomes,
      mobileHeight: 100,
      title: 'Interiors',
    },
    bubble: {
      image: imgInteriorsBubble,
      top: '-80px',
      right: '-80px',
    },
    hasDot: {
      top: '50px',
      left: '-10px',
    },
  },
  // {
  //   text: {
  //     alignment: 'flex-start',
  //     link: '/floorplans',
  //     header: {
  //       title: (
  //         <span>
  //           Homes that
  //           <br />
  //           Work for You
  //         </span>
  //       ),
  //       subtitle: 'Floorplans',
  //     },

  //     body: 'Interiors that come together to create homes with real value.',
  //     pWidth: 307,
  //   },
  //   image: {
  //     image: imgFloorplans,
  //     backgroundPosition: '38% 80%',
  //     backgroundSize: '200%',
  //     mobileHeight: 51,
  //     title: 'Floorplans',
  //   },
  //   hasDot: {
  //     top: '80px',
  //     left: '-4px',
  //     right: '-10px',
  //   },
  // },
];

export default pageCards;
