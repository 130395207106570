import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import media from 'src/styles/media';
import CountUp from 'react-countup';

import TravelChip from '../../elements/TravelChip';
import Transportation from './elements/Transportation';
import Button from 'components/Button-Toggle';

import imgOpen from 'src/assets/images/icons/open.svg';
import imgClosed from 'src/assets/images/icons/close.svg';
import imgMap from 'src/assets/images/neighbourhood/map.jpg';
import imgPin from 'src/assets/images/neighbourhood/pin.svg';
import imgCentraPin from 'src/assets/images/neighbourhood/centra-pin2.svg';
import img5min from 'src/assets/images/neighbourhood/5-min-dr.png';

const Map = ({
  travelChips,
  mapLocations,
  activeGroup,
  handleClick,
  points,
  toggleVisibility,
  mapVisible,
}) => {
  return (
    <Root>
      <MapTitle>
        <h2>Surrey Centre</h2>
        <h1>Key Areas</h1>
      </MapTitle>

      <MapContent>
        <Image background={imgMap}>
          <GradientLayer mapVisible={mapVisible} />
          {points.map((point, index) => (
            <Point
              top={point.location[0]}
              left={point.location[1]}
              key={index}
              onClick={() => handleClick(index)}
              background={imgPin}
            >
              <PointBackground active={activeGroup === index} />
              <Label active={activeGroup === index}>
                {index < 3 ? `${index + 1}` : <Icon src={point.icon} />}
              </Label>
            </Point>
          ))}

          {travelChips.map((chip, index) => (
            <TravelChip
              key={index}
              info={chip}
              mapOverlay
              visible={mapVisible}
            />
          ))}
          <CentraPin background={imgCentraPin} />

          <MinText src={img5min} alt="5 minute drive" />

          <Compass>
            <span>N</span>
          </Compass>
        </Image>
        <Transportation />

        <CardWrapper>
          {mapLocations.map((locationGroup, index) => (
            <Card
              key={index}
              isActive={activeGroup === index}
              onClick={() => handleClick(index)}
            >
              <Title isActive={activeGroup === index}>
                <h1>
                  <span>{index + 1}</span>
                  {locationGroup.title}
                </h1>{' '}
                <OpenIndicator
                  src={activeGroup === index ? imgClosed : imgOpen}
                  alt="FeatureCard is Open"
                />
              </Title>
              <Content isActive={activeGroup === index}>
                <p>{locationGroup.description}</p>
                {locationGroup.locations.map((location, index) => (
                  <li key={index}>{location}</li>
                ))}
              </Content>
            </Card>
          ))}
          <ButtonWrapper>
            <Button
              label={
                mapVisible ? 'Hide Nearby Locations' : 'Show Nearby Locations'
              }
              border="#aa694c"
              handleClick={toggleVisibility}
            />
          </ButtonWrapper>
        </CardWrapper>
      </MapContent>

      <NearbyLocations>
        <h2>Nearby Locations</h2>
        <ChipWrapper>
          {travelChips.map((infoChip, index) => (
            <TravelChip key={index} info={infoChip} />
          ))}
        </ChipWrapper>
      </NearbyLocations>
    </Root>
  );
};

export default Map;

const pulse = keyframes`
0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 20px 15px rgba(238, 46, 79, 0.6);
      box-shadow: 0 0 20px 15px rgba(238, 46, 79, 0.6);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
`;

const pulseAnimation = css`
  ${pulse} 2s infinite
`;

const Root = styled.div`
  @media (min-width: ${media.mdup}) {
    background: #ffffff;
    margin: 0 5.8vw;
  }
  h2 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colorBlack};
    font-weight: 300;
  }
`;

const Image = styled.div`
  background-image: ${props =>
    // props.mapVisible
    //   ? `linear-gradient(to bottom, rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url(${
    //       props.background
    //     })`
    // :
    `url(${props.background})`};
  background-size: cover, cover;
  background-position: center, center;
  height: 109vw;
  position: relative;
  margin: 0 -23px;
  @media (min-width: ${media.mdup}) {
    height: 58.3vw;
    width: 52.3vw;
  }
`;

const NearbyLocations = styled.div`
  padding-bottom: 46px;
  padding-top: 40px;
  h2 {
    text-align: center;
    margin-bottom: 36px;
    font-size: 14px;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colorBlack};
    font-weight: normal;
  }
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const CardWrapper = styled.div`
  @media (min-width: ${media.mdup}) {
    width: 27.6vw;
  }
`;

const Card = styled.div`
  cursor: pointer;
  background-color: ${props =>
    props.isActive ? 'rgba(170, 105, 76, 0.13)' : '#FFFFFF'};
  margin: 0 -23px;
  padding: 0 23px 23px 23px;
  transition: all 0.8s;

  @media (min-width: ${media.mdup}) {
    margin: 0;
    width: 27.6vw;
    &:hover {
      background-color: rgba(170, 105, 76, 0.13);
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  h1 {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: ${props => (props.isActive ? 'r00' : '300')};
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 2.5;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colorCopper};
    display: inline-block;
    padding-right: 20px;
  }
`;

const OpenIndicator = styled.img`
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const Content = styled.div`
  padding-left: 27px;
  height: ${props => (props.isActive ? '175px' : '0')};
  overflow: hidden;
  transition: all 0.8s;
  p {
    margin-bottom: 3px;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colorBlack};
  }
  li {
    list-style: none;
    position: relative;
    padding-left: 27px;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colorBlack};
  }
  li:after {
    position: absolute;
    content: '';
    top: 45%;
    left: 0;
    width: 2px;
    height: 2px;
    border-radius: 50px;
    background: ${({ theme }) => theme.colorBlack};
  }

  @media (min-width: ${media.mdup}) {
    height: auto;
  }
`;

const Point = styled.div`
  position: absolute;
  top: ${props => `${props.top}%`};
  left: ${props => `${props.left}%`};
  width: 32px;
  height: 40px;
  background: #ffffff;
  filter: box-shadow(0 0 10px 5px rgba(238, 46, 79, 0.6));
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => `url(${props.background})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(-50%, -50%);
`;

const PointBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  animation: ${props => props.active && pulseAnimation};
`;

const Label = styled.span`
  font-size: 14px;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  transform: translateY(-25%);
`;

const MapTitle = styled.div`
  background: #ffffff;
  margin: -23px;
  padding: 23px;

  @media (min-width: ${media.mdup}) {
    margin: 30px;
    padding-top: 30px;
    padding-bottom: 0;
  }
`;

const MapContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;

  @media (min-width: ${media.mdup}) {
    flex-direction: row-reverse;
    position: relative;
    justify-content: flex-end;
  }
`;

const ButtonWrapper = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    cursor: pointer;
  }
`;

const CentraPin = styled.div`
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-position: center;
  height: 90px;
  width: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Icon = styled.img`
  transform: translate(1px, 3px);
`;

const Compass = styled.div`
  height: 25px;
  width: 25px;
  background-color: ${({ theme }) => theme.colorCopper};
  opacity: 0.75;
  position: absolute;
  bottom: -10px;
  right: 20px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  z-index: 1000;
  span {
    color: white;
    font-size: 12px;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;

    border-bottom: 5px solid white;
    position: absolute;
    top: 2px;
    left: 9px;
  }

  @media (min-width: ${media.mdup}) {
    height: 48px;
    width: 48px;
    right: -10px;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 15px solid white;
      position: absolute;
      top: 4px;
      left: 19px;
    }
    span {
      font-size: 18px;
      letter-spacing: 1.3px;
      transform: translateY(6px);
    }
  }
`;

const MinText = styled.img`
  position: absolute;
  left: 49%;
  top: 8%;
  transform: translate(-50%, -50%);
  @media (min-width: ${media.mdup}) {
    left: 49%;
    top: 8%;
  }
`;

const GradientLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: ${props => (props.mapVisible ? 'block' : 'none')};
  z-index: 999;
`;
