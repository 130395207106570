import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import LayoutWithHero from 'components/LayoutWithHero';
import Description from 'components/Description';
import ImageWithPOI from './elements/ImageWithPOI';
import TextBlock from 'components/TextBlock';
import FeatureAccordion from 'components/FeatureAccordion';
import ScrollAnimation from 'components/ScrollAnimation';
import ParallaxContainer from 'components/ParallaxContainer';

import imgHero from 'src/assets/images/homes/hero.jpg';
import imgLiving from 'src/assets/images/homes/living.jpg';
import imgStools from 'src/assets/images/homes/stools.jpg';
import imgBathroom from 'src/assets/images/homes/bathroom.jpg';
import imgBalcony from 'src/assets/images/homes/balcony.jpg';
import imgDiningBubble from 'src/assets/images/homes/diningBubbles.png';
import imgKitchenBubble from 'src/assets/images/homes/kitchenBubbles.png';

const text = {
  title: <h1>Contemporary Elegance</h1>,
  body: (
    <p>
      Centra's contemporary studio, one, two and three bedroom homes have been
      designed to give you a home with <span>urban style</span> and{' '}
      <span>natural sophistication</span>. It's thoughtfully designed floorplans
      ensure that every space is utilized as efficiently as possible, resulting
      in homes that not only feel <span>more spacious</span> but are also{' '}
      <span>endlessly practical</span>.
    </p>
  ),
};

const features = [
  {
    title: 'Cultivated Kitchens',
    features: [
      'Contemporary kitchen with quartz countertops, backsplash and waterfall edges',

      'Polished chrome Grohe fixtures embellished sleek cabinetry',

      'Under-mounted stainless steel sink for smart, convenient clean-up',

      {
        feature:
          'European stainless steel appliances to complement your modern lifestyle:',
        subfeatures: [
          '24” Appliance Package for Studios, 1 & Junior 2 Bedrooms',

          '30” Appliance Package for 2 & 3 bedrooms',

          'Blomberg refrigerator with integrated front panel that matches the cabinetry seamlessly',

          'Fulgor stainless steel 4 or 5 burner gas cooktop with combined wall oven',

          'Blomberg integrated dishwasher with 	unified front panel',

          'Faber stainless steel hood fan',

          'Panasonic stainless steel microwave with custom trim kit',
        ],
      },
    ],
  },
  {
    title: 'Inspired Interiors',
    features: [
      `Lofty 8’8” ceilings tower over spacious main living areas`,

      `Choice of two exquisite colour schemes curated by Cristina Oberti Interior Design - Light and Dark`,

      `Elegant wide-plank laminate wood flooring provides wall-to-wall upscale comfort`,

      `Expansive windows are covered with stylish roller shades for clean, modern lighting control`,

      `Blomberg front-loading washer and dryer stacked in a space-saving laundry closet`,

      `Climate control with air conditioning homes for all-season comfort`,
    ],
  },
  {
    title: 'Visionary Bathrooms',
    features: [
      `Indulge in a modern, sleek design, featuring elegant stainless steel Grohe faucets and showerheads with polished chrome accessory fixtures`,

      `Stunning quartz countertops accompany lavish cabinetry, with contemporary soft-close drawers and doors`,

      `Sophisticated porcelain tile flooring provide effortless luxurious comfort`,

      `Exclusive floor-to-ceiling porcelain-tiled walls featured in ensuite bathrooms`,

      `Floor-mounted dual-flush toilet with soft-close seat`,

      `Spacious medicine cabinet and shelving for convenient storage`,
    ],
  },
];

const diningPOI = [
  {
    description: `Lofty 8'8" ceilings tower over spacious main living areas`,
    location: [19, 23],
  },
  {
    description: 'Expansive windows are covered with stylish roller shades',
    location: [27, 46.5],
  },
  {
    description: 'Elegant wide-plank laminate wood flooring',
    location: [86, 57],
  },
];

const kitchenPOI = [
  {
    description: `Faber stainless steel hood fan`,
    location: [12, 57],
  },
  {
    description: `Polished chrome Grohe fixtures embellished sleek cabinetry`,
    location: [18, 70],
  },
  {
    description: `Under-mounted stainless steel sink`,
    location: [45, 44],
  },
  {
    description: `Blomberg refrigerator with integrated  front panel`,
    location: [34, 32],
  },
  {
    description: `Fulgor stainless steel 4 -burner gas cooktop`,
    location: [39, 63],
  },
  {
    description: `Quartz countertops, backsplash and waterfall edges`,
    location: [46, 81],
  },
];

const bathroomPOI = [
  {
    description: 'Stainless steel Grohe faucets and shower heads',
    location: [33, 36],
  },
  {
    description: 'Quartz countertops',
    location: [66, 80],
  },
  {
    description: 'Porcelain Tile Flooring',
    location: [90, 23],
  },
  {
    description: 'Lavish cabinetry, with soft-close drawers and doors',
    location: [79, 55],
  },
];

class Interiors extends Component {
  state = {
    openFeature: 1,
  };
  handleOpenFeature = index => {
    if (this.state.openFeature === index) {
      this.setState({ openFeature: null });
    } else this.setState({ openFeature: index });
  };
  render() {
    return (
      <LayoutWithHero
        hero={{
          image: imgHero,
          title: (
            <span>
              Interiors With
              <br />
              Contemporary Elegance
            </span>
          ),
        }}
        // nextPage={{ text: 'Discover Next: The Team', link: '/team' }}
        nextPage={{
          text: 'Discover Next: The Floorplans',
          link: '/floorplans',
        }}
      >
        <ScrollAnimation>
          <Description
            image={{
              image: imgStools,
              alt: 'Centra Surrey Building at Sunrise',
              backgroundPosition: 'calc(100% + 95px)',
              backgroundSize: '200%',
            }}
            text={{
              alignment: 'flex-start',
              title: <h1>Refined Style</h1>,
              body: (
                <p>
                  The beautifully designed Cristina Oberti interiors of Centra
                  feature <span>high ceilings</span> that together with{' '}
                  <span>expansive windows</span> give you a sense of natural
                  spaciousness and frame the <span>spectacular views</span>{' '}
                  beautifully. In the kitchen, clean contemporary lines are
                  complemented by European appliances and{' '}
                  <span>stone countertops</span>, and{' '}
                  <span>elegant wide-planked flooring</span> sweeps throughout
                  the homes to create natural continuity in the design.
                </p>
              ),
            }}
          />
        </ScrollAnimation>

        <ScrollAnimation>
          <ImageWithPOI
            image={imgLiving}
            label="Dining/Living Area"
            points={diningPOI}
          >
            <ParallaxContainer bottom="-3%" right="-5%">
              <Bubble src={imgDiningBubble} bottom={-3} right={-5} />
            </ParallaxContainer>
          </ImageWithPOI>
        </ScrollAnimation>

        <ScrollAnimation>
          <ImageWithPOI
            image={imgStools}
            label="Dining/Living Area"
            points={kitchenPOI}
          >
            <ParallaxContainer bottom="-3%" left="-8%">
              <Bubble src={imgKitchenBubble} bottom={-3} left={-8} />
            </ParallaxContainer>
          </ImageWithPOI>
        </ScrollAnimation>

        <ScrollAnimation>
          <SplitFeature>
            <TextWrapper>
              <TextBlock text={text} />
            </TextWrapper>
            <ImageWithPOI
              image={imgBathroom}
              label="Bathroom"
              points={bathroomPOI}
              halfsized
            />
          </SplitFeature>
        </ScrollAnimation>

        <ScrollAnimation>
          <ImageWithPOI
            image={imgBalcony}
            label="Balcony"
            // points={diningPOI}
          />
        </ScrollAnimation>

        <ScrollAnimation>
          <FeatureAccordion
            features={features}
            handleClick={this.handleOpenFeature}
            activeIndex={this.state.openFeature}
            title="Features and Finishes"
          />
        </ScrollAnimation>
      </LayoutWithHero>
    );
  }
}

export default Interiors;

const TextWrapper = styled.div`
  margin-top: -55px;
  padding: 0 27px;
  margin-bottom: 59px;
  @media (min-width: ${media.mdup}) {
    max-width: 27.2vw;
    padding: 3.5vw;
  }
`;

const SplitFeature = styled.div`
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
`;

const Bubble = styled.img`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    /* position: absolute;
    top: ${props => `${props.top}%`};
    bottom: ${props => `${props.bottom}%`};
    left: ${props => `${props.left}%`};
    right: ${props => `${props.right}%`}; */
  }
`;
