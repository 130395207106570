import React from 'react';
import styled from 'styled-components';

const renderContent = (content, type) => {
  if (type === 'location') {
    return (
      <a href={content.link} target="_blank" rel="noopener noreferrer">
        {content.body}
      </a>
    );
  }
  if (type === 'contact') {
    return content.map((item, index) => (
      <a href={item.link} target="_blank" rel="noopener noreferrer" key={index}>
        <p>{item.body}</p>
      </a>
    ));
  }
};

const ContactFragment = ({ title, content, type }) => {
  return (
    <Root>
      <h2>{title}</h2>
      {renderContent(content, type)}
    </Root>
  );
};

export default ContactFragment;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  h2 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    color: white;
    font-weight: 300;
    margin-bottom: 5px;
  }
  a {
    font-size: 14px;
    font-weight: 300;
    color: white;
    line-height: 1.71;
    letter-spacing: 1px;
  }
`;
