import React, { Fragment } from 'react';

export default {
  outline: (
    <Fragment>
      <g
        id="level-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="bg-shapes" transform="translate(92.000000, 32.000000)">
          <path
            d="M163.165279,149.328164 L163.165279,128.445223"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="113.834835 228.32016 113.834835 226.504252 112.021216 226.504252 112.021216 190.57954 96.3938634 190.57954 96.3938634 177.656328 102.741531 177.656328 102.741531 176.506253"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="12.4535189 169.726863 44.5848067 169.726863 44.5848067 165.641071 62.2373674 165.641071 62.2373674 138.674837 102.741531 138.674837"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="127.860157 108.409705 127.860157 81.5947974 155.064446 81.5947974"
          />
          <path
            d="M105.915364,37.4077039 L155.094673,37.4077039"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M100.686096,40.6763382 L105.915364,41.3724362"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M105.915364,108.43997 L105.915364,37.4077039"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M129.431961,128.445223 L129.431961,149.328164"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M118.580472,128.445223 L118.580472,149.328164"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M136.444622,162.917209 L136.444622,128.445223"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M118.580472,128.445223 L189.855709,128.445223"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M189.855709,149.297899 L118.580472,149.297899"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M189.855709,162.917209 L119.033877,162.917209"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M102.741531,138.705103 L102.741531,108.43997"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="127.860157 108.43997 189.855709 108.43997 189.855709 176.506253"
          />
          <path
            d="M102.741531,108.318909 L105.764229,108.318909"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="175.890841 176.506253 175.890841 195.543022 177.70446 195.543022 177.70446 242"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="12.4535189 169.726863 12.4535189 145.030515 -1.42108547e-14 142.972486 11.5467093 67.0675338 40.0507585 71.3954477 41.8643778 58.4722361 40.5041633 58.2301151 44.5848067 31.5967984 100.686096 40.6763382 101.381316 35.9247124 102.288126 36.1668334 105.310825 15.97999 146.631117 22.2146073 150.016539 0 159.53804 2.72386193 155.094673 31.5967984 155.094673 108.318909"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="177.946276 242 265 242 265 212.309905 227.639443 212.309905 202.762633 187.613557 189.402304 201.081541 189.402304 242"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="137 128 163.514997 148.969565 190 128"
          />
          <polyline
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="137 149 163.514997 128 190 149"
          />
          <path
            d="M176,149.381738 L176,176.659697 L176,149.381738 Z"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M136.418046,163.051026 L136.418046,176.659697 L136.418046,163.051026 Z"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <polygon
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="136.710002 108 136.710002 114.063348 128.175158 114.063348 128.175158 108"
          />
          <path
            d="M176.217584,176.30362 L190,176.30362 L176.217584,176.30362 Z"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M128,114 L137,108"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M128,108 L137,114"
            id="Path"
            stroke="#2A2827"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <text
            id="AMENITY"
            opacity="0.5"
            font-family="Brother-1816-Book, Brother 1816"
            font-size="8"
            font-weight="300"
            letter-spacing="0.6666667"
            fill="#B77356"
          >
            <tspan x="30.4026665" y="108">
              AMENITY
            </tspan>
          </text>
          <text
            id="LOBBY"
            opacity="0.5"
            font-family="Brother-1816-Book, Brother 1816"
            font-size="8"
            font-weight="300"
            letter-spacing="0.6666667"
            fill="#B77356"
          >
            <tspan x="116.489333" y="62">
              LOBBY
            </tspan>
          </text>
          <text
            id="M."
            opacity="0.5"
            font-family="Brother-1816-Book, Brother 1816"
            font-size="8"
            font-weight="300"
            letter-spacing="0.6666667"
            fill="#B77356"
          >
            <tspan x="137.860062" y="99">
              M.
            </tspan>
          </text>
        </g>
      </g>
    </Fragment>
  ),
};
