import React from 'react';

export default {
  compass: (
    <g id="compass" transform="translate(402.000000, 236.000000)">
      <g transform="translate(20.152543, 20.152543) rotate(-315.000000) translate(-20.152543, -20.152543) translate(6.152543, 5.652543)">
        <circle
          id="Oval"
          stroke="#2A2827"
          strokeWidth="0.5"
          opacity="0.795321"
          cx="14"
          cy="14"
          r="14"
        />
        <path
          d="M14.5,0 L14.5,28"
          id="Line"
          stroke="#2A2827"
          strokeWidth="0.5"
          opacity="0.795321"
          strokeLinecap="square"
        />
        <path
          d="M0,14 L28,14"
          id="Line-2"
          stroke="#2A2827"
          strokeWidth="0.5"
          opacity="0.795321"
          strokeLinecap="square"
        />
        <path
          d="M14.5,1 L14.5,13.5"
          id="Line-3"
          stroke="#B77356"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
      </g>
      <polygon
        id="N"
        fill="#2A2827"
        points="37.6928333 9.084 37.6858333 9.084 34.5568333 5.689 34.3958333 5.514 34.3958333 9 33.7238333 9 33.7238333 3.876 33.7308333 3.876 36.8598333 7.271 37.0208333 7.446 37.0208333 3.96 37.6928333 3.96"
      />
    </g>
  ),
};
