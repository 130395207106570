import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const PlanCard = ({
  id,
  bedrooms,
  interior,
  handleClick,
  index,
  den,
  showCondoUnits,
  //hideUnit,
  renderBedrooms,
  junior2,
  soldout,
}) => {
  return (
    <Root
      onClick={() => handleClick(index, soldout)}
      hide={
        (showCondoUnits && id.includes('th')) ||
        (!showCondoUnits && !id.includes('th'))
      }
      //hideUnit={hideUnit}
      soldout={soldout}
    >
      {soldout && soldout === true && (
        <Overlay>
          <img
            src={require('src/assets/images/floorplans/sold_out_lg.png')}
            alt="sold out"
          />
        </Overlay>
      )}
      <ID>{id}</ID>
      <Bedrooms>
        {renderBedrooms(bedrooms, junior2)}
        {den && '+ Den'}
      </Bedrooms>
      <Sqft>{interior} s ft</Sqft>
    </Root>
  );
};

export default PlanCard;

const Root = styled.div`
  position: relative;
  background: #ffffff;
  padding: 15px;
  text-align: center;
  width: 100%;
  height: 100%;
  min-width: 136px;
  cursor: ${props => (props.soldout ? 'default' : 'pointer')};
  //display: ${props => props.hideUnit && 'none'};

  @media (min-width: ${media.mdup}) {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
    display: ${props => props.hide && 'none'};
  }
`;

const ID = styled.h1`
  font-size: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colorCopper};

  @media (max-width: ${media.sm}) {
    font-size: 18px;
  }
`;

const Bedrooms = styled.p`
  font-size: 12px;
  letter-spacing: 1.7px;
  color: ${({ theme }) => theme.colorBlack};
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 13px;
  height: 40px; /* hardcoded to accomodate for 2 lines */
`;

const Sqft = styled.p`
  font-size: 12px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.9px;
  color: ${({ theme }) => theme.colorBlack};
  text-transform: uppercase;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(247, 245, 241, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    width: auto;
    height: 50%;
`;
