import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const renderTitle = (type, info, index) => {
  if (type === 'card') {
    return (
      <p>
        <span>{info.num}</span>
        {info.subtext}
      </p>
    );
  } else if (type === 'expandable') {
    return (
      <p>
        <span>{index + 1}</span>
        {info.subtext}
      </p>
    );
  } else return <span>{info.num}</span>;
};

const InfoChip = ({ info, type, index, handleClick, activeIndex }) => {
  return (
    <Root
      expandable={type === 'expandable'}
      onClick={() => handleClick(index)}
      className={activeIndex === index && 'isActive'}
    >
      <Title>{renderTitle(type, info, index)}</Title>
      <Body expandable={type === 'expandable'} className="hidden">
        {info.body && <span>{info.body}</span>}
      </Body>
    </Root>
  );
};

export default InfoChip;

const Root = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.8s;
  background: white;
  flex: 1;
  max-height: 133px;
  height: 133px;
  opacity: 0.85;
  margin-bottom: 24px;
  width: 60vw;
  /* height: 0px; */
  &:not(:last-child) {
    @media (min-width: ${media.mdup}) {
      margin-right: 10px;
    }
    @media (min-width: 1200px) {
      margin-right: 30px;
    }
  }

  @media (min-width: ${media.mdup}) {
    width: auto;
  }

  @media (max-width: ${media.md}) {
    &.isActive {
      max-height: 284px;
      height: 100%;
      opacity: 1;
      height: 288px;

      span {
        opacity: 1;
      }
      .hidden {
        transition: opacity 1s;
        transition-delay: 0.5s;
        height: 100%;
        max-height: 3000px;
        opacity: 1;
      }
    }
  }

  @media (min-width: ${media.mdup}) {
    &:hover {
      max-height: 300px;
      opacity: 1;
      /* height: 300px; */

      div {
        opacity: 1;
      }
    }
  }

  @media (min-width: ${media.mdup}) {
    margin-right: 10px;
    &:hover {
      max-height: 300px;
      height: 100%;
      opacity: 1;
      height: 300px;

      span {
        opacity: 1;
      }
      .hidden {
        transition: opacity 1s;
        transition-delay: 0.5s;
        height: 100%;
        max-height: 3000px;
        opacity: 1;
      }
    }
  }

  @media (min-width: 1400px) {
    &:hover {
      max-height: 254px;
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-weight: normal;
  /* padding-top: 30px; */

  /* STYLING REMOVED FOR PADDING TOP FOR HOME */
  padding-bottom: 90px;

  /* padding-bottom: 30px; */
  /* background: black; */
  height: 133px;

  span {
    display: block;
    font-size: 30px;
    color: ${({ theme }) => theme.colorCopper};
    margin-bottom: 6px;
  }
  p {
    font-size: 14px;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colorBlack};
    max-width: 149px;
  }
`;

const Body = styled.div`
  /* padding: 0px 10px 30px 10px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background: white;
  height: 0;
  opacity: 0;
  padding-top: 30px;
  span {
    padding: 0px 10px 30px 10px;
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colorBlack};
    /* opacity: 0; */
  }
  @media (min-width: ${media.mdup}) {
    padding-top: 0;
  }
`;
