import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import Chip from './Chip';

// import imgPlaceholder from 'src/assets/images/home/neighbourhood.jpg';

const Layout = ({
  chip,
  handleClick,
  activeIndex,
  chipOrientation,
  image,
  height,
  transform,
  width,
  dot,
  chipImage,
  desktopHeight
}) => {
  // if (chipOrientation === 'right') {
  return (
    <Root chipOrientation={chipOrientation}>
      <Title>
        <h3>{chip.header.subtitle}</h3>
        <h2>{chip.header.title}</h2>
      </Title>
      <Image
        background={image}
        height={height}
        transform={transform}
        width={width}
        desktopHeight={desktopHeight}
      >
        <TextTitle chipOrientation={chipOrientation}>
          Everest {chip.header.title}
        </TextTitle>
        {chip.features.map((point, index) => {
          if (point.locations) {
            return point.locations.map((multiitem, secondaryIndex) => (
              <Point
                top={multiitem[0]}
                left={multiitem[1]}
                key={secondaryIndex}
                onClick={() => handleClick(index)}
              >
                <span>{index + 1}</span>
                <Label active={activeIndex === index}>{point.title}</Label>
              </Point>
            ));
          } else
            return (
              <Point
                top={point.location[0]}
                left={point.location[1]}
                key={index}
                onClick={() => handleClick(index)}
              >
                <span>{index + 1}</span>
                <Label active={activeIndex === index}>{point.title}</Label>
              </Point>
            );
        })}
      </Image>

      <Chipwrapper chipOrientation={chipOrientation} transform={chip.transform}>
        <ChipImage background={chipImage} />

        <Chip
          chip={chip}
          activeIndex={activeIndex}
          handleClick={handleClick}
          dot={dot}
        />
      </Chipwrapper>
    </Root>
  );
};

export default Layout;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  @media(min-width:${media.xsup}){
    br{
      display:none;
    }
  }
  @media (min-width: ${media.mdup}) {
    flex-direction: ${props => props.chipOrientation};
    margin-bottom: 200px;
  }
`;

const Image = styled.div`
  height: 100%;
  height: ${props => `${props.height}vw`};
  position: relative;
  background-image: ${props => `url(${props.background})`};
  background-size: cover;
  /* background-size:contain;
  background-repeat:no-repeat; */
  background-position: center;
  @media (min-width: ${media.mdup}) {
    width: ${props => props.width};
    height: ${props=>props.desktopHeight};
  }
`;

const Point = styled.div`
  position: absolute;
  top: ${props => `${props.top}%`};
  left: ${props => `${props.left}%`};
  width: 20px;
  height: 20px;
  background: #ffffff;
  box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.9);
  transform: translate(-50%, -50%);

  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.span`
  background: #ffffff;
  position: absolute;
  height: 36px;
  top: -50px;
  white-space: nowrap;
  display: ${props => (props.active ? 'flex' : 'none')};
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colorCopper};

  font-size: 9px;
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colorBlack};
  padding: 13px 40px;
  text-transform: uppercase;

  ::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -10px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    transform: translateY(100%);
    border-top: 10px solid ${({ theme }) => theme.colorCopper};
  }
`;

const Title = styled.div`
  h2,
  h3 {
    text-transform: uppercase;
  }
  h2 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 3px;
    color: ${({ theme }) => theme.colorBlack};
    margin-top: 4px;
    margin-bottom: 40px;
    @media (min-width: ${media.smup}) {
      margin-bottom: 24px;
      margin-top: 4px;
      /* margin-top: 30px; */
    }
  }
  h3 {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colorBlack};
  }

  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const Chipwrapper = styled.div`
  @media (min-width: ${media.mdup}) {
    margin-left: ${props => (props.chipOrientation === 'row' ? '105px' : '0')};
    margin-right: ${props =>
    props.chipOrientation === 'row-reverse' ? '105px' : '0'};
    transform: ${props => props.transform};
  }
  /* transform: translateY(100px); */
`;

const ChipImage = styled.div`
  background: ${props => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  width: 21.8vw;
  height: 15.2vw;
  min-height: 220px;
  min-width: 314px;
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
  }
`;

const TextTitle = styled.p`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    font-size: 12px;
    font-weight: 300px;
    color: ${({ theme }) => theme.colorCopper};
    letter-spacing: 3.8px;
    position: absolute;
    bottom: 0;
    left: ${props => props.chipOrientation === 'row' && 0};
    right: ${props => props.chipOrientation === 'row-reverse' && 0};

    text-transform: uppercase;
  }
`;
