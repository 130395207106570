import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'src/styles/media';

const Button = ({
  type,
  border,
  handleClick,
  mobileBorderless,
  label,
  centered,
}) => {
  return (
    <Border
      border={border}
      mobileBorderless={mobileBorderless}
      centered={centered}
    >
      {type === 'register' && (
        <RootLink to="/register" onClick={handleClick}>
          <span>Register Now</span>
        </RootLink>
      )}
      {type === 'book' && (
        <AhrefLink
          href="https://rennie.as.me/schedule.php?calendarID=5602694"
          target="_blank"
          onClick={handleClick}
        >
          <span className="show-on-mobile">Book Appt</span>
          <span className="show-on-desktop">Book Appointment</span>
        </AhrefLink>
      )}
      {(!type || (type !== 'register' && type !== 'book')) && (
        <Root onClick={handleClick}>
          <span>{label}</span>
        </Root>
      )}
    </Border>
  );
};

export default Button;

const Border = styled.div`
  border: ${props => props.border && `1px solid ${props.theme.colorCopper}`};
  max-width: 216px;
  margin: ${props => props.centered && '0 auto'};
  margin-left: ${props => props.mobileBorderless && 'auto'};

  @media (min-width: ${media.mdup}) {
    border: ${props =>
      props.mobileBorderless && `1px solid ${props.theme.colorCopper}`};

    &:hover {
      background: ${({ theme }) => theme.colorCopper};
      span {
        color: white;
      }
    }
  }
`;

const buttonStyles = css`
  span {
    display: block;
    width: 100%;
    font-size: 11px;
    letter-spacing: 1.6px;
    color: ${({ theme }) => theme.colorCopper};
    text-transform: uppercase;
    text-align: center;
    padding: 9px 26px;
  }
`;

const RootLink = styled(Link)`
  ${buttonStyles}
`;

const AhrefLink = styled.a`
  ${buttonStyles}
`;

const Root = styled.div`
  ${buttonStyles}
`;
