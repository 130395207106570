import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const TextHeader = ({ header }) => {
  return (
    <Root alignment={header.alignment}>
      <h3>{header.subtitle}</h3>
      <h1>{header.title}</h1>
    </Root>
  );
};

export default TextHeader;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${props => props.alignment};
  h1,
  h3 {
    text-transform: uppercase;
  }
  h1 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 3px;
    color: ${({ theme }) => theme.colorBlack};
    margin-top: 24px;
    margin-bottom: 13px;
    @media (min-width: ${media.smup}) {
      margin-bottom: 24px;
      margin-top: 4px;
      /* margin-top: 30px; */
    }
  }
  h3 {
    display: none;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3.8px;
    color: ${({ theme }) => theme.colorCopper};
    @media (min-width: ${media.smup}) {
      display: block;
    }
  }
`;
