import React from 'react';
import styled from 'styled-components';
import Swipeable from 'react-swipeable';
import media from 'src/styles/media';

import imgClose from 'src/assets/images/icons/close-icon.svg';
import Button from 'components/Button-WithBorder';
import BackButton from 'components/Button-Toggle';
import ScrollAnimation from 'components/ScrollAnimation';

import imgTriangle from 'src/assets/images/icons/triangle-brown.svg';
import imgCompass from 'src/assets/images/floorplans/compass-4-x.png';

const MobilePopUp = ({
  floorplan,
  floorplansLength,
  isOpen,
  handleClose,
  handleSwipeLeft,
  handleSwipeRight,
  index,
  swipeDirection,
  renderDetails,
}) => {
  return (
    <Root isOpen={isOpen} key={index}>
      <Swipeable
        onSwipedLeft={handleSwipeLeft}
        onSwipedRight={handleSwipeRight}
        trackMouse
      >
        <CloseButton src={imgClose} alt="Close Window" onClick={handleClose} />

        <ScrollAnimation effect={swipeDirection}>
          <Container>
            <RightColumn>
              <ImageWrapper>
                {floorplan.soldout && (
                  <Sold
                    src={require('src/assets/images/floorplans/sold_out_lg.png')}
                    alt="sold out"
                  />
                )}
                <PlanImage
                  background={require(`src/assets/images/floorplans/planImage/${
                    floorplan.id
                  }.png`)}
                  enlargeImage={floorplan.id === 'th3'}
                  soldout={floorplan.soldout}
                >
                  <Compass src={imgCompass} />
                </PlanImage>
              </ImageWrapper>

              <NavigationContainer>
                <Navigation
                  left
                  arrow={imgTriangle}
                  onClick={handleSwipeRight}
                  numberOfPlans={floorplansLength}
                >
                  <span>Previous Plan</span>
                </Navigation>

                <Navigation
                  arrow={imgTriangle}
                  onClick={handleSwipeLeft}
                  numberOfPlans={floorplansLength}
                >
                  <span>Next Plan</span>
                </Navigation>
              </NavigationContainer>
            </RightColumn>

            <LeftColumn>
              <LocationsContainer>
                <h1>Plan Locations:</h1>

                <Locations
                  background={require(`src/assets/images/floorplans/contextPlates/${
                    floorplan.id
                  }.png`)}
                />
              </LocationsContainer>

              <PlanDetails>
                <PlanLabel>{floorplan.id.split('-', 1)}</PlanLabel>
                <DetailWrapper marginRight>
                  <h1>Details</h1>
                  <p>
                    {renderDetails(
                      floorplan.bedrooms,
                      floorplan.den,
                      floorplan.junior2
                    )}
                  </p>
                </DetailWrapper>

                <DetailWrapper>
                  <h1>Interior</h1>
                  <p>{floorplan.interior} sq ft</p>
                  <DesktopHidden>
                    <h1>Exterior</h1>
                    <p>{floorplan.exterior} sq ft</p>
                  </DesktopHidden>
                </DetailWrapper>

                <DetailWrapper mobileHidden>
                  <h1>Exterior</h1>
                  <p>{floorplan.exterior} sq ft</p>
                </DetailWrapper>

                <Units>
                  {floorplan.units === '' ? '' : `Units ${floorplan.units}`}
                </Units>
                <ButtonWrapper>
                  <a
                    href={`/assets/pdfs/${floorplan.id}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button label="Download" border="#aa694c" />
                  </a>

                  <BackButton
                    label="Back"
                    border="#aa694c"
                    width={165}
                    arrow={imgTriangle}
                    handleClick={handleClose}
                  />
                </ButtonWrapper>
              </PlanDetails>

              <ButtonWrapper mobile>
                <a
                  href={`/assets/pdfs/${floorplan.id}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button label="Download" border="#aa694c" />
                </a>
              </ButtonWrapper>
            </LeftColumn>
          </Container>
        </ScrollAnimation>
      </Swipeable>
    </Root>
  );
};

MobilePopUp.defaultProps = {
  floorplan: { id: 'a1' },
};

export default MobilePopUp;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  transform: ${props =>
    props.isOpen ? 'transform3D(0,0,0)' : 'translateY(100%)'};
  height: 100vh;
  width: 100%;
  z-index: 100;
  background: #ffffff;
  transition: all 0.8s;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;

  @media (max-width: ${media.sm}) and (orientation: landscape) {
    overflow-y: scroll;
  }

  @media (min-width: ${media.mdup}) {
    padding: 0;
    min-height: 100vh;
    height: 100%;
    overflow-y: scroll;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: ${media.mdup}) {
    height: auto;
  }
`;

const Sold = styled.img`
  width: 72px;
  height: 72px;

  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  opacity: 1;
  @media (min-width: ${media.mdup}) {
    width: 120px;
    height: 120px;
  }
`;

const PlanImage = styled.div`
  position: relative;
  width: 100%;
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  height: 35vh;
  height: ${props => props.enlargeImage && `45vh`};
  background-image: ${props => `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: ${props => (props.soldout ? 0.2 : 1)};

  @media (min-width: ${media.mdup}) {
    height: 38.6vw;
    height: ${props => props.enlargeImage && `46vw`};
    padding-top: 0;
    padding-left: 6vw;
    padding-right: 6vw;
    position: relative;
  }
`;

const Locations = styled.div`
  background: ${props => `url(${props.background})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 25vw;
  max-height: 120px;
  @media (min-width: ${media.mdup}) {
    height: 6.9vw;
    max-height: none;
    background-position: left;
  }
`;

const DetailWrapper = styled.div`
  margin-right: ${props => props.marginRight && '15px'};
  display: ${props => props.mobileHidden && 'none'};
  @media (min-width: ${media.xsup}) {
    display: ${props => props.mobileHidden && 'block'};
  }
  @media (min-width: ${media.mdup}) {
    margin-bottom: 23px;
  }
  h1 {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colorBlack};
    margin-bottom: 5px;
    @media (min-width: ${media.mdup}) {
      margin-bottom: 19px;
    }
  }

  p {
    font-size: 14px;
    letter-spacing: 0.9px;
    color: ${({ theme }) => theme.colorBlack};
    text-transform: uppercase;
    width: 25vw;
  }
`;

const PlanDetails = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: row;

  @media (min-width: ${media.mdup}) {
    margin-top: 4.1vw;
    background: ${({ theme }) => theme.colorOffWhite};
    flex-direction: column;
    padding: 1.6vw;
    width: 16.5vw;
    margin-bottom: 61px;
    min-width: 200px;
  }
`;

const PlanLabel = styled.h1`
  font-size: 40px;
  font-weight: bold;
  color: ${({ theme }) => theme.colorBlack};
  margin-right: 20px;
  @media (min-width: ${media.mdup}) {
    font-size: 54px;
    margin-bottom: 50px;
  }
`;

const Navigation = styled.div`
  position: absolute;
  left: ${props => (props.left ? '-15px' : '')};
  right: ${props => (props.left ? '' : '0')};
  top: 50%;
  transform: rotate(270deg);
  cursor: pointer;
  display: ${props => (props.numberOfPlans > 1 ? 'block' : 'none')};

  span {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1.4px;
    color: ${({ theme }) => theme.colorCopper};
    @media (min-width: ${media.mdup}) {
      font-size: 14px;
      letter-spacing: 2px;
    }
  }
  &:after {
    content: '';
    height: 5px;
    width: 9px;
    background: ${props => `url(${props.arrow})`};
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 50%;
    top: ${props => props.left && '-5px'};
    bottom: ${props => (props.left ? '' : '-8px')};

    transform: ${props => (props.left ? 'rotate(180deg)' : '')};
    @media (min-width: ${media.mdup}) {
      top: 50%;
      transform: ${props => (props.left ? 'rotate(90deg)' : 'rotate(-90deg)')};
      left: ${props => (props.left ? '-15px' : 'auto')};
      right: ${props => (props.left ? 'auto' : '-15px')};
    }
  }

  @media (min-width: ${media.mdup}) {
    bottom: 0;
    transform: rotate(0);
    top: auto;
    right: ${props => (props.left ? '37.1vw' : '25.4vw')};
    left: auto;
    position: static;
    margin: 30px;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  padding: 6.1vw;
  cursor: pointer;
  @media (min-width: ${media.mdup}) {
    height: 32px;
    width: 32px;
    padding: 0;
    margin-top: 1.7vw;
    margin-right: 1.7vw;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: ${props => (props.mobile ? 'block' : 'none')};
  @media (min-width: ${media.mdup}) {
    display: ${props => (props.mobile ? 'none' : 'block')};
    margin-top: 36px;
  }
`;

const Container = styled.div`
  height: 100vh;
  padding: 6.1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    min-height: 100vh;
    height: 100%;

    padding: 0;
  }
`;

const LeftColumn = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: 5.3vw;
  }
`;

const LocationsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  /* display: none; */
  h1 {
    font-size: 12px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colorBlack};
    font-weight: ${props => (props.bold ? '500' : 'normal')};
    text-align: center;
    margin-bottom: 14px;
    @media (min-width: ${media.mdup}) {
      text-align: left;
    }
  }

  @media (min-width: ${media.mdup}) {
    align-self: flex-start;
    margin-top: 0px;

    bottom: 0;
    padding-bottom: 20px;
  }

  /* @media (min-width: ${media.smup}) {
    display: block;
  } */
`;

const NavigationContainer = styled.div`
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
`;
const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 6vw;

  @media (min-width: ${media.mdup}) {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 85px;
    justify-content: flex-start;
  }
`;

const Units = styled.p`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    text-transform: uppercase;
  }
`;

const Compass = styled.img`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

const DesktopHidden = styled.div`
  @media (min-width: ${media.xsup}) {
    display: none;
  }
`;
