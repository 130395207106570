import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import Description from 'components/Description';
import InfoChip from './elements/InfoChip';
import TextBlock from 'components/TextBlock';

import LayoutWithHero from 'components/LayoutWithHero';
import ScrollAnimation from 'components/ScrollAnimation';
import Map from './sections/Map/Map';

import imgLogo from 'src/assets/images/logo/logo-flower.png';
import imgCollage1 from 'src/assets/images/neighbourhood/collage1.jpg';
import imgCollage2 from 'src/assets/images/neighbourhood/collage2.jpg';
import imgCollage3 from 'src/assets/images/neighbourhood/collage3.jpg';
import imgCentraAerial from 'src/assets/images/neighbourhood/aerial.jpg';
import imgCentraPin from 'src/assets/images/neighbourhood/centra-pin.svg';
import imgLocations from 'src/assets/images/neighbourhood/locations.png';
import imgCollageBubble from 'src/assets/images/neighbourhood/group-9.png';

import infoChips from 'src/data/neighbourhood/infoChips';
import mapLocations from 'src/data/neighbourhood/mapLocations';
import mapPoints from 'src/data/neighbourhood/mapPoints';
import travelChips from 'src/data/neighbourhood/travelChips';

class Neighbourhood extends Component {
  state = {
    activeGroup: 0,
    visible: false,
    mapVisible: false,
  };

  handleHighlightLocation = index => {
    if (this.state.activeGroup === index) {
      this.setState({ activeGroup: null });
    } else this.setState({ activeGroup: index });
  };

  toggleVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };

  toggleMapVisible = () => {
    this.setState({ mapVisible: !this.state.mapVisible });
  };

  render() {
    return (
      <LayoutWithHero
        hero={{
          image: require('src/assets/images/neighbourhood/neighbourhood.jpg'),
          title: (
            <span>
              Surrey City
              <br /> Centre
            </span>
          ),
        }}
        nextPage={{ text: 'Discover Next: The Amenities', link: '/amenities' }}
        // hideOverflow
      >
        <Description
          image={{
            image: require('src/assets/images/neighbourhood/family.jpg'),
            alt: 'Family',
            // backgroundSize: '160%',
            // backgroundPosition: '90%',
          }}
          text={{
            alignment: 'flex-start',
            title: (
              <h1>
                A Diverse and
                <br />
                Rewarding City
              </h1>
            ),
            body: (
              <p>
                What was once a suburban town centre, is now a{' '}
                <span>
                  walkable, transit-focused business, cultural, and
                  entertainment hub
                </span>
                . Connected by SkyTrain, future transit expansion, and roads
                created for walking and cycling, Surrey City Centre is also
                renowned for its parks and plazas, and its spectacular scenery.
              </p>
            ),
          }}
        />
        <Text>
          <Logo src={imgLogo} alt="Centra Logo" />
          In the next decade, Surrey will become the{' '}
          <span>biggest city in Western Canada, </span> and what is already a
          diverse and rewarding city will become the new centre of business and
          culture in BC.
        </Text>

        <ScrollAnimation>
          <ChipWrapper>
            {infoChips.map((infoChip, index) => (
              <InfoChip key={index} info={infoChip} type="card" />
            ))}
          </ChipWrapper>
        </ScrollAnimation>

        <CentraRender>
          <GradientLayer background={imgCentraAerial} />
          <Locations src={imgLocations} visible={this.state.visible} />
          <CentraPin src={imgCentraPin} />
          <Toggle onClick={this.toggleVisibility}>
            <span>
              {this.state.visible ? 'Hide' : 'Show'} points of interest
            </span>
          </Toggle>
        </CentraRender>

        <ScrollAnimation>
          <ImageGrid>
            <Image1 background={imgCollage1} className="image1" />
            <Image2 background={imgCollage2} className="image2" />
            <Image3 background={imgCollage3} className="image3">
              <Bubble src={imgCollageBubble} />
            </Image3>

            <TextWrapper>
              <TextBlock
                text={{
                  alignment: 'flex-start',
                  title: (
                    <h1>
                      A Diverse and <br />
                      Rewarding City
                    </h1>
                  ),
                  body: (
                    <p>
                      The <span>tranquility of a quiet suburb, </span> the
                      cosmopolitan <span>energy of the city</span>, you usually
                      have to choose one or the other. At Centra, you can enjoy
                      the best of both worlds. Less than 9 minutes' walk from
                      Surrey's downtown, the conveniences and everything you
                      love about <span>city living is just steps away</span>.
                      And when you're home, its idyllic setting lets you enjoy
                      the <span>peaceful atmosphere of a neighbourhood</span>{' '}
                      with access to trails, parks and never ending views.
                    </p>
                  ),
                }}
              />
            </TextWrapper>
          </ImageGrid>
        </ScrollAnimation>

        <Collage>
          <Image1 background={imgCollage1} />
          <Image2 background={imgCollage2} />
          <Image3 background={imgCollage3} />
        </Collage>

        <Map
          points={mapPoints}
          travelChips={travelChips}
          mapLocations={mapLocations}
          activeGroup={this.state.activeGroup}
          handleClick={this.handleHighlightLocation}
          toggleVisibility={this.toggleMapVisible}
          mapVisible={this.state.mapVisible}
        />
      </LayoutWithHero>
    );
  }
}

export default Neighbourhood;

const Text = styled.p`
  text-align: center;
  font-size: 18px;
  line-height: 1.44;
  letter-spacing: 1.3px;
  color: ${({ theme }) => theme.colorBlack};
  margin-bottom: 77px;
  margin-top: 85px;
  span {
    /* TODO: Font weight normal?? or Bold? */
    font-weight: bold;
  }
  @media (min-width: ${media.smup}) {
    max-width: 670px;
    margin: 0 auto;
    margin-bottom: 41px;
  }
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* > div {
    height: 255px;
  } */
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
  }
`;

const Logo = styled.img`
  height: 42px;
  width: auto;
  margin: 0 auto 14px auto;
  /* margin: -24px auto 14px auto; */
  display: block;
`;

const CentraRender = styled.div`
  /* height: 69.3vw; */
  height: 44vw;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 60px;
  position: relative;

  /* background-blend-mode: normal, multiply; */
  background-image: linear-gradient(to bottom, #d8d8d8, #d8d8d8);
  @media (min-width: ${media.mdup}) {
    margin-bottom: 80px;
  }
`;

const Collage = styled.div`
  margin-top: 53px;
  margin-bottom: 60px;
  display: grid;

  grid-template-rows: 69.3vw 40vw;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 22px;
  grid-row-gap: 22px;

  /* flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; */
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const Image1 = styled.div`
  background: ${props => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  height: 69.3vw;
  width: 100%;
  margin-bottom: 5vw;
  grid-row: 1 / 2;
  grid-column: 1 / 3;

  @media (min-width: ${media.mdup}) {
    height: 28.8vw;
    width: 36.4vw;
    margin-bottom: 0;

    margin-left: auto;
    display: block;
  }
`;

const Image2 = styled.div`
  background: ${props => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;

  @media (min-width: ${media.mdup}) {
    height: 100%;
    width: 100%;
    margin-bottom: 0;
    display: block;
  }
  /* width: 50%; */
  /* margin-right: 3vw; */
`;

const Image3 = styled.div`
  background: ${props => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  @media (min-width: ${media.mdup}) {
    width: 100%;
    height: 15.3vw;
    margin-bottom: 0;
    display: block;
    position: relative;
  }
  /* width: 50%; */
  /* margin-right: 3vw; */
`;

const ImageGrid = styled.div`
  display: block;
  .image1,
  .image2,
  .image3 {
    display: none;
  }
  @media (min-width: ${media.mdup}) {
    display: grid;
    grid-template-columns: 23.7vw auto 29.4vw;
    grid-template-rows: 28.82vw 27.5vw;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    margin-left: 8.4vw;
    margin-right: 9.2vw;
    margin-bottom: 90px;

    .image1,
    .image2,
    .image3 {
      display: block;
    }
    .image1 {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
    .image2 {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    .image3 {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 30px;
  @media (min-width: ${media.mdup}) {
    width: 120%;
  }
`;

const CentraPin = styled.img`
  position: absolute;
  top: 47%;
  left: 53%;
  transform: translate(-50%, -50%);
  @media (min-width: 517px) {
    top: 50%;
  }
`;

const Locations = styled.img`
  opacity: 0;
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    opacity: ${props => (props.visible ? '1' : '0')};
    transform: ${props =>
      props.visible ? 'translateY(0)' : 'translateY(-50px)'};
    position: absolute;
    width: 62.8vw;
    top: 10%;
    left: 3%;
    transition: all 0.8s;
  }
`;

const GradientLayer = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${props => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;
`;

const Toggle = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 253px;
  height: 34px;
  margin: 0 auto;
  border: 1px solid white;
  transform: translateY(-200%);
  @media (min-width: ${media.mdup}) {
    display: flex;
    &:hover {
      background: white;
      transition: all 0.3s;

      span{
        color: ${({ theme }) => theme.colorBlack};
        transition: all 0.3s;

      }
      /* color: ${({ theme }) => theme.colorBlack}; */
    }
  }
  span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1.6px;
    color: white;
  }
`;

const Bubble = styled.img`
  display: none;
  @media (min-width: ${media.mdup}) {
    position: absolute;
    bottom: -80px;
    left: -198px;
    display: block;
  }
`;
