import imgBookPin from 'src/assets/images/neighbourhood/book-icon.svg';
import imgSFUPin from 'src/assets/images/neighbourhood/sfu-icon-4-x.png';
import imgKPUPin from 'src/assets/images/neighbourhood/kpu-icon-4-x.png';

const mapPoints = [
  {
    label: 'King George Station',
    location: [61, 34],
  },
  {
    label: 'Civic Plaza',
    location: [30, 16],
  },
  {
    label: 'Central City',
    location: [45, 22],
  },
  {
    label: 'Simon Fraser University',
    location: [50, 16],
    icon: imgSFUPin,
  },
  {
    label: 'Kwantlen Polytechnic University',
    location: [53, 21],
    icon: imgKPUPin,
  },
  {
    label: 'Library 1',
    location: [73, 11],
    icon: imgBookPin,
  },
  {
    label: 'Library 2',
    location: [86, 27],
    icon: imgBookPin,
  },
];

export default mapPoints;
