import React, { Fragment } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import Button from 'components/Button';

import imgOpen from 'src/assets/images/icons/open.svg';
import imgClosed from 'src/assets/images/icons/close.svg';

const FeatureAccordion = ({ features, handleClick, activeIndex, title }) => {
  return (
    <Root>
      <SectionHeader>{title}</SectionHeader>
      <CardWrapper>
        {features.map((featureSection, index) => (
          <Card key={index}>
            {featureSection.image && (
              <Image background={featureSection.image} />
            )}
            <Title onClick={() => handleClick(index)}>
              <h1>{featureSection.title}</h1>{' '}
              <OpenIndicator
                src={activeIndex === index ? imgClosed : imgOpen}
                alt="FeatureCard is Open"
              />
            </Title>
            <Content isActive={activeIndex === index}>
              {featureSection.features.map((feature, index) => {
                if (typeof feature === 'object') {
                  return (
                    <Fragment key={index}>
                      <li key={index}>{feature.feature}</li>
                      <NestedList>
                        {feature.subfeatures.map((subfeature, index) => (
                          <li key={index}>{subfeature}</li>
                        ))}
                      </NestedList>
                    </Fragment>
                  );
                } else return <li key={index}>{feature}</li>;
              })}
            </Content>
          </Card>
        ))}
      </CardWrapper>

      <Download>
        <h1>Full Feature Sheet PDF</h1>
        <a
          href={`/assets/pdfs/features.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button label="Download" border="#aa694c" centered />
        </a>
      </Download>
    </Root>
  );
};

export default FeatureAccordion;

const Root = styled.div`
  /* padding-bottom: 82px; */
  @media (min-width: ${media.mdup}) {
    padding-bottom: 0;
  }
`;

const Title = styled.div`
  font-weight: 300;
  color: #ba734f;
  letter-spacing: 1px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 32px;
  h1 {
    color: #ba734f;
    font-weight: normal;
    letter-spacing: 2px;
    font-size: 14px;
    color: ${({ theme }) => theme.colorCopper};
  }
`;

const Card = styled.div`
  @media (min-width: ${media.mdup}) {
    width: 19.9vw;
    margin-right: 4.2vw;
    &:last-child {
      margin-right: 0px;
      > div {
        padding-right: 0;
      }
    }
    /* flex: 1; */
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
  }
`;

const Content = styled.ul`
  list-style: none;
  margin-bottom: 30px;
  width: 73vw;
  max-height: ${props => (props.isActive ? '1000px' : '0px')};
  opacity: ${props => (props.isActive ? '1' : '0')};
  overflow: hidden;
  transition: all 1.2s;
  @media (min-width: ${media.smup}) {
    max-height: ${props => (props.isActive ? '1000px' : '0px')};
  }
  @media (min-width: ${media.mdup}) {
    max-height: none;
    opacity: 1;
    display: flex;
    flex-direction: column;
    width: 19.4vw;
    height: 100%;
  }
  li {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.36;
    color: ${({ theme }) => theme.colorBlack};
    @media (min-width: ${media.mdup}) {
      &:last-of-type {
        margin-bottom: 80px;
      }
    }
  }
`;

const Download = styled.div`
  text-align: center;
  span {
    margin: 0 auto;
  }
  h1 {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 2px;
    margin-bottom: 12px;
  }
`;

const OpenIndicator = styled.img`
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const SectionHeader = styled.h1`
  margin-bottom: 30px;
  @media (min-width: ${media.mdup}) {
    text-align: center;
    /* margin-bottom: 30px; */
  }
`;

const Image = styled.div`
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-position: center;
  width: 19.4vw;
  height: 12.7vw;
  margin-bottom: 36px;
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
  }
`;

const NestedList = styled.div`
  padding-left: 30px;
  /* margin-top: -50px; */
  @media (min-width: ${media.mdup}) {
    margin-top: -50px;
  }
`;
