import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import media from 'src/styles/media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(60px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeInUp {
    animation-name: fadeInUp;
  }


  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(60px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeInLeft {
    animation-name: fadeInLeft;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(-60px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeInRight {
    animation-name: fadeInRight;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }

  @keyframes pulse {

  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
    /* overflow: auto; */
    /* scroll-behavior: smooth; */

  }

  body {
    font-family: 'Brother 1816';
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.colorPrimary};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1{
    font-size: 16px;
    letter-spacing: 3px;
    color:${({ theme }) => theme.colorBlack};
    text-transform: uppercase;
    font-weight: 500;
  }

  p{
    /* font-size: 14px; */
    font-weight: 300;
    line-height: 1.71;
    letter-spacing: 1px;
    color:${({ theme }) => theme.colorBlack};
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('/assets/fonts/Brother-1816-Light.woff2') format('woff2'),
        url('/assets/fonts/Brother-1816-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('/assets/fonts/Brother-1816-Regular.woff2') format('woff2'),
        url('/assets/fonts/Brother-1816-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('/assets/fonts/Brother-1816-Bold.woff2') format('woff2'),
        url('/assets/fonts/Brother-1816-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('/assets/fonts/Brother-1816-Book.woff2') format('woff2'),
        url('/assets/fonts/Brother-1816-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('/assets/fonts/Brother-1816-Medium.woff2') format('woff2'),
        url('/assets/fonts/Brother-1816-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
`;
