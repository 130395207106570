const floorplans = [
  {
    id: 'a1',
    bedrooms: 0,
    den: false,
    sqft: 489,
    floors: ['3,4-19'],
    units: '307-1907',
    interior: 436,
    exterior: 53,
    soldout: true,
  },
  {
    id: 'b1',
    bedrooms: 1,
    den: false,
    sqft: 507,
    floors: ['3,4-19'],
    units: '301-1901',
    interior: 469,
    exterior: 38,
    soldout: true,
  },
  {
    id: 'b2',
    bedrooms: 1,
    den: false,
    sqft: 517,
    floors: [1],
    units: '101',
    soldout: true,
    interior: 434,
    exterior: 83,
  },
  {
    id: 'c1-303',
    bedrooms: 1,
    den: true,
    sqft: 573,
    floors: [3],
    units: '303',
    soldout: true,
    interior: 500,
    exterior: 73,
  },
  {
    id: 'c1',
    bedrooms: 1,
    den: true,
    sqft: 573,
    floors: [3],
    units: '403-1903',
    interior: 500,
    exterior: 73,
    soldout: true,
  },
  {
    id: 'c2',
    bedrooms: 1,
    den: true,
    sqft: 552,
    floors: [2],
    units: '202',
    soldout: true,
    interior: 552,
    exterior: 0,
  },
  {
    id: 'd1',
    bedrooms: 2,
    den: false,
    sqft: 647,
    floors: ['4-19'],
    units: '405-1905',
    soldout: true,
    interior: 601,
    exterior: 75,
  },
  {
    id: 'd1-305',
    bedrooms: 2,
    den: false,
    sqft: 647,
    floors: [3],
    units: '305',
    soldout: true, //listViewHidden: true,
    interior: 601,
    exterior: 46,
  },
  {
    id: 'd2',
    bedrooms: 2,
    den: false,
    junior2: true,
    sqft: 791,
    floors: [2],
    units: '204',
    interior: 721,
    exterior: 70,
    soldout: true,
  },
  {
    id: 'e1',
    bedrooms: 2,
    den: false,
    sqft: 906,
    floors: [20, '21-23'],
    units: '2003-2303',
    interior: 773,
    exterior: 133,
    soldout: true,
  },
  {
    id: 'e2',
    bedrooms: 2,
    den: false,
    sqft: 835,
    floors: ['3,4-19'],
    units: '302-1902',
    interior: 775,
    exterior: 60,
    soldout: true,
  },

  {
    id: 'e3',
    bedrooms: 2,
    den: false,
    sqft: 824,
    floors: [2],
    units: '203',
    interior: 781,
    exterior: 43,
    soldout: true,
  },
  {
    id: 'e4',
    bedrooms: 2,
    den: false,
    sqft: 835,
    floors: [2],
    units: '201',
    interior: 776,
    exterior: 59,
    soldout: true,
  },
  {
    id: 'e5',
    bedrooms: 2,
    den: false,
    sqft: 890,
    floors: [1],
    units: '102',
    interior: 764,
    exterior: 126,
    soldout: true,
  },
  {
    id: 'e6',
    bedrooms: 2,
    den: false,
    sqft: 906,
    floors: ['3,4-19'],
    units: '308-1908',
    interior: 825,
    exterior: 81,
    soldout: true,
  },
  {
    id: 'e7-2005',
    bedrooms: 2,
    den: false,
    sqft: 1120,
    floors: [20],
    units: '2005',
    soldout: true, //listViewHidden: true,
    interior: 935,
    exterior: 167,
  },
  {
    id: 'e7',
    bedrooms: 2,
    den: false,
    sqft: 1033,
    floors: ['21-23'],
    units: '2105-2305',
    interior: 935,
    exterior: 98,
    soldout: true,
  },
  {
    id: 'f1',
    bedrooms: 2,
    den: true,
    sqft: 892,
    floors: ['3,4-19'],
    units: '306-1906',
    interior: 801,
    exterior: 91,
    soldout: true,
  },
  {
    id: 'g1-304',
    bedrooms: 3,
    den: false,
    sqft: 1053,
    floors: [3],
    units: '304',
    soldout: true, //listViewHidden: true,
    interior: 941,
    exterior: 112,
  },
  {
    id: 'g1',
    bedrooms: 3,
    den: false,
    sqft: 1051,
    floors: ['4-19'],
    units: '404-1904',
    interior: 941,
    exterior: 110,
  },
  {
    id: 'g2-2004',
    bedrooms: 3,
    den: false,
    sqft: 1333,
    floors: [20],
    units: '2004',
    soldout: true, //listViewHidden: true,
    interior: 1011,
    exterior: 322,
  },
  {
    id: 'g2',
    bedrooms: 3,
    den: false,
    sqft: 1144,
    floors: ['21-23'],
    units: '2104-2304',
    interior: 1011,
    exterior: 133,
  },
  {
    id: 'g3',
    bedrooms: 3,
    den: false,
    sqft: 1145,
    floors: [20, '21-23'],
    units: '2002-2302',
    interior: 1008,
    exterior: 137,
  },
  {
    id: 'g4',
    bedrooms: 3,
    den: false,
    sqft: 1170,
    floors: [20, '21-23'],
    units: '2001-2301',
    interior: 1108,
    exterior: 62,
  },
  {
    id: 'ph1',
    bedrooms: 3,
    den: false,
    sqft: 2430,
    floors: [24],
    units: '2401',
    interior: 1316,
    exterior: 1114,
  },
  {
    id: 'ph2',
    bedrooms: 3,
    den: false,
    sqft: 2293,
    floors: [24],
    units: '2402',
    interior: 1339,
    exterior: 954,
  },
  {
    id: 'th1',
    bedrooms: 2,
    den: false,
    sqft: 1734,
    floors: ['1'],
    units: '',
    interior: 1389,
    exterior: 345,
  },
  {
    id: 'th2',
    bedrooms: 2,
    den: false,
    sqft: 1840,
    floors: ['1'],
    units: '',
    interior: 1471,
    exterior: 369,
  },
  {
    id: 'th3',
    bedrooms: 4,
    den: true,
    sqft: 2488,
    floors: ['1'],
    units: '',
    interior: 2157,
    exterior: 331,
  },
];

export default floorplans;
