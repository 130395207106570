import React from 'react';
import styled from 'styled-components';

import imgBuilding from 'src/assets/images/floorplans/building-elevation.png';

const Building = ({ activeLevel }) => (
  <Root background={imgBuilding}>
    <SVG
      width="209px"
      height="438px"
      viewBox="0 0 209 438"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>building-select-svg</title>
      <desc>Created with Sketch.</desc>
      <g
        id="building-select-svg"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.502139137"
      >
        <Rect
          id="1"
          fill="#B77356"
          active={activeLevel.title === '1'}
          // style="mix-blend-mode: multiply;"
          transform="translate(118.500000, 395.500000) scale(1, -1) translate(-118.500000, -395.500000) "
          x="28"
          y="350"
          width="158"
          height="40"
        />
        <Rect
          id="2"
          fill="#B77356"
          active={activeLevel.title === '2'}
          // style="mix-blend-mode: multiply;"
          transform="translate(118.500000, 395.500000) scale(1, -1) translate(-118.500000, -395.500000) "
          x="28"
          y="387"
          width="181"
          height="17"
        />
        <Rect
          id="3"
          active={activeLevel.title === '3'}
          fill="#B77356"
          // style="mix-blend-mode: multiply;"
          transform="translate(118.500000, 379.000000) scale(1, -1) translate(-118.500000, -379.000000) "
          x="28"
          y="371"
          width="181"
          height="16"
        />
        <Rect
          id="24"
          active={activeLevel.title === '24'}
          fill="#B77356"
          // style="mix-blend-mode: multiply;"
          transform="translate(108.500000, 60.000000) scale(1, -1) translate(-108.500000, -60.000000) "
          x="28"
          y="51"
          width="161"
          height="18"
        />
        <Rect
          id="20"
          active={activeLevel.title === '20'}
          // fill="#B77356"
          // style="mix-blend-mode: multiply;"
          transform="translate(118.500000, 126.000000) scale(1, -1) translate(-118.500000, -126.000000) "
          x="28"
          y="117"
          width="181"
          height="18"
        />
        <Rect
          id="21-23"
          active={activeLevel.title === '21-23'}
          // fill="#B77356"
          // style="mix-blend-mode: multiply;"
          transform="translate(108.500000, 93.000000) scale(1, -1) translate(-108.500000, -93.000000) "
          x="28"
          y="69"
          width="161"
          height="48"
        />
        <Rect
          id="4-19"
          active={activeLevel.title === '4-19'}
          // fill="#B77356"
          // style="mix-blend-mode: multiply;"
          x="28"
          y="135"
          width="181"
          height="236"
        />
      </g>
    </SVG>
  </Root>
);

export default Building;

const SVG = styled.svg``;

const Rect = styled.rect`
  fill: ${props => (props.active ? 'rgb(183, 115, 86)' : 'none')};
  mix-blend-mode: multiply;
`;

const Root = styled.div`
  background: ${props => `url(${props.background})`};
  background-repeat: no-repeat;
  margin: 20px;
`;
