import React from 'react';

export default {
  units: [
    {
      id: 'e3',
      mockup: (
        <g id="unit-e3" transform="translate(96.000000, 64.000000)">
          <polygon
            id="fill-e3"
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="150.223073 56.2109729 102.053721 56.2109729 102.053721 9.60718326 97.0510584 8.95079186 40.4640527 0 36.3600719 26.5838518 37.7080948 26.8225396 35.8807749 39.7713518 7.15290907 35.5346437 0.981959792 75.7535351 84.0501265 75.7535351 84.0501265 85.0921946 99.0281587 85.0921946 99.0281587 76.946974 150.223073 76.946974"
          />
          <text
            id="E3"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="54.556333" y="38">
              E3
            </tspan>
          </text>
          <text
            id="03"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="54.178333" y="56">
              03
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'c2',
      mockup: (
        <g id="unit-c2" transform="translate(91.000000, 139.000000)">
          <polygon
            id="fill-c2"
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="104.088071 10.0921946 89.1100386 10.0921946 89.1100386 0.753535068 5.98195979 0.753535068 0.5 36.6760464 13.051591 38.7347285 13.051591 63.2598982 45.4640527 63.2598982 45.4640527 59.4110577 68.2905738 59.4110577 68.2905738 33.2747455 85.2756624 33.2747455 85.2756624 41.8973416 103.968247 41.8973416"
          />
          <text
            id="C2"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="32.793333" y="26">
              C2
            </tspan>
          </text>
          <text
            id="02-copy-2"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="33.052333" y="44">
              02
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'e4',
      mockup: (
        <g id="unit-e4" transform="translate(104.000000, 172.000000)">
          <polygon
            id="fill-e4"
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="100.464319 37.3011878 91.0880708 37.3011878 91.0880708 8.89734163 72.2756624 8.89734163 72.2756624 0.274745475 55.2905738 0.274745475 55.2905738 26.4110577 32.4640527 26.4110577 32.4640527 30.2598982 0.0515909999 30.2598982 0.0515909999 68.4499434 32.2244042 68.4499434 32.2244042 80.3843326 30.8464252 80.3843326 30.8464252 102.880656 102.231727 102.880656 102.231727 87.5449661 100.404407 87.5449661"
          />
          <text
            id="E4"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="49.010333" y="58">
              E4
            </tspan>
          </text>
          <text
            id="01"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="50.151333" y="76">
              01
            </tspan>
          </text>
        </g>
      ),
    },
    {
      id: 'd2',
      mockup: (
        <g id="unit-d2" transform="translate(204.000000, 187.000000)">
          <polygon
            id="fill-d2"
            stroke="#2A2827"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="96.7431101 27.9998586 112.5 27.9998586 112.5 0.968467195 95.6047797 0.968467195 95.6047797 9.91925905 78.9492078 9.91925905 78.9492078 22.1818439 0.464318999 22.1818439 0.464318999 72.5449661 2.2317268 72.5449661 2.2317268 74.3649604 27.9939422 74.3649604 27.9939422 82.9875566 26.4062708 82.9875566 26.4062708 88 68.344761 88 68.344761 74.3649604 94.1369325 74.3649604 94.1369325 72.5449661 96.6232858 72.5449661"
          />
          <text
            id="D2"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="37.380333" y="49">
              D2
            </tspan>
          </text>
          <text
            id="04"
            fontFamily="Brother-1816-Regular, Brother 1816"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="1.166667"
            fill="#B77356"
          >
            <tspan x="37.632333" y="67">
              04
            </tspan>
          </text>
        </g>
      ),
    },
  ],
};
