const infoChips = [
  {
    num: '$16.8',
    subtext: 'billion',
    body:
      'In development opportunities will be created over the next 10 years.',
  },
  {
    num: '40',
    subtext: 'thousand',
    body: 'Direct and indirect jobs will be created in the next decade',
  },
  {
    num: '2041',
    subtext: 'project growth',
    body:
      'Poised to become the highest municipal population in BC with over 100 languages spoken',
  },
  {
    num: '20',
    subtext: 'thousand',
    body: 'Students enrolled at SFU & Kwantlen Polytechnic university campuses',
  },
];

export default infoChips;
