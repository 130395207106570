import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const InfoChip = ({ info, type, index }) => {
  return (
    <Root>
      <Title>
        <p>
          <span>{info.num}</span>
          {info.subtext}
        </p>
      </Title>
      <Body>{info.body && <span>{info.body}</span>}</Body>
    </Root>
  );
};

export default InfoChip;

const Root = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  transition: all 0.8s;
  background: white;
  flex: 1;
  /* max-height: 133px; */
  /* height: 133px; */
  /* opacity: 0.85; */
  margin-bottom: 24px;
  width: 60vw;
  padding: 0 10px;
  height: 290px;
  width: 75vw;
  /* height: 0px; */
  &:not(:last-child) {
    @media (min-width: ${media.mdup}) {
      margin-right: 40px;
    }
  }

  @media (min-width: ${media.mdup}) {
    width: auto;
  }

  /* @media (max-width: ${media.sm}) {
    &.isActive {
      max-height: 284px;
      height: 100%;
      opacity: 1;
      height: 288px;
      span {
        opacity: 1;
      }
      .hidden {
        transition: opacity 1s;
        transition-delay: 0.5s;
        height: 100%;
        max-height: 3000px;
        opacity: 1;
      }
    }
  }

  @media (min-width: ${media.smup}) {
    &:hover {
      max-height: 284px;
      height: 100%;
      opacity: 1;
      height: 288px;
      span {
        opacity: 1;
      }
      .hidden {
        transition: opacity 1s;
        transition-delay: 0.5s;
        height: 100%;
        max-height: 3000px;
        opacity: 1;
      }
    }
  } */
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  padding-top: 30px;
  margin-bottom: 30px;
  /* padding-bottom: 60px; */

  /* padding-bottom: 30px; */
  /* background: black; */
  /* height: 133px; */

  span {
    display: block;
    font-size: 30px;
    color: ${({ theme }) => theme.colorCopper};
    margin-bottom: 6px;
  }
  p {
    font-size: 14px;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colorBlack};
    max-width: 149px;
  }
`;

const Body = styled.div`
  /* padding: 0px 10px 30px 10px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background: white;
  /* height: 0; */
  /* opacity: 0; */
  span {
    padding: 0px 10px 30px 10px;
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colorBlack};
    /* opacity: 0; */
  }
`;
